import { XANDR_ADLIB_URL } from '@/modules/xandr';

/**
 * Check if Brave is the current browser
 * @return Boolean
 */
export function isBraveBrowser(): boolean {
    // @ts-ignore: Property 'brave' does not exist on type 'Navigator'.
    return typeof navigator?.brave !== 'undefined' && typeof navigator?.brave?.isBrave !== 'undefined';
}

/**
 * Check if Opera is the current browser
 * @return Boolean
 */
export function isOperaBrowser(): boolean {
    return !!navigator.userAgent.match(/Opera|OPR\//);
}

/**
 * Check if a DOM element seems to be blocked by an adblocker or not
 * @param elem - The DOM element to check
 * @return Boolean
 */
export function isElementHidden(elem: HTMLElement | null): boolean {
    /* eslint-disable @typescript-eslint/prefer-optional-chain */
    if (
        !elem ||
        elem?.offsetParent === null ||
        elem?.offsetHeight === 0 ||
        elem?.offsetLeft === 0 ||
        elem?.offsetTop === 0 ||
        elem?.offsetWidth === 0 ||
        elem?.clientHeight === 0 ||
        elem?.clientWidth === 0
    ) {
        return true;
    }
    const elemCS = window.getComputedStyle?.(elem);

    return elemCS?.getPropertyValue('display') === 'none' || elemCS?.getPropertyValue('visibility') === 'hidden';
}

/**
 * Create and execute an XMLHttpRequest that should be blocked by an adblocker
 * @return Promise<XMLHttpRequest>
 */
export function createBaitRequest(): Promise<XMLHttpRequest> {
    return new Promise((resolve, reject) => {
        try {
            const url = XANDR_ADLIB_URL;
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState === 4) {
                    resolve(xhttp);
                }
            };
            // send request to ads url to see if it gets blocked
            xhttp.open('GET', url, true);
            xhttp.send();
        } catch (error) {
            console.error('Error creating bait request:', error);
            reject(error);
        }
    });
}

/**
 * Create a bait div with ads content and classes
 */
export function createBaitDiv(): void {
    const baitDiv = document.createElement('DIV');
    baitDiv.setAttribute('id', 'baitAds');
    baitDiv.setAttribute(
        'class',
        'ads showads ads-prebid pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner',
    );
    baitDiv.setAttribute(
        'style',
        'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;',
    );
    document.body.appendChild(baitDiv);
}

// NOTE : brave seems to let blocked requests return a valid HTTP status code,
// but the content returned is empty, so we check if we see the content that we know is in our bait file
export function isBaitBlockedByBrave(xhttp: XMLHttpRequest): boolean {
    return xhttp.status === 200 && !xhttp.responseText.match(/^adblockertext(\n|)$/);
}

// NOTE : opera and some ad blocker like u-origin seems to set the HTTP status code to 0
// and empty content, so we check if we see the content that we know is in our bait file
export function isBaitRequestBlocked(xhttp: XMLHttpRequest): boolean {
    return xhttp.status === 0 || xhttp.status < 200 || xhttp.status >= 300;
}
