import axios from 'axios';
import route from '@router/route';
import { assignCancelToken } from '@/api/utils';
import { backend } from '.';

export function joinGroup(groupId) {
    return backend.post(route('groups.memberships.store', { groupId }));
}

export function leaveGroup(groupId) {
    return backend.delete(route('groups.memberships.destroy', { group: groupId }));
}

export function getFeed(groupId, page = 1) {
    return backend.get(route('groups.feed', { group: groupId }).toString(), {
        params: { page },
    });
}

export function retrieveCategories() {
    return backend.get(route('groups.categories.index'));
}

export function searchGroups(filters) {
    const cancelToken = axios.CancelToken.source();
    const promise = backend.get(route('groups.fetch', { ...filters }), {
        cancelToken: cancelToken.token,
    });

    return assignCancelToken(promise, cancelToken);
}

export function getFaq(groupId) {
    return backend.get(route('groups.faq.show', { group: groupId }));
}

/**
 * @param {name, target, reason} payload
 */
export function suggestGroup(payload) {
    return backend.post(route('groups.suggest'), payload);
}

/**
 * @param {Array} order - array of ids
 * @returns {Promise}
 */
export function orderGroups(order) {
    return backend.post(route('groups.memberships.order'), { order });
}
