/**
 * Initilizes Facebook
 * @param {'de_DE' | 'en_US'} locale
 * @param {bool} initialStatusCheck - when you want to quickly verify fb login status after intialization
 * @returns
 */
export async function initializeFacebook(locale, initialStatusCheck = false) {
    return new Promise((resolve) => {
        if (window.FB) {
            // facebook already initialized
            resolve();
            return;
        }

        const oldInit = window.fbAsyncInit;
        // fbAsyncInit will be called as soon as the external facebook script  was finished loading
        // (which is appended to the document below in this function)
        window.fbAsyncInit = () => {
            // As initializeFacebook() could be executed multiple times on a page while the sdk is being loaded,
            // we need to chain the init functions and thus the promise resolvers!
            if (oldInit) {
                oldInit();
            } else {
                // the first init, does not have oldInit and will initialize facebook
                FB.init({
                    appId: import.meta.env.MIX_FACEBOOK_ID,
                    status: initialStatusCheck,
                    xfbml: true,
                    cookie: true, // Crucial for PHP SDK
                });
            }

            resolve();
        };

        const sdkId = 'facebook-jssdk';

        if (document.getElementById(sdkId)) {
            // FB is already loading, nothing more to do
            return;
        }

        // load facebook script by inserting it into the DOM
        const script = document.createElement('script');
        script.id = sdkId;
        script.async = true;
        script.src = `//connect.facebook.net/${locale}/all.js`;

        document.head.appendChild(script);
    });
}

export function login(callback, specificPermissions) {
    if (!window.FB) throw new Error('Facebook SDK not initialized yet');

    FB.login(callback, {
        scope: specificPermissions || import.meta.env.MIX_FACEBOOK_APP_PERMISSIONS,
        return_scopes: true,
        auth_type: specificPermissions ? 'rerequest' : undefined,
    });
}

export function getLoginStatus(callback) {
    FB.getLoginStatus(callback);
}

export function logout(callback) {
    FB.logout(callback);
}
