import { storeDsaReportData } from '@/api/backend/dsa';

const types = {
    IS_REPORT_MODAL_ALLOWED: 'IS_REPORT_MODAL_ALLOWED',
    DSA_REPORT_DATA_PUSH: 'DSA_REPORT_DATA_PUSH',
};

const state = {
    dsaReportData: {},
};

const mutations = {
    [types.IS_REPORT_MODAL_ALLOWED](state, payload) {
        state.dsaReportData[payload.adType] = { ...payload };
    },

    [types.DSA_REPORT_DATA_PUSH](state, payload) {
        storeDsaReportData(payload);
    },
};

const actions = {
    shouldShowReportModal({ commit }, data) {
        commit(types.IS_REPORT_MODAL_ALLOWED, data);
    },

    storeDsaReport({ commit }, data) {
        commit(types.DSA_REPORT_DATA_PUSH, data);
    },
};

const getters = {
    dsaReportData: (state) => state.dsaReportData,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
