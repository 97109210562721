import { createUniversity, getUniversityById, getStats, getCourses, getDocuments } from '@/api/backend/university';
import route from '@router/route';
import { queryToObject, objectToQuery } from '@/helpers/url';
import { mapAggregationsToObject } from '@/utils/searchUtils';
import { applySearchParams } from './search';

const types = {
    SET_NEW_UNIVERSITY: 'SET_NEW_UNIVERSITY',
    SET_CURRENT_UNIVERSITY: 'SET_CURRENT_UNIVERSITY',
    SET_STATS: 'SET_STATS',
    CLEAR_STATS: 'CLEAR_STATS',
    // University courses
    UPDATE_COURSES_LIST: 'UPDATE_COURSES_LIST',
    UPDATE_COURSES_PROGRESS: 'UPDATE_COURSES_PROGRESS',
    UPDATE_COURSES_TOTAL_COUNT: 'UPDATE_COURSES_TOTAL_COUNT',
    UPDATE_COURSES_LAST_PAGE: 'UPDATE_COURSES_LAST_PAGE',
    // University documents
    UPDATE_DOCUMENTS_LIST: 'UPDATE_DOCUMENTS_LIST',
    UPDATE_DOCUMENTS_PROGRESS: 'UPDATE_DOCUMENTS_PROGRESS',
    UPDATE_DOCUMENTS_TOTAL_COUNT: 'UPDATE_DOCUMENTS_TOTAL_COUNT',
    UPDATE_DOCUMENTS_LAST_PAGE: 'UPDATE_DOCUMENTS_LAST_PAGE',
    UPDATE_DOCUMENTS_META: 'UPDATE_DOCUMENTS_META',
    APPLY_PARAMS: 'APPLY_PARAMS',
};

const isUniPage = route().current('university.show');

const state = {
    university: {},
    searchParams: isUniPage ? queryToObject(window.location.search) : {},
    universitySelectedId: null,
    universitySelectedName: null,
    newUniversity: null,
    stats: {},
    courses: {
        list: [],
        progress: false,
        lastPage: 1,
        totalCount: 0,
        filters: null,
    },
    documents: {
        list: [],
        progress: false,
        lastPage: 1,
        totalCount: 0,
        filters: null,
        meta: {},
    },
};

const mutations = {
    [types.APPLY_PARAMS](state, data) {
        state.searchParams = applySearchParams(state.searchParams, data);
        window.router.push(`?${objectToQuery(state.searchParams)}`);
        window.history.replaceState({ ...window.history.state }, null, null);
    },
    [types.SET_NEW_UNIVERSITY](state, value) {
        state.newUniversity = value;
    },
    [types.SET_CURRENT_UNIVERSITY](state, payload) {
        state.university = payload;
    },
    [types.SET_STATS](state, payload) {
        state.stats = payload;
    },
    [types.CLEAR_STATS](state) {
        state.stats = {};
    },
    [types.UPDATE_COURSES_LIST](state, payload) {
        state.courses.list = payload;
    },
    [types.UPDATE_COURSES_PROGRESS](state, payload) {
        state.courses.progress = payload;
    },
    [types.UPDATE_COURSES_TOTAL_COUNT](state, payload) {
        state.courses.totalCount = payload;
    },
    [types.UPDATE_COURSES_LAST_PAGE](state, payload) {
        state.courses.lastPage = payload;
    },
    [types.UPDATE_DOCUMENTS_LIST](state, payload) {
        state.documents.list = payload;
    },
    [types.UPDATE_DOCUMENTS_PROGRESS](state, payload) {
        state.documents.progress = payload;
    },
    [types.UPDATE_DOCUMENTS_TOTAL_COUNT](state, payload) {
        state.documents.totalCount = payload;
    },
    [types.UPDATE_DOCUMENTS_LAST_PAGE](state, payload) {
        state.documents.lastPage = payload;
    },
    [types.UPDATE_DOCUMENTS_META](state, data) {
        state.documents.meta = data;
    },
};

const actions = {
    getUniversityById({ commit }, universityId) {
        return getUniversityById(universityId).then(({ data }) => {
            commit('SET_CURRENT_UNIVERSITY', data);
            return data;
        });
    },
    createUniversity({ commit }, university) {
        return createUniversity(university).then((response) => {
            if (response.data.success) {
                commit('SET_NEW_UNIVERSITY', response.data.university);
                return true;
            }
            return false;
        });
    },
    setCurrentUniversity({ commit }, payload) {
        commit(types.SET_CURRENT_UNIVERSITY, payload);
    },
    applyParams({ commit }, data) {
        commit(types.APPLY_PARAMS, data);
    },
    retrieveStats({ commit }, universityId) {
        return getStats(universityId).then((response) => commit('SET_STATS', response.data.stats));
    },
    clearStats({ commit }) {
        commit(types.CLEAR_STATS);
    },
    retrieveCourses({ commit }, data) {
        commit('UPDATE_COURSES_PROGRESS', true);
        commit('UPDATE_COURSES_LIST', {});
        commit('UPDATE_COURSES_TOTAL_COUNT', 0);
        commit('UPDATE_COURSES_LAST_PAGE', null);

        return getCourses({ ...data, per_page: data.perPage })
            .then(
                ({
                    data: {
                        data: { results },
                    },
                }) => {
                    const coursesList = results.data;
                    if (coursesList) {
                        commit('UPDATE_COURSES_LIST', coursesList);
                        commit('UPDATE_COURSES_TOTAL_COUNT', results.total);
                        commit('UPDATE_COURSES_LAST_PAGE', results.last_page);
                    }
                },
            )
            .finally(() => commit('UPDATE_COURSES_PROGRESS', false));
    },
    retrieveDocuments({ commit }, data) {
        commit('UPDATE_DOCUMENTS_PROGRESS', true);

        return getDocuments({ ...data, per_page: data.perPage })
            .then((response) => {
                const { results, meta } = response.data.data;

                commit('UPDATE_DOCUMENTS_LIST', results.data);
                commit('UPDATE_DOCUMENTS_TOTAL_COUNT', results.total);
                commit('UPDATE_DOCUMENTS_LAST_PAGE', results.last_page);
                commit('UPDATE_DOCUMENTS_META', meta);
            })
            .finally(() => commit('UPDATE_DOCUMENTS_PROGRESS', false));
    },
};

const getters = {
    newUniversity: (state) => state.newUniversity,
    universitySelectedId: (state) => state.universitySelectedId,
    universitySelectedName: (state) => state.universitySelectedName,
    currentUniversity: (state) => state.university,
    universityStats: (state) => state.stats,
    searchParams: (state) => state.searchParams,
    coursesList: (state) => {
        return state.courses.list !== undefined ? state.courses.list : [];
    },
    coursesProgress: (state) => {
        return state.courses.progress !== undefined ? state.courses.progress : false;
    },
    coursesTotalCount: (state) => {
        return state.courses.totalCount;
    },
    coursesLastPage: (state) => {
        return state.courses.lastPage;
    },
    documentsList: (state) => {
        return state.documents.list !== undefined ? state.documents.list : [];
    },
    documentsProgress: (state) => {
        return state.documents.progress !== undefined ? state.documents.progress : false;
    },
    documentsTotalCount: (state) => {
        return state.documents.totalCount;
    },
    documentsLastPage: (state) => {
        return state.documents.lastPage;
    },
    documentTypeTotals: (state) => mapAggregationsToObject(state.documents.meta?.aggregations?.file_content_type),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
