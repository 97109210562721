import type { SentryEventProcessor } from './type';
import userDataProcessor from './userDataProcessor';
import usercentricsProcessor from './usercentricsProcessor';
import axiosProcessor from './axiosProcessor';

// NOTE: the order defines their execution order
export default {
    axiosProcessor,
    usercentricsProcessor,
    userDataProcessor,
} as Record<string, SentryEventProcessor>;
