import axios from 'axios';
import route from '@router/route';
import { captureException } from '@sentry/vue';

const lake = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-SD-Internal-Token': import.meta.env.MIX_SD_LAKE_SERVICE_API_TOKEN,
    },
});

/**
 * Stores track event data.
 *
 * @param {object} payload - The data to be stored.
 * @return {Promise<void>} A Promise that resolves when the data is stored successfully.
 */
export async function storeTrackEventData(payload: object): Promise<void> {
    if (import.meta.env.MIX_SD_LAKE_SERVICE_DEBUG === 'true') {
        return;
    }
    await lake
        .post(route('external.lake.events'), payload)
        // we don't want to propagate the error, but get an error report
        .catch(captureException);
}

/**
 * @deprecated OLD TRACKING SYSTEM
 * DO NOT USE ANYMORE
 */
export async function storeTrackingData(payload: object): Promise<void> {
    await lake
        .post(route('external.lake.tracking'), payload)
        // we don't want to propagate the error, but get an error report
        .catch(captureException);
}
