import { sendFeedback } from '@/api/backend/course-expert';

const types = {
    PUSH_FEEDBACK: 'PUSH_FEEDBACK',
};

const state = {
    message: null,
};

const mutations = {
    [types.PUSH_FEEDBACK](state, value) {
        state.message = value;
    },
};

const actions = {
    sendFeedback({ commit }, message) {
        return sendFeedback(message).then((response) => {
            if (response.data.success) {
                commit('PUSH_FEEDBACK', message);
                return true;
            }
            return false;

            // return !!response.data.success;
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
