import {
    getSemesters,
    getStudyPrograms,
    getUserStudies,
    getUserStudyOnboarding,
    updateUserStudies,
    updateUserStudyOnboarding,
} from '@/api/gateway/legacy-api/study';

const getKey = () => {
    return Math.random().toString(36).substring(2, 9);
};

const state = {
    semesters: [],
    studyPrograms: [],
    userStudies: [],
};

const types = {
    SET_SEMESTERS: 'SET_SEMESTERS',
    SET_STUDY_PROGRAMS: 'SET_STUDY_PROGRAMS',
    SET_USER_STUDIES: 'SET_USER_STUDIES',
    APPEND_EMPTY_STUDY: 'APPEND_EMPTY_STUDY',
    CHANGE_STUDY: 'CHANGE_STUDY',
    REMOVE_STUDY: 'REMOVE_STUDY',
};

const mutations = {
    [types.SET_SEMESTERS](state, payload) {
        state.semesters = payload;
    },
    [types.SET_STUDY_PROGRAMS](state, payload) {
        state.studyPrograms = payload;
    },
    [types.SET_USER_STUDIES](state, payload) {
        state.userStudies = payload.map((study) => ({ key: getKey(), ...study }));
    },
    [types.APPEND_EMPTY_STUDY](state) {
        if (state.userStudies.length === 3) {
            return;
        }
        const emptyStudy = {
            key: getKey(),
            university: {
                id: null,
                name: null,
                has_specific_programs: null,
                study_programs: [
                    {
                        id: null,
                        name: null,
                        field_of_study: null,
                        primary: null,
                    },
                ],
            },
            semester: {
                id: null,
                description: null,
            },
        };
        state.userStudies.push(emptyStudy);
    },
    [types.CHANGE_STUDY](state, { index, study }) {
        state.userStudies.splice(index, 1, study);
    },
    [types.REMOVE_STUDY](state, index) {
        state.userStudies.splice(index, 1);
    },
};

const actions = {
    initialize({ commit, rootGetters }, userId) {
        const id = userId || rootGetters['user/user'].userid;
        const submits = [
            getSemesters().then((response) => commit(types.SET_SEMESTERS, response.data)),
            getStudyPrograms().then((response) => commit(types.SET_STUDY_PROGRAMS, response.data)),
        ];

        if (id > 0) {
            submits.push(getUserStudies(id).then((response) => commit(types.SET_USER_STUDIES, response.data)));
        }

        return Promise.all(submits);
    },
    updateUserStudies({ commit }, payload) {
        return updateUserStudies(payload).then((response) => {
            commit(types.SET_USER_STUDIES, response.data);
            return response.data;
        });
    },
    appendEmptyStudy({ commit }) {
        commit(types.APPEND_EMPTY_STUDY);
    },
    changeStudy({ commit }, payload) {
        commit(types.CHANGE_STUDY, payload);
    },
    removeStudy({ commit }, index) {
        commit(types.REMOVE_STUDY, index);
    },
    getUserStudyOnboarding() {
        return getUserStudyOnboarding();
    },
    updateUserStudyOnboarding(_, payload) {
        return updateUserStudyOnboarding(payload);
    },
};

const getters = {
    semesters: (state) => state.semesters,
    studyPrograms: (state) => state.studyPrograms,
    userStudies: (state) => state.userStudies,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
