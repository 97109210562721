/* these variables should not be reachable outside this file to prevent manipulation */
const idMap = new WeakMap<object, number>();
let idCounter = 0;

/**
 * This class generates unique ids for objects.
 */
export class IdGenerator {
    /**
     * Gets a unique id for an object.
     * It will always return the same id for the same object.
     * @param obj
     * @returns number
     */
    static get(obj: object) {
        if (!idMap.has(obj)) {
            // eslint-disable-next-line no-plusplus
            idMap.set(obj, ++idCounter);
        }
        return idMap.get(obj) as number;
    }
}

export default {
    IdGenerator,
};
