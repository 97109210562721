import { type Writeable } from '@/types/misc';
import { getParentPath } from './dom';

/**
 * Event properties cannot be looped over,
 * so this function extracts all enumerable attributes.
 */
function getEventData<T extends Event>(e: T) {
    const data: Partial<Writeable<T>> = {};
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const attr in e) {
        data[attr as keyof T] = e[attr as keyof T];
    }
    return data as Writeable<T>;
}

/**
 * Creates a new event based on the provided one
 */
export function reCreateEvent<T extends Event, C extends EventInit>(e: T, options: C) {
    const { cancelable, eventPhase, ...data } = getEventData(e);

    return new (e.constructor as typeof Event)(e.type, {
        ...data,
        ...options,
    }) as T;
}

/**
 * Dispatches event again
 */
export function reDispatchEvent<T extends Event, C extends EventInit>(e: T, options: C) {
    return !!e.target?.dispatchEvent(reCreateEvent(e, options));
}

/**
 * Some browsers do not provide the path for events.
 * So use this helper to always get one.
 */
export function getPath<T extends Event>(event: T) {
    // some browsers have path, some have composedPath
    if ('path' in event) return event.path as Element[];

    if ('composedPath' in event) return event.composedPath() as Element[];

    // event could also target other objects than dom nodes
    if ((event as T).target instanceof Element) {
        return getParentPath((event as T).target as Element);
    }

    return [];
}

/**
 * Retrieves link node in event's element chain
 */
export function findLinkInEvent<T extends UIEvent>(event: T) {
    return getPath(event).find((el) => 'tagName' in el && el.tagName === 'A') as HTMLLinkElement | undefined;
}
