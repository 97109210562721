import { ref, computed } from 'vue';
import { once } from 'lodash-es';
import { hasWebSupport } from '@helpers/browser';
import { useAccessToken } from './accessToken';

export const useCommonHeaders = once(() => {
    const accept = ref(['application/json', 'text/plain', '*/*']);
    const { accessToken } = useAccessToken();

    // add webp accept header if supported
    hasWebSupport.then((result) => {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (!result || !result.animation) return;

        accept.value.push('image/webp');
    });

    return {
        Accept: computed(() => accept.value.join()),
        Authorization: computed(() => (accessToken.value ? `Bearer ${accessToken.value}` : undefined)),
    };
});
