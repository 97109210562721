import { createStore } from 'vuex';
import flashMessages from '@/store/modules/flash-messages';
import search from '@/store/modules/search';
import ui from '@/store/modules/ui';
import ad from '@/store/modules/ad';
import document from '@/store/modules/document';
// authentication logic
import auth from '@/store/modules/auth';
// current user state
import user from '@/store/modules/user';
// profile logic (not necessarily current user)
import profile from '@/store/modules/profile';
import platform from '@/store/modules/platform';
import post from '@/store/modules/post';
import postDetail from '@/store/modules/postDetail';
import university from '@/store/modules/university';
import company from '@/store/modules/company';
import course from '@/store/modules/course';
import courseExpert from '@/store/modules/course-expert';
import callout from '@/store/modules/callout';
import group from '@/store/modules/group';
import newsfeed from '@/store/modules/newsfeed';
import reward from '@/store/modules/reward';
import upload from '@/store/modules/upload';
import careerCorner from '@/store/modules/career-corner';
import settings from '@/store/modules/settings';
import chats from '@/store/modules/chats';
import studylists from '@/store/modules/studylists';
import study from '@/store/modules/study';
import notifications from './modules/notifications';
import ceDashboard from './modules/ceDashboard';
import dsa from './modules/dsa';
import xandr from './modules/xandr';
import preferReducedMotionSync from './plugins/preferReducedMotionSync';
import visibilitySync from './plugins/visibilitySync';
import focusSync from './plugins/focusSync';
import PersistPlugin from './plugins/persist';
import EventBusPlugin from './plugins/eventBus';

export default createStore({
    modules: {
        ad,
        auth,
        callout,
        careerCorner,
        ceDashboard,
        chats,
        company,
        course,
        courseExpert,
        document,
        flashMessages,
        group,
        newsfeed,
        notifications,
        platform,
        post,
        postDetail,
        profile,
        reward,
        search,
        settings,
        study,
        studylists,
        ui,
        university,
        upload,
        user,
        dsa,
        xandr,
    },
    plugins: [
        EventBusPlugin,
        preferReducedMotionSync,
        focusSync,
        visibilitySync,
        PersistPlugin,
    ],
});
