<template>
    <TopBarLogged v-if="$store.getters['auth/isLoggedIn']" />
    <TopBarGuest v-else />
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'TheTopBar',

    components: {
        TopBarGuest: defineAsyncComponent(() => import('./TopBarGuest.vue')),
        TopBarLogged: defineAsyncComponent(() => import('./TopBarLogged.vue')),
    },
};
</script>
