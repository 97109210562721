import { toRef } from 'vue';
import { once } from 'lodash-es';
import { useCommonHeaders } from '@/api/utils/composables/commonHeaders';
import { useAxios } from '@/api/utils/composables/axios';
import { registerInterceptors } from '@/api/utils/interceptors';
import networkErrorInterceptor from '@/api/utils/interceptors/networkErrorInterceptor';
import tokenRefreshInterceptor from '@/api/utils/interceptors/tokenRefreshInterceptor';
import transformDatesInterceptor from '@/api/utils/interceptors/transformDatesInterceptor';
import { HTTP_STATUS } from '@/utils/networkUtils';
import maintenanceInterceptor from '@/api/utils/interceptors/maintenanceInterceptor';

export const useGatewayHeaders = once(() => ({
    ...useCommonHeaders(),
    // just something for our legacy-api to identify the request as coming from the web
    'X-SD-Platform': 'Web',
}));

function createGateway() {
    const instance = useAxios(
        toRef({
            baseURL: import.meta.env.MIX_GATEWAY_URL,
            headers: useGatewayHeaders(),
        }),
    );

    registerInterceptors(instance, [
        networkErrorInterceptor,
        maintenanceInterceptor,
        tokenRefreshInterceptor(
            instance,
            // The gateway does not return 401, but 403 instead.
            // But if we receive a 403 with the `x-sd-granted` header, it is not a gateway/token issue, as this header is set if the token is valid.
            // But we might also receive a 401 from our api services if no token at all is provided.
            (response) =>
                [HTTP_STATUS.FORBIDDEN, HTTP_STATUS.UNAUTHORIZED].includes(response.status) &&
                !response.headers['x-sd-granted'],
        ),
        transformDatesInterceptor,
    ]);

    return instance;
}

export const gateway = createGateway();
