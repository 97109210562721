const fallback = (val) => val;

function registerResponseInterceptor(axios, interceptor) {
    axios.interceptors.response.use(
        interceptor.handleResponse || fallback,
        interceptor.handleResponseError || ((val) => Promise.reject(val)),
    );
}

function registerRequestInterceptor(axios, interceptor) {
    axios.interceptors.request.use(interceptor.handleRequest || fallback, interceptor.handleRequestError || fallback);
}

/**
 * Based on the interceptor functions, it will be either registered as request or response itnerceptor (or both)
 * @param {*} axios
 * @param {*} interceptor
 */
function registerInterceptor(axios, interceptor) {
    if (interceptor.handleRequest || interceptor.handleRequestError) {
        registerRequestInterceptor(axios, interceptor);
    }

    if (interceptor.handleResponse || interceptor.handleResponseError) {
        registerResponseInterceptor(axios, interceptor);
    }
}

export function registerInterceptors(axios, interceptors) {
    return interceptors.map((interceptor) => registerInterceptor(axios, interceptor));
}
