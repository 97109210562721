export enum ContainerClass {
    SUCCESS = 'border-green-200 text-green-300',
    ERROR = 'border-red-200 text-red-400',
    DEFAULT = 'border-black-300 text-black-600',
}

export enum ProgressClass {
    SUCCESS = 'bg-green-300',
    ERROR = 'bg-red-400',
    DEFAULT = 'bg-blue-600',
}

export enum BtnColor {
    SUCCESS = 'positive',
    ERROR = 'negative',
    DEFAULT = 'primary',
}
