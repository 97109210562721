import { type Slots, defineComponent } from 'vue';

export interface ModalProps {
    closeable?: boolean;
    [key: string]: unknown;
}

export interface ModalEvents<ReturnType> {
    close?: (val?: ReturnType) => void;
    [key: string]: unknown;
}

export type ModalSlots = Slots;

export default defineComponent({
    name: 'ModalMixin',
    props: {
        /**
         * Whether the modal can be closed by clicking on the backdrop.
         */
        closeable: {
            type: Boolean,
            // eslint-disable-next-line vue/no-boolean-default
            default: true,
        },
    },
    emits: ['close'],
    methods: {
        close(returnValue: unknown = undefined) {
            /**
             * Emits close event that will close the modal and return its value to the calling function.
             */
            this.$emit('close', returnValue);
        },
    },
});
