import { createUsm, getCountries, getDegreeTypes, getMajors, getPlatformStats } from '@/api/backend/platform';

const state = {
    countries: {
        list: [],
        loading: false,
    },
    generalStats: {
        users: 0,
        documents: 0,
        posts: 0,
    },
    majors: {
        list: sdWindow.majors ?? [],
        loading: false,
    },
    degreeTypes: {
        list: sdWindow.degree_type ?? [],
        loading: false,
    },
    newUsms: [],
};

const types = {
    UPDATE_STATS_PLATFORM: 'UPDATE_STATS_PLATFORM',
    SET_MAJORS: 'SET_MAJORS',
    LOADING_MAJORS: 'LOADING_MAJORS',
    SET_DEGREE_TYPES: 'SET_DEGREE_TYPES',
    LOADING_DEGREE_TYPES: 'LOADING_DEGREE_TYPES',
    ADD_NEW_USM: 'ADD_NEW_USM',
    SET_COUNTRIES: 'SET_COUNTRIES',
    LOADING_COUNTRIES: 'LOADING_COUNTRIES',
};

const mutations = {
    [types.UPDATE_STATS_PLATFORM](state, payload) {
        state.generalStats = payload;
    },
    [types.SET_MAJORS](state, payload) {
        state.majors.list = payload;
    },
    [types.LOADING_MAJORS](state, val) {
        state.majors.loading = val;
    },
    [types.SET_DEGREE_TYPES](state, payload) {
        state.degreeTypes.list = payload;
    },
    [types.LOADING_DEGREE_TYPES](state, val) {
        state.degreeTypes.loading = val;
    },
    [types.ADD_NEW_USM](state, usm) {
        state.newUsms.push(usm);
    },
    [types.SET_COUNTRIES](state, countries) {
        state.countries.list = countries;
    },
    [types.LOADING_COUNTRIES](state, val) {
        state.countries.loading = val;
    },
};

const actions = {
    retrievePlatformStats({ commit }) {
        return getPlatformStats().then((response) => commit(types.UPDATE_STATS_PLATFORM, response.data.stats));
    },
    async useMajors({ state, commit }) {
        if (state.majors.loading || state.majors.list.length) return;

        try {
            commit(types.LOADING_MAJORS, true);
            commit(types.SET_MAJORS, await getMajors());
        } catch (e) {
            commit(types.LOADING_MAJORS, false);
        }
    },
    async useDegreeTypes({ state, commit }) {
        if (state.degreeTypes.loading || state.degreeTypes.list.length) return;

        try {
            commit(types.LOADING_DEGREE_TYPES, true);
            commit(types.SET_DEGREE_TYPES, await getDegreeTypes());
        } catch (e) {
            commit(types.LOADING_DEGREE_TYPES, false);
        }
    },
    createNewUsm({ commit }, usm) {
        return createUsm(usm).then((response) => {
            if (response.data.success) {
                commit(types.ADD_NEW_USM, response.data.data);
            }
            return response;
        });
    },
    async useCountries({ commit, state }) {
        if (state.countries.loading || state.countries.list.length) return;

        try {
            commit(types.LOADING_COUNTRIES, true);
            commit(types.SET_COUNTRIES, await getCountries());
        } catch (e) {
            commit(types.LOADING_COUNTRIES, false);
        }
    },
};

const getters = {
    statsPlatform: (state) => state.generalStats,
    degreeTypes: (state) => state.degreeTypes.list,
    majors: (state) => state.majors.list,
    newUsms: (state) => state.newUsms,
    countries: (state, getters, rootState, rootGetters) => {
        /* eslint-disable camelcase */
        return state.countries.list.map(({ name_de, name_en, ...country }) => ({
            ...country,
            // adding name in the current language to our contry object
            // TODO: do that in the backend
            name: rootGetters['ui/languageCode'] === 'de' ? name_de : name_en,
        }));
        /* eslint-enable camelcase */
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
