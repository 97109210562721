import { searchParamsToObject } from '@/helpers/url';
import type { RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';

/**
 * Adjusts the route location to preserve the url based on the `from` location.
 * This only works for target routes that utilize the `pathMatch` param.
 */
export function keepUrl(to: RouteLocationNamedRaw, from: RouteLocationNormalized) {
    return {
        ...to,
        params: {
            pathMatch: from.path.split('/').slice(1),
            ...to.params,
        },
        query: from.query,
        hash: from.hash,
        replace: true,
    };
}

/**
 * Returns previous location for a route navigation.
 * The location is evaluated in this order:
 *   1. location from where the new route is redirected from
 *   2. location of the previous route
 *   3. location of the the new route if the name patches its path
 *   4. location of current browser url
 */
export function getPreviousLocation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
): RouteLocationNormalized {
    const prev = to.redirectedFrom ?? (from.matched.length ? from : to);

    // previous location indicates the homepage, but wasn't. So we adjust the location based on the current url.
    if (prev.path === '/' && prev.matched.some((match) => match.name !== 'home')) {
        const url = new URL(window.location.href);
        const fullPath = `${url.pathname}${url.search}${url.hash}`;

        return {
            ...prev,
            fullPath,
            path: url.pathname,
            hash: url.hash,
            query: searchParamsToObject(url.searchParams),
        };
    }

    return prev;
}
