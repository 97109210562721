import { sortBy } from 'lodash-es';
import {
    getDocuments,
    getFlashcards,
    joinCourse,
    leaveCourse,
    getFeed,
    createCourse,
    getUserCourses,
    getCourseById,
} from '@/api/backend/course';
import {
    deletePost,
    editPost,
    reportPost,
    togglePinnedPost,
    createComment,
    editComment,
    deleteComment,
    reportComment,
    toggleBestComment,
    getComments,
} from '@/api/backend/post';
import { mapAggregationsToObject } from '@/utils/searchUtils';
import { injectXandrAds } from '@/utils/xandrUtils';

const types = {
    TOGGLE_BEST_COMMENT: 'TOGGLE_BEST_COMMENT',
    PROPAGATE_IDENTITY_CHANGE: 'PROPAGATE_IDENTITY_CHANGE',
    SET_COURSE_DATA: 'SET_COURSE_DATA',
    UPDATE_DOCUMENTS_LIST: 'UPDATE_DOCUMENTS_LIST',
    UPDATE_DOCUMENTS_PROGRESS: 'UPDATE_DOCUMENTS_PROGRESS',
    UPDATE_CAN_LOAD_MORE_DOCUMENTS: 'UPDATE_CAN_LOAD_MORE_DOCUMENTS',
    UPDATE_DOCUMENTS_TOTAL_COUNT: 'UPDATE_DOCUMENTS_TOTAL_COUNT',
    UPDATE_FLASHCARDS_LIST: 'UPDATE_FLASHCARDS_LIST',
    UPDATE_FLASHCARDS_PROGRESS: 'UPDATE_FLASHCARDS_PROGRESS',
    UPDATE_CAN_LOAD_MORE_FLASHCARDS: 'UPDATE_CAN_LOAD_MORE_FLASHCARDS',
    UPDATE_FLASHCARDS_TOTAL_COUNT: 'UPDATE_FLASHCARDS_TOTAL_COUNT',
    UPDATE_FEED_LIST: 'UPDATE_FEED_LIST',
    UPDATE_FEED_PROGRESS: 'UPDATE_FEED_PROGRESS',
    UPDATE_CAN_LOAD_MORE_FEED: 'UPDATE_CAN_LOAD_MORE_FEED',
    UPDATE_JOIN_PROGRESS: 'UPDATE_JOIN_PROGRESS',
    UPDATE_LEAVE_PROGRESS: 'UPDATE_LEAVE_PROGRESS',
    UPDATE_META_DATA: 'UPDATE_META_DATA',
    UPDATE_POST_COMMENTS: 'UPDATE_POST_COMMENTS',
    REMOVE_POST_FROM_FEED: 'REMOVE_POST_FROM_FEED',
    REMOVE_COMMENT_FROM_POST: 'REMOVE_COMMENT_FROM_POST',
    SET_REPORTED_POST: 'SET_REPORTED_POST',
    SET_REPORTED_COMMENT: 'SET_REPORTED_COMMENT',
    USER_COURSE_LIST: 'USER_COURSE_LIST',
    USER_COURSE_LIST_LOADING: 'USER_COURSE_LIST_LOADING',
    ADD_OR_UPDATE_POST_IN_FEED: 'ADD_OR_UPDATE_POST_IN_FEED',
    ADD_OR_UPDATE_COMMENT_IN_POST: 'ADD_OR_UPDATE_COMMENT_IN_POST',
};

const state = {
    course: {},
    joinProgress: false,
    leaveProgress: false,
    documents: {
        list: [],
        progress: false,
        canLoadMore: true,
        totalCount: 0,
        filters: null,
        metaData: null,
    },
    flashcards: {
        list: [],
        progress: false,
        canLoadMore: true,
        totalCount: 0,
    },
    feed: {
        list: [],
        progress: false,
        canLoadMore: true,
    },
    user: {
        courses: [],
        coursesLoading: false,
        coursesLoaded: false,
    },
    courseSelectedId: null,
    courseSelectedName: null,
};

const mutations = {
    [types.PROPAGATE_IDENTITY_CHANGE](state, { postId, user, isAnonymous }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        if (post.isOwner) {
            if (isAnonymous) {
                post.postDetails.anonymousUser = user;
                post.postDetails.user = null;
            } else {
                post.postDetails.user = user;
                post.postDetails.anonymousUser = null;
            }
            post.postDetails.isAnonymous = isAnonymous;
        }

        /* eslint-disable no-param-reassign */
        post.comments.forEach((comment) => {
            if (comment.isOwner) {
                if (isAnonymous) {
                    comment.anonymousUser = user;
                    comment.user = null;
                } else {
                    comment.user = user;
                    comment.anonymousUser = null;
                }
                comment.isAnonymous = isAnonymous;
            }
        });
        /* eslint-enable no-param-reassign */
    },
    [types.SET_COURSE_DATA](state, payload) {
        state.course = payload;
    },
    [types.UPDATE_DOCUMENTS_LIST](state, { shouldAppendResult, results }) {
        const { data } = results;

        if (shouldAppendResult) {
            state.documents.list.push(...data);
        } else {
            state.documents.list = data;
        }
    },
    [types.UPDATE_DOCUMENTS_PROGRESS](state, payload) {
        state.documents.progress = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_DOCUMENTS](state, payload) {
        state.documents.canLoadMore = payload;
    },
    [types.UPDATE_DOCUMENTS_TOTAL_COUNT](state, payload) {
        state.documents.totalCount = payload;
    },
    [types.UPDATE_JOIN_PROGRESS](state, payload) {
        state.joinProgress = payload;
    },
    [types.UPDATE_LEAVE_PROGRESS](state, payload) {
        state.leaveProgress = payload;
    },
    [types.UPDATE_FEED_LIST](state, payload) {
        state.feed.list.push(...payload);
    },
    [types.UPDATE_FEED_PROGRESS](state, payload) {
        state.feed.progress = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_FEED](state, payload) {
        state.feed.canLoadMore = payload;
    },
    [types.UPDATE_META_DATA](state, payload) {
        state.documents.metaData = payload;
    },
    [types.UPDATE_POST_COMMENTS](state, { postId, comments = {} }) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list[postIndex].comments = comments;
    },
    [types.UPDATE_FLASHCARDS_LIST](state, { shouldAppendResult, results }) {
        const { data } = results;

        if (shouldAppendResult) {
            state.flashcards.list.push(...data);
        } else {
            state.flashcards.list = data;
        }
    },
    [types.UPDATE_FLASHCARDS_PROGRESS](state, payload) {
        state.flashcards.progress = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_FLASHCARDS](state, payload) {
        state.flashcards.canLoadMore = payload;
    },
    [types.UPDATE_FLASHCARDS_TOTAL_COUNT](state, payload) {
        state.flashcards.totalCount = payload;
    },
    [types.SET_REPORTED_POST](state, postId) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        post.postDetails.isReported = true;
        post.postDetails.userReport = true;
    },
    [types.SET_REPORTED_COMMENT](state, { postId, commentId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const comment = post.comments.find((item) => {
            return item.id === commentId;
        });
        comment.isReported = true;
        comment.userReport = true;
    },
    [types.REMOVE_POST_FROM_FEED](state, postId) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list.splice(postIndex, 1);
    },

    [types.REMOVE_COMMENT_FROM_POST](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const commentIndex = post.comments.findIndex((comment) => {
            return comment.id === commentId;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found');
        }

        post.comments.splice(commentIndex, 1);
    },
    [types.TOGGLE_BEST_COMMENT](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (post === undefined) {
            throw new Error('Post not found, cannot toggle best comment.');
        }

        const bestComment = post.comments.find((comment) => comment.id === commentId);

        if (bestComment === undefined) {
            throw new Error('Comment not found, cannot toggle best comment.');
        }

        post.postDetails.hasBestAnswer = !post.postDetails.hasBestAnswer;
        bestComment.isBest = !bestComment.isBest;
    },
    [types.USER_COURSE_LIST](state, data) {
        state.user.courses = data;
        state.user.coursesLoaded = true;
    },
    [types.USER_COURSE_LIST_LOADING](state, data) {
        state.user.coursesLoading = data;
    },
    [types.ADD_OR_UPDATE_POST_IN_FEED](state, post) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            if (feedItem.type !== 'post' || post.type !== 'post') {
                return false;
            }

            let contextMatches;

            if (feedItem.postDetails.subContext) {
                contextMatches = feedItem.postDetails.subContext.type === post.subContext.type;
            } else {
                contextMatches = feedItem.postDetails.context === post.postDetails.context;
            }

            return contextMatches && feedItem.postDetails.id === post.postDetails.id;
        });

        // if the post exists we just replace it with its updated version
        if (postIndex !== -1) {
            /* eslint-disable no-param-reassign */
            post.comments = state.feed.list[postIndex].comments;
            post.totalComments = state.feed.list[postIndex].totalComments;
            post.isPinned = state.feed.list[postIndex].isPinned;
            /* eslint-enable no-param-reassign */
            state.feed.list[postIndex] = post;
            return;
        }

        // if it's a new post we add it to the beginning of the feed
        state.feed.list.unshift(post);
    },
    [types.ADD_OR_UPDATE_COMMENT_IN_POST](state, comment) {
        const post = state.feed.list.find((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const contextMatches = feedItem.postDetails.context === comment.context.type;
            return contextMatches && feedItem.postDetails.id === comment.context.id;
        });

        const commentIndex = post.comments.findIndex((postComment) => {
            return postComment.id === comment.id;
        });

        // if the comment exists we just replace it with its updated version
        if (commentIndex !== -1) {
            post.comments[commentIndex] = comment;
            return;
        }

        // if it's a new comment we push it to the post
        post.comments.push(comment);
        post.totalComments += 1;
    },
};

const actions = {
    getCourseById({ commit }, courseId) {
        return getCourseById(courseId).then(({ data }) => {
            commit('SET_COURSE_DATA', data);
            return data;
        });
    },
    join({ commit, dispatch }, { course, fromCoursePage = false }) {
        commit('UPDATE_JOIN_PROGRESS', true);
        return joinCourse(course.id, fromCoursePage)
            .then((response) => {
                dispatch('user/pushCourse', response.data.data, { root: true });
            })
            .finally(() => commit('UPDATE_JOIN_PROGRESS', false));
    },
    leave({ commit, dispatch }, { course, keepFollowedDocuments = false }) {
        commit('UPDATE_LEAVE_PROGRESS', true);
        return leaveCourse(course.id, keepFollowedDocuments)
            .then(() => {
                dispatch('user/popCourse', course, { root: true });
            })
            .finally(() => commit('UPDATE_LEAVE_PROGRESS', false));
    },
    retrieveDocuments({ commit, rootGetters }, { filter, page, shouldAppendResult }) {
        commit('UPDATE_DOCUMENTS_PROGRESS', true);
        return getDocuments(filter, page)
            .then((response) => {
                const { results, meta } = response.data.data;
                results.data = injectXandrAds(results.data, rootGetters['auth/isLoggedIn'], 'document');

                commit('UPDATE_META_DATA', meta);
                commit('UPDATE_DOCUMENTS_TOTAL_COUNT', results.total);
                commit('UPDATE_DOCUMENTS_LIST', { shouldAppendResult, results });
                commit('UPDATE_CAN_LOAD_MORE_DOCUMENTS', results.to < results.total);
            })
            .finally(() => commit('UPDATE_DOCUMENTS_PROGRESS', false));
    },
    retrieveFlashcards({ commit, rootGetters }, { filter, page, shouldAppendResult }) {
        commit('UPDATE_FLASHCARDS_PROGRESS', true);
        return getFlashcards(filter, page)
            .then((response) => {
                const { results } = response.data.data;
                results.data = injectXandrAds(results.data, rootGetters['auth/isLoggedIn'], 'flashcard');

                commit('UPDATE_FLASHCARDS_LIST', { shouldAppendResult, results });
                commit('UPDATE_FLASHCARDS_TOTAL_COUNT', results.total);
                commit('UPDATE_CAN_LOAD_MORE_FLASHCARDS', results.to < results.total);
            })
            .finally(() => commit('UPDATE_FLASHCARDS_PROGRESS', false));
    },
    retrieveFeed({ commit, getters, rootGetters }, { page }) {
        commit('UPDATE_FEED_PROGRESS', true);
        return getFeed(getters.course.id, page)
            .then((response) => {
                const { data, meta } = response.data;

                commit('UPDATE_FEED_LIST', injectXandrAds(data || [], rootGetters['auth/isLoggedIn']));
                commit('UPDATE_CAN_LOAD_MORE_FEED', getters.feedList.length < meta.total);
            })
            .finally(() => commit('UPDATE_FEED_PROGRESS', false));
    },
    createCourse({ dispatch }, payload) {
        return createCourse(payload).then((response) => {
            dispatch('user/pushCourse', response.data.data, {
                root: true,
            });
            return true;
        });
    },
    useUserCourses({ getters, dispatch }) {
        if (getters.userCoursesLoaded || getters.userCoursesLoading) return;

        dispatch('retrieveUserCourses');
    },
    retrieveUserCourses({ commit }) {
        commit(types.USER_COURSE_LIST_LOADING, true);

        return getUserCourses()
            .then((response) => commit('USER_COURSE_LIST', response.data?.courses || []))
            .finally(() => commit(types.USER_COURSE_LIST_LOADING, false));
    },
    async loadPostComments({ commit }, { postId, context = 'course' }) {
        const response = await getComments(context, postId);

        commit(types.UPDATE_POST_COMMENTS, {
            postId,
            comments: response.data.comments,
        });
    },
    propagateIdentityChange({ commit }, { postId, user, isAnonymous }) {
        commit(types.PROPAGATE_IDENTITY_CHANGE, {
            postId,
            user,
            isAnonymous,
        });
    },
    setCourseData({ commit }, payload) {
        commit(types.SET_COURSE_DATA, payload);
    },
    async deletePost({ commit }, { context, postId }) {
        await deletePost(context, postId);
        commit(types.REMOVE_POST_FROM_FEED, postId);
    },
    async editPost({ commit }, { context, post }) {
        const response = await editPost(context, post);
        commit(types.ADD_OR_UPDATE_POST_IN_FEED, response.data);
        return { post: response.data };
    },
    async reportPost({ commit }, { context, postId, reason, additional }) {
        await reportPost(context, postId, reason, additional);
        commit(types.SET_REPORTED_POST, postId);
    },
    // eslint-disable-next-line no-unused-vars
    async togglePinnedPost({ commit }, { context, postId, targetId }) {
        await togglePinnedPost(context, postId, targetId);
    },
    async createComment({ commit }, { context, comment }) {
        const response = await createComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async deleteComment({ commit }, { context, postId, commentId }) {
        await deleteComment(context, commentId);
        commit(types.REMOVE_COMMENT_FROM_POST, { commentId, postId });
    },
    async editComment({ commit }, { context, comment }) {
        const response = await editComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async reportComment({ commit }, { context, postId, commentId, reason, additional }) {
        await reportComment(context, commentId, reason, additional);
        commit(types.SET_REPORTED_COMMENT, { postId, commentId });
    },
    async toggleBestComment({ commit }, { context, postId, commentId }) {
        await toggleBestComment(context, commentId);
        commit(types.TOGGLE_BEST_COMMENT, { commentId, postId });
    },
    addPostToFeed({ commit }, post) {
        commit(types.ADD_OR_UPDATE_POST_IN_FEED, post);
    },
    setXandrSponsoringAd({ commit }, data) {
        commit(types.SET_XANDR_SPONSORING_COURSE_AD, data);
    },
};

const getters = {
    course: (state) => {
        return state.course;
    },
    documentsList: (state) => {
        return state.documents.list !== undefined ? state.documents.list : [];
    },
    documentsProgress: (state) => {
        return state.documents.progress !== undefined ? state.documents.progress : false;
    },
    documentsCanLoadMore: (state) => {
        return state.documents.canLoadMore;
    },
    documentsTotalCount: (state) => {
        return state.documents.totalCount;
    },
    flashcardsList: (state) => {
        return state.flashcards.list !== undefined ? state.flashcards.list : [];
    },
    flashcardsProgress: (state) => {
        return state.flashcards.progress !== undefined ? state.flashcards.progress : false;
    },
    flashcardsCanLoadMore: (state) => {
        return state.flashcards.canLoadMore;
    },
    flashcardsTotalCount: (state) => {
        return state.flashcards.totalCount;
    },
    feedList: (state) => {
        if (state.feed.list !== undefined) {
            const feedItems = state.feed.list;

            return sortBy(feedItems, [
                // eslint-disable-next-line func-names
                function (item) {
                    return !item.isPinned;
                },
            ]);
        }
        return [];
    },
    feedProgress: (state) => {
        return state.feed.progress !== undefined ? state.feed.progress : false;
    },
    feedCanLoadMore: (state) => {
        return state.feed.canLoadMore;
    },
    leaveProgress: (state) => state.leaveProgress,
    joinProgress: (state) => state.joinProgress,
    courseSelectedId: (state) => state.courseSelectedId,
    courseSelectedName: (state) => state.courseSelectedName,
    documentTypeTotals: (state) => mapAggregationsToObject(state?.documents?.metaData?.aggregations?.file_content_type),
    hasPinnedPost: (state) => state.feed.list.some((item) => item.isPinned === true),
    userCourses: (state) => state.user.courses,
    userCoursesLoading: (state) => state.user.coursesLoading,
    userCoursesLoaded: (state) => state.user.coursesLoaded,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
