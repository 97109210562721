/**
 * Converts URLSearchParams object to native js object.
 * Params like 'param[]' will be turned into Arrays.
 *
 * Inverse of objectToSearchParams.
 * IMPORTANT: If params contain [ or ] that could create unforseen side-effects
 * @param {URLSearchParams} params
 * @returns Object
 */
export function searchParamsToObject(params) {
    function processEntry(obj, [param, value]) {
        // extract values from params like 'param[attribute]' or 'param[]'
        const match = param.match(/([^[]+)\[([^\]]*)\](.*)/);

        if (!match) {
            // no object, so assign value directly
            // eslint-disable-next-line no-param-reassign
            obj[param] = value;
            return obj;
        }

        const isArray = !match[2]; // no object attribute matched
        const subObj = obj[match[1]] || (isArray ? [] : {});
        const subParam = `${isArray ? subObj.length : match[2]}${match[3]}`;

        // eslint-disable-next-line no-param-reassign
        obj[match[1]] = processEntry(subObj, [subParam, value]);
        return obj;
    }

    return [...params.entries()].reduce(processEntry, {});
}

/**
 * Converts native js object to URLSearchParams similar to jQuerys $.param() function.
 * Nested Arrays wil be turned into individual params like 'param[]'
 * Nested Objects will be turned into params like 'param[attribute]'
 * Arrays and objects can be nested infinitely.
 *
 * Inverse of searchParamsToObject
 * IMPORTANT: If params contain [ or ] that could create unforseen side-effects
 * @param {Object} obj
 * @returns {URLSearchParams}
 */
export function objectToSearchParams(obj) {
    function processEntry(params, [key, value]) {
        if (value instanceof Object) {
            if (value instanceof Array) {
                value.forEach((item) => processEntry(params, [`${key}[]`, item]));
            } else {
                Object.entries(value).forEach((entry) => processEntry(params, [`${key}[${entry[0]}]`, entry[1]]));
            }
        } else {
            params.append(key, value);
        }

        return params;
    }

    return Object.entries(obj).reduce(processEntry, new URLSearchParams());
}

/**
 * Converts object to url query string
 * @param {Object} obj
 * @returns {String}
 */
export function objectToQuery(obj) {
    return objectToSearchParams(obj).toString();
}

/**
 * Converts url query parameters to object structure
 * @param {String} query or url
 * @returns {Object}
 */
export function queryToObject(query) {
    return searchParamsToObject(new URLSearchParams(query));
}

/**
 * Set the window.opener property to null
 * IMPORTANT: For security reasons we should start using this new function instead of using window.open() for external links
 * @param params
 * @returns {Window}
 */
export function openNewWindow(...params) {
    const newWindow = window.open(...params);
    newWindow.opener = null;

    return newWindow;
}
