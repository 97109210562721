import type { DataResponse } from '@/api/types';
import route from '@router/route';
import { backend } from '.';

export function getPlatformStats() {
    return backend.post(route('guests.stats.platform'));
}

export function getCountries() {
    return backend
        .get<DataResponse<unknown>>(route('suggest.countries', { with_prefixes: false }))
        .then((response) => response.data);
}

export function getDegreeTypes() {
    return backend.get<DataResponse<unknown>>(route('degreeType.index')).then((res) => res.data);
}

export function getMajors() {
    return backend.get<DataResponse<unknown>>(route('majors.index')).then((res) => res.data?.data);
}

export function getEmployees() {
    return backend.get<DataResponse<unknown>>(route('employee.index')).then((res) => res.data);
}

export function getEmployeeTeams() {
    return backend.get<DataResponse<unknown>>(route('employee-team.index')).then((res) => res.data);
}

export function createUsm(usm: {
    usm_university_id: number;
    usm_name: string;
    usm_degree_type_id: number;
    usm_major: number;
    usm_major_fixed_ids: number;
}) {
    return backend.post(route('usm.store'), usm);
}

export function switchLocale(locale: string) {
    return backend.post(route('locale.switch'), {
        locale,
    });
}
