<template>
    <div
        v-if="messages && messages.length > 0"
        class="flash-message fixed z-70 top-0 right-0 p-4 flex flex-col w-full md:w-160"
        @click.stop=""
    >
        <Alert
            v-for="message in messages"
            :key="message.key"
            :appearance="message.appearance"
            :progress="message.progress"
            class="mb-4"
            @button-click="dismiss(message.key)"
            @auto-close="dismiss(message.key)"
        >
            <div>{{ message.content }}</div>
        </Alert>
    </div>
</template>

<script>
import Alert from '@components/Alert';
import { mapGetters } from 'vuex';

export default {
    name: 'FlashMessages',
    components: { Alert },
    computed: {
        ...mapGetters('flashMessages', ['messages']),
    },
    methods: {
        dismiss(key) {
            this.$store.dispatch('flashMessages/dismiss', key);
        },
    },
};
</script>

<style lang="scss" scoped>
.flash-message {
    -webkit-animation: flashmessage-show 0.5s;
    animation: flashmessage-show 0.5s;
}

@-webkit-keyframes flashmessage-show {
    0% {
        transform: translateY(-0.625em) rotateZ(2deg);
    }
    33% {
        transform: translateY(0) rotateZ(-2deg);
    }
    66% {
        transform: translateY(0.3125em) rotateZ(2deg);
    }
    100% {
        transform: translateY(0) rotateZ(0);
    }
}

@keyframes flashmessage-show {
    0% {
        transform: translateY(-0.625em) rotateZ(2deg);
    }
    33% {
        transform: translateY(0) rotateZ(-2deg);
    }
    66% {
        transform: translateY(0.3125em) rotateZ(2deg);
    }
    100% {
        transform: translateY(0) rotateZ(0);
    }
}

@-webkit-keyframes flashmessage-hide {
    100% {
        transform: rotateZ(1deg);
        opacity: 0;
    }
}

@keyframes flashmessage-hide {
    100% {
        transform: rotateZ(1deg);
        opacity: 0;
    }
}
</style>
