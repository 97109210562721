let items = [];

const arrayStorage = {
    clear() {
        items = [];
    },

    getItem(key) {
        return items[key];
    },

    key(index) {
        return Array.indexOf(items, index);
    },

    removeItem(key) {
        delete items[key];
    },

    setItem(key, value) {
        items[key] = String(value);
    },
};

Object.defineProperty(arrayStorage, 'length', {
    get() {
        return Object.keys(items).length;
    },
});

const getStorage = () => {
    try {
        const key = 'lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit';
        localStorage.setItem(key, key);
        localStorage.removeItem(key);
        return localStorage;
    } catch (e) {
        return arrayStorage;
    }
};

export const storage = getStorage();
