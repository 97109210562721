import type { EnumToUnion } from '@/types/misc';

export enum Locale {
    DE = 'de',
    EN = 'en',
    EN_GB = 'en-GB',
}

export const availableLocales = [Locale.DE, Locale.EN, Locale.EN_GB];

export const defaultLocale = Locale.EN;

export const defaultNamespace = 'common';

export function getLanguageCodeFromLocale(locale: EnumToUnion<Locale>) {
    return locale.slice(0, 2) as Locale.DE | Locale.EN;
}
