import { i18n } from '@/plugins/i18n';

function filterPostId(id) {
    return (item) => item.type === 'post' && item.postDetails.id === id;
}

/**
 * Finds post by id
 * @param {*} posts
 * @param {*} id
 */
export function findPostbyId(feed, id) {
    return feed.find(filterPostId(id));
}

/**
 * find post index by id
 * @param {*} feed
 * @param {*} id
 */
export function findPostIndexById(feed, id) {
    return feed.findIndex(filterPostId(id));
}

/**
 * Returns faked post
 * @returns {*}
 */
export function getFakedPost() {
    const now = new Date();
    return {
        canEdit: false,
        comments: [],
        group: {
            id: 0,
            name: i18n.t('newsfeed:fakePost.courseName'),
            slug: 'example-course',
        },
        document: null,
        feedType: 'newsfeed',
        isAdmin: false,
        isOwner: false,
        isPinned: false,
        postDetails: {
            anonymousUser: {
                id: 0,
                name: i18n.t('newsfeed:fakePost.username'),
                icon: 'images/avatars/anonymous/flashlight.svg',
                typeId: 9,
            },
            content: i18n.t('newsfeed:fakePost.content'),
            context: 'group',
            createDate: now.toDateString(),
            editDate: now.toDateString(),
            editDateHuman: i18n.t('newsfeed:fakePost.time'),
            hasBestAnswer: false,
            hasPoll: false,
            id: 0,
            isAnonymous: true,
            isReported: false,
            poll: null,
            upvotes: 41,
            user: null,
            userLiked: false,
            userReport: false,
        },
        totalComments: 0,
        type: 'post',
        university: {
            name: 'Studydrive HQ',
        },
    };
}
