async function isUserCentricsLoaded() {
    return new Promise((resolve) => {
        let i = 0;
        const wait = setInterval(() => {
            if (i === 10) {
                resolve(false);
            }
            // eslint-disable-next-line no-plusplus
            i++;
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                clearInterval(wait);
                resolve(true);
            }
        }, 500);
    });
}

export default async function (serviceName) {
    if (!sdWindow.consentManagement) return true;
    const isUCEnable = isUserCentricsLoaded();
    // eslint-disable-next-line no-return-await,consistent-return
    return await isUCEnable.then((response) => {
        if (response) {
            let serviceStatus = false;
            const services = window.UC_UI.getServicesBaseInfo();
            let service = {};
            services.forEach((element) => {
                if (element.name === serviceName) {
                    service = element;
                }
            });
            if (service.consent) {
                serviceStatus = service.consent.status;
            }
            return serviceStatus;
        }
        return false;
    });
}
