<template>
    <component
        :is="tag"
        :target="target"
        v-bind="attrs"
        @click="onClick"
        @click.middle="onNavigate"
        @click.right="onNavigate"
    >
        <!-- @slot content of the link -->
        <slot />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

/**
 * A link component that can be used to navigate to a different page.
 * Either by using vue-router or a normal anchor tag. The used tag depends on which prop is being provided.
 *
 * Additionally, logic is included to open external links in a new tab and it provides further a11y improvements.
 *
 * Any additional props/attributes will be forwarded to the underlying component.
 */
export default defineComponent({
    name: 'Link',
    inheritAttrs: false,
    props: {
        /**
         * A link to navigate to on click.
         * The component will render as an `<a>` tag.
         * Will be ignored if `to` is defined
         * @example http://www.studydrive.net
         */
        href: {
            type: String,
            default: undefined,
        },
        /**
         * <router-link> prop for in-page navigation.
         * The component will render as an `<router-link>` tag.
         * @example { name: 'newsfeed' }
         */
        to: {
            type: [Object, String] as PropType<RouteLocationRaw>,
            default: undefined,
        },
    },
    emits: ['click', 'navigate'],
    computed: {
        tag(): string {
            if (this.to) return 'router-link';
            return 'a';
        },
        target(): string | undefined {
            return this.isExternalLink ? '_blank' : undefined;
        },
        rel(): string | undefined {
            return this.isExternalLink ? 'noopener noreferrer' : undefined;
        },
        isExternalLink(): boolean {
            if (!this.href) return false;

            const url = new URL(this.href, window.location.origin);
            return url.hostname !== window.location.hostname;
        },
        /**
         * If a link refers to an action on the current page, it behaves like a button.
         */
        role(): string | undefined {
            return this.href?.startsWith('#') ? 'button' : undefined;
        },
        attrs() {
            return {
                ...this.$attrs,
                rel: this.rel,
                role: this.role,
                [this.to ? 'to' : 'href']: this.to ?? this.href,
            };
        },
    },
    methods: {
        onClick(event: PointerEvent) {
            /**
             * Triggered on click
             * @event click
             * @property {PointerEvent} event
             */
            this.$emit('click', event);

            // as this click could also be a possible navigation, we call onNavigate
            this.onNavigate(event);
        },
        onNavigate(event: MouseEvent | PointerEvent) {
            // not a clickable link, so no navigation would occur naturally
            if (!('href' in this.$el)) return;

            /**
             * Triggered when the user will navigate to the specified address via left/middel/right mouse or keyboard action.
             * The action of "Open in new Tab" via the context menu is inaccurate as just the contextmenu event is evaluated for simplicity currently.
             * @todo improve right-click handling
             *
             * @event navigate
             * @property {MouseEvent | PointerEvent} event
             */
            this.$emit('navigate', event);
        },
    },
});
</script>
