import { mapValues } from 'lodash-es';
import dayjs from '@/plugins/dayjs';
import { i18n, getLanguageCode } from '@/plugins/i18n';

/**
 * this is a relative generic date regex and will match dates in the following formats:
 * 2020-01-01 00:00:00
 * 2020-01-01T00:00:00
 * 2020-01-01 00:00:00
 * 2020-01-01T00:00:00.000
 * 2020-01-01 00:00:00.0000
 * 2020-01-01T00:00:00Z
 * 2020-01-01T00:00:00+00:00
 * 2020-01-01 00:00:00-00:00
 * 2020-01-01 00:00:00.000+0000
 * 2020-01-01T00:00:00.00000-0000
 * It is therefore UTC and ISO-8601 compliant, but will not check if the date itself is valid (e.g. 2020-02-30T00:00:00 will be matched)
 */
const dateRegex = /^\d\d\d\d-\d\d-\d\d[T\s]\d\d:\d\d:\d\d(\.\d+)?(Z|[+-]\d\d:?\d\d)?$/;
const dateWithoutTimezoneRegex = /^\d\d\d\d-\d\d-\d\d[T\s]\d\d:\d\d:\d\d(\.\d+)?$/;

/**
 * converts all date strings in the given object to Date objects.
 * The typing is not quite accurate, but really hard to get right, so we just keep the current type of the input.
 * @param val - the value to convert
 * @param adjustServerTime - if true, dates without timezone offset will be assumed to be in the server timezone (berlin time) and adjusted accordingly
 */
export function convertDateTimeStringsToDate<T>(val: unknown, adjustServerTime = false): T {
    if (typeof val === 'string' && val.match(dateRegex)) {
        if (adjustServerTime && val.match(dateWithoutTimezoneRegex)) {
            return dayjs.tz(val, 'Europe/Berlin').toDate() as T;
        }

        return new Date(val) as T;
    }

    if (Array.isArray(val)) {
        return val.map((el) => convertDateTimeStringsToDate(el, adjustServerTime)) as T;
    }

    if (val && val instanceof Object) {
        return mapValues(val, (objVal) => convertDateTimeStringsToDate(objVal, adjustServerTime)) as T;
    }

    return val as T;
}

/**
 * formats a date based on the current user language
 */
export function toLocaleString(date: Date, options?: Intl.DateTimeFormatOptions) {
    return date.toLocaleString(getLanguageCode(), options);
}

/**
 * formats a date in one of the following strings:
 * relative localized date (today or yesterday),
 * name of the weekday (localized)
 * localized date
 */
export function getFormattedDate(d: Date) {
    const date = dayjs(d);
    const dateNow = dayjs();

    if (date.isToday()) {
        return i18n.t('common:today') as string;
    }

    if (date.isBefore(dateNow)) {
        if (date.isYesterday()) {
            return i18n.t('yesterday') as string;
        }

        if (date.isAfter(dateNow.subtract(7, 'days'))) {
            // date is in last 7 days
            return date.format('dddd');
        }
    }

    return toLocaleString(date.toDate(), { dateStyle: 'short' });
}
