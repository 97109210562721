import type { App, Component } from 'vue';
import { createApp } from 'vue';

/**
 * Creates new app, but using the same context as parentApp.
 * based on https://github.com/quasarframework/quasar/blob/dev/ui/src/install-quasar.js
 */
export function createChildApp(parentApp: App, component: Component, props?: Record<string, unknown>) {
    const app = createApp(component, props);

    app.config.globalProperties = parentApp.config.globalProperties;

    // eslint-disable-next-line no-underscore-dangle
    Object.assign(app._context, parentApp._context);

    return app;
}
