import type { CancelTokenSource } from 'axios';
import { assign } from 'lodash-es';

export interface CancelTokenPromise<T> extends Promise<T> {
    cancel: CancelTokenSource['cancel'];
}

/**
 * Assigns Canceler of CancelToken to object
 */
export function assignCancelToken<T extends object>(obj: T, cancelToken: CancelTokenSource) {
    return assign(obj, { cancel: cancelToken.cancel });
}
