import type { DataResponse } from '@/api/types';
import { gateway } from '..';
import type { StudyListItemResource, StudyListItemType } from './resources/StudyListItemResource';

export function addStudylistItem(type: StudyListItemType, id: number) {
    return gateway.post<DataResponse<StudyListItemResource[]>>(`/legacy-api/v1/study-list/${type}/`, { item_id: id });
}

export function deleteStudylistItem(type: StudyListItemType, id: number) {
    return gateway.delete<{
        list_deleted: boolean;
    }>(`/legacy-api/v1/study-list/${type}/${id}`);
}

export function fetchStudyLists() {
    return gateway.get<DataResponse<StudyListItemResource[]>>(`/legacy-api/v1/study-list`);
}

export function toggleStudyListItemStatus(
    item: Pick<StudyListItemResource, 'item_type' | 'item_id' | 'completed_at'>,
    isCompleted: boolean,
) {
    return gateway.patch<{
        is_completed: boolean;
    }>(`/legacy-api/v1/study-list/${item.item_type}/${item.item_id}`, {
        is_completed: isCompleted,
    });
}
