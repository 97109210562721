import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import './styles.scss';

const config = {
    // Disable popper components
    disabled: false,
    // Default position offset along main axis (px)
    distance: 16,
    // Default position offset along cross axis (px)
    skidding: 0,
    // Default container where the tooltip will be appended
    container: 'body',
    // Element used to compute position and size boundaries
    boundary: undefined,
    // Skip delay & CSS transitions when another popper is shown, so that the popper appear to instanly move to the new position.
    instantMove: false,
    // Auto destroy tooltip DOM nodes (ms)
    disposeTimeout: 5000,
    // Triggers on the popper itself
    popperTriggers: [],
    // Positioning strategy
    strategy: 'absolute',
    // Prevent overflow
    preventOverflow: true,
    // Flip to the opposite placement if needed
    flip: true,
    // Shift on the cross axis to prevent the popper from overflowing
    shift: true,
    // Overflow padding (px)
    overflowPadding: 0,
    // Arrow padding (px)
    arrowPadding: 12,
    // Compute arrow overflow (useful to hide it)
    arrowOverflow: true,
    // Themes
    themes: {
        tooltip: {
            // Default tooltip placement relative to target element
            placement: 'top',
            // Default events that trigger the tooltip
            triggers: ['hover', 'focus', 'touch'],
            // Close tooltip on click on tooltip target
            hideTriggers: (events) => [...events, 'click'],
            // Delay (ms)
            delay: {
                show: 200,
                hide: 0,
            },
            // Update popper on content resize
            handleResize: true,
            // Enable HTML content in directive
            html: false,
            // Displayed when tooltip content is loading
            loadingContent: '...',
            // Amount (in px) that the tooltip should be spaced apart from the edge of the screen
            overflowPadding: 8,
        },
        infoTooltip: {
            $extend: 'tooltip',
            delay: { show: 0, hide: 1000 },
            triggers: ['hover', 'focus'],
            html: true,
            popperTriggers: ['hover', 'focus'],
        },
        dropdown: {
            $resetCss: true,
            // Default dropdown placement relative to target element
            placement: 'bottom',
            // Default events that trigger the dropdown
            triggers: ['click'],
            // Delay (ms)
            delay: 0,
            // Update popper on content resize
            handleResize: true,
            // Hide on click outside
            autoHide: true,
        },
        sdMenu: {
            $resetCss: true,
            placement: 'bottom',
            triggers: ['click'],
            popperTriggers: ['click'],
            delay: 0,
            arrowPadding: 0,
            distance: 4,
            handleResize: true,
            autoHide: true,
        },
        floatMenu: {
            $extend: 'sdMenu',
        },
        profileCard: {
            $resetCss: true,
            placement: 'bottom',
            handleResize: true,
            triggers: ['hover', 'focus'],
            popperTriggers: ['hover', 'focus'],
            delay: {
                show: 300,
                hide: 300,
            },
        },
    },
};

export default {
    install(app, options = {}) {
        app.use(FloatingVue, {
            ...config,
            ...options,
        });
    },
};
