import * as Sentry from '@sentry/vue';

/**
 * Intercept 429 Too Many Requests errors to log them in Sentry.
 */
export default {
    handleResponseError(error) {
        if (error.response?.status === 429) {
            Sentry.withScope((scope) => {
                scope.setLevel('info');
                Sentry.captureException(error);
            });
        }

        return Promise.reject(error);
    },
};
