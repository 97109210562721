<template>
    <div :class="['z-20 flex h-full', { 'order-last flex-row-reverse': side === DrawerSide.RIGHT }, containerClass]">
        <transition name="fade">
            <div
                v-if="!noOverlay && withBackdrop && open"
                :class="['bg-black inset-0 opacity-15 pointer-events-auto z-0', position]"
                @click="closeDrawer"
            ></div>
        </transition>
        <div
            v-if="railMode && !noOverlay"
            :style="{ width: railWidth }"
        />
        <transition :name="animation">
            <div
                v-if="open || railMode"
                :class="[
                    'top-0 bottom-0 pointer-events-auto bg-white z-1',
                    position,
                    {
                        'shadow-md': open && !noOverlay,
                        'transition-[width] ease-linear duration-300': largeScreenAnimation,
                    },
                    $attrs.class,
                ]"
                :style="{ width }"
            >
                <slot :open="open"></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue';
import { type EnumToUnion } from '@/types/misc';
import { DrawerSide } from './NavigationDrawer';

/**
 * Navigation drawer to render a sidebar menu on the page. In order for noOverlay mode to work properly,
 * make sure the parent is a flex box and the other elements are unordered.
 */
export default defineComponent({
    name: 'NavigationDrawer',
    inheritAttrs: false,
    props: {
        /**
         * Controls whether the drawer is open or closed.
         */
        open: {
            type: Boolean,
            default: false,
        },
        /**
         * Enables animated expansion transition on large screens.
         */
        largeScreenAnimation: {
            type: Boolean,
            default: false,
        },
        /**
         * Side of the drawer
         * @values left, right
         */
        side: {
            type: String as PropType<EnumToUnion<DrawerSide>>,
            default: DrawerSide.LEFT,
            validator: (val: DrawerSide) => Object.values(DrawerSide).includes(val),
        },
        /**
         * Adds backdrop behind the drawer.
         * Clicking the backdrop will emit the close event,
         * it has no effect if the no-overlay mode is enabled
         */
        withBackdrop: {
            type: Boolean,
            default: false,
        },
        /**
         * Width of the drawer. Accepts CSS values.
         */
        drawerWidth: {
            type: String,
            default: '256px',
        },
        /**
         * container element customization
         */
        containerClass: {
            type: undefined,
            default: undefined,
        },
        /**
         * Whether the drawer should be fixed instead of absolute,
         * does not work when no-overlay is enabled.
         *
         * TODO: Ideally, this prop should not need to exist as the drawer should rather be contained in a fixed element
         * But changing the page layout is a lot more work than to make this drawer fixed.
         */
        fixed: {
            type: Boolean,
            default: false,
        },
        /**
         * Controls whether the drawer is in rail mode.
         */
        railMode: {
            type: Boolean,
            default: false,
        },
        /**
         * Width of the rail. Accepts CSS values.
         */
        railWidth: {
            type: String,
            default: '80px',
        },
        /**
         * Display backdrop overlay or if set to true, expanded drawer
         * will push the content of parent to the side
         */
        noOverlay: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close', 'update:open'],
    data() {
        return {
            DrawerSide,
        };
    },
    computed: {
        animation(): string {
            return this.side === DrawerSide.RIGHT ? 'slide-right' : 'slide-left';
        },
        width(): string {
            return (this.railMode && !this.open ? this.railWidth : this.drawerWidth) as string;
        },
        position() {
            if (this.noOverlay) return '';

            return this.fixed ? 'fixed' : 'absolute';
        },
    },
    methods: {
        closeDrawer() {
            /**
             * closes the drawer
             */
            this.$emit('close', false);
            /**
             * updates open prop
             */
            this.$emit('update:open', false);
        },
    },
});
</script>

<style lang="scss" scoped>
.slide-left-enter-active {
    animation: slide-in-left 200ms ease-in-out;
}

.slide-left-leave-to {
    animation: slide-out-left 200ms ease-in-out;
}

.slide-right-enter-active {
    animation: slide-in-right 200ms ease-in-out;
}

.slide-right-leave-to {
    animation: slide-out-right 200ms ease-in-out;
}
</style>
