import { gateway } from '..';
import type { DocumentSummaryResource } from './resources/DocumentSummaryResource';

const apiPath = 'alchemist/api/v1';

export function getDocumentSummary(id: number) {
    return gateway.get<{ summary: DocumentSummaryResource[] }>(`${apiPath}/documents/${id}/summary`);
}

export function generateDocumentSummary(
    document_id: number,
    document_file_name: string,
    document_description: string,
    number_of_pages: number,
) {
    return gateway.post(`${apiPath}/documents/summary`, {
        document_id,
        document_file_name,
        document_description,
        number_of_pages,
    });
}
