import ClickOutsideDirective from './click-outside';
import DynamicHeightDirective from './dynamic-height';
import DragScrollDirective from './drag-scroll';
import HorizontalScrollDirective from './horizontal-scroll';
import TrackingDirective from './tracking';
import InputErrorDirective from './input-error';
import ParallaxDirective from './parallax';
import MutationDirective from './mutation';
import ResizeDirective from './resize';

export default {
    'click-outside': ClickOutsideDirective,
    'dynamic-height': DynamicHeightDirective,
    'drag-scroll': DragScrollDirective,
    'horizontal-scroll': HorizontalScrollDirective,
    tracking: TrackingDirective,
    'input-error': InputErrorDirective,
    parallax: ParallaxDirective,
    mutation: MutationDirective,
    resize: ResizeDirective,
};
