/**
 * Will request a lock and excute `fnc` or wait till the lock is available again, but does not execute `fnc`.
 * It utilizes the web locks api.
 */
export function requestLockOrWait<T>(key: string, fnc: () => Promise<T>) {
    return navigator.locks.request(key, { ifAvailable: true }, (lock) => {
        if (!lock) {
            // Another process is already holding the lock, so we wait until it is released.
            return navigator.locks.request(key, async () => undefined);
        }

        return fnc();
    }) as Promise<T | undefined>;
}
