import route from '@router/route';
import { backend } from '.';

export function getDocument(id, slug) {
    return backend.get(route('document.preview', { id, slug }));
}

export function getDiscussion(id, page, postId = null) {
    return backend.get(route('document.post.index', { id, page, postId }));
}

export function retrieveMarkings(id, page) {
    return backend.get(route('document.page.marking.index', { id, page })).then((response) => response.data);
}

export function toggleFollowDocument(id) {
    return backend.post(route('document.follow.toggle', { id }));
}

export function deleteDocument(id, type, comment) {
    return backend.delete(route('document.destroy', { id, type, comment }));
}

export function downloadTimed(id) {
    return backend.post(route('document.download.timed', { id }));
}

export function updateDocument(id, payload) {
    return backend.put(route('document.update', { id }), payload);
}

export function reportDocument(id, reasonId, additionalInfo) {
    return backend.post(route('document.report', { id }), {
        reason: reasonId,
        additional: additionalInfo,
    });
}

export function downloadDocument(id, fileName, recaptchaToken, getPdf) {
    return backend({
        url: route('document.download.manual', { id }),
        method: 'POST',
        responseType: 'blob',
        data: {
            recaptcha_token: recaptchaToken,
            get_pdf: getPdf,
        },
    }).then((response) => {
        const blob = new Blob([response.data], {
            type: response.data.type,
        });
        let documentName = fileName;
        if (sdWindow.document.has_pdf && getPdf) {
            documentName += '.pdf';
        }
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = documentName;
        link.click();
        URL.revokeObjectURL(link.href);

        return response;
    });
}

export function getTotalDownloads(id) {
    return backend.get(route('document.download.count', { id }));
}

export function getTopDocumentsByMajor(id) {
    return backend.get(route('document.top.major', { id }));
}
