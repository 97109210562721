import { getHash, getHashAnon } from '@/api/backend/upload';

const types = {
    HASH: 'HASH',
    HASH_ANON: 'HASH_ANON',
};

const state = {
    hash: null,
    hashAnon: null,
};

const mutations = {
    [types.HASH](state, value) {
        state.hash = value;
    },
    [types.HASH_ANON](state, value) {
        state.hashAnon = value;
    },
};

const actions = {
    retrieveHash({ commit }) {
        return getHash().then((response) => commit('HASH', response.data));
    },
    retrieveHashAnon({ commit }) {
        return getHashAnon().then((response) => commit('HASH_ANON', response.data));
    },
};

const getters = {
    hash: (state) => state.hash,
    hashAnon: (state) => state.hashAnon,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
