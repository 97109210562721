import { createRouter, createWebHistory } from 'vue-router';
import xandr from '@/modules/xandr';
import routes from './routes';
import { langGuard, pageStatusGuard } from './guards';

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(langGuard);
router.beforeEach(pageStatusGuard);

// inject xandr adlib
// TODO: as not every route change refreshes the page,
// this call will create new duplicated event listeners.
// We need to clean up these events or handle the events differently
router.afterEach(xandr);

// setting the router to use in vuex store files
window.router = router;
export default router;
