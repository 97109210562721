import closest from '@/helpers/closest';

const protectedAuthClasses = ['protected-auth'];

const exclusionProtectedAuthClasses = ['exclude-auth'];

const protectedVerifiedClasses = ['protected-verified'];

const exclusionProtectedVerifiedClasses = ['exclude-verified'];

function isProtected(el, protectedClasses, excludedClasses) {
    for (let i = 0; i < excludedClasses.length; i += 1) {
        if (el.classList.contains(excludedClasses[i]) || closest(el, `.${excludedClasses[i]}`)) {
            return false;
        }
    }

    for (let i = 0; i < protectedClasses.length; i += 1) {
        if (el.classList.contains(protectedClasses[i]) || closest(el, `.${protectedClasses[i]}`)) {
            return true;
        }
    }
    return false;
}

// eslint-disable-next-line import/prefer-default-export
export const clickEvent = (e, store, i18n) => {
    if (e.target && e.target.classList) {
        const isLoggedIn = store.getters['auth/isLoggedIn'];
        const isVerified = store.getters['auth/isVerified'];
        if (isLoggedIn) {
            if (!isVerified && isProtected(e.target, protectedVerifiedClasses, exclusionProtectedVerifiedClasses)) {
                e.preventDefault();
                e.stopPropagation();
                if (e.target.classList && e.target.classList.contains('unblur-it')) {
                    e.target.blur();
                }
                store.dispatch('auth/showEmailVerificationPopup', {
                    store,
                    i18n,
                    closeable: true,
                });
            }
        } else if (isProtected(e.target, protectedAuthClasses, exclusionProtectedAuthClasses)) {
            e.preventDefault();
            e.stopPropagation();

            // if we need to know exactly where the auth popup was triggered. E.g. redirection or specific treatments for TheAuthOverlay
            const authTriggerElem = closest(e.target, `[data-specific-auth-trigger]`);
            let authTrigger = null;
            if (authTriggerElem) {
                authTrigger = authTriggerElem.getAttribute('data-specific-auth-trigger');
            }
            store.dispatch('ui/openRegistration', {
                authTrigger,
            });
        }
    }
};
