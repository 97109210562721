import { defineAsyncComponent } from 'vue';

const Xandr = defineAsyncComponent(() => import('./Xandr.vue'));
const XandrStickyAd = defineAsyncComponent(() => import('./XandrStickyAd.vue'));

const XandrFullScreenAdWrapper = defineAsyncComponent(() => import('./XandrFullScreenAdWrapper.vue'));

const XandrSponsoredHeader = defineAsyncComponent(() => import('./XandrSponsoredHeader.vue'));

export { Xandr, XandrStickyAd, XandrFullScreenAdWrapper, XandrSponsoredHeader };
