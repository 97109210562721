{
    "minify": true,
    "enableClasses": true,
    "options": [
        "setClasses"
    ],
    "feature-detects": [
        "test/img/webp"
    ]
}
