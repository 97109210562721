import { findLinkInEvent } from '../helpers/event';
import { scrollPageToElementWithId } from '../helpers/window';

let scrolledPos = null;

async function evaluateHash(hash) {
    const id = hash.split('#')[1];
    if (!id) return;

    scrolledPos = await scrollPageToElementWithId(id);
}

/**
 * hash change listener.
 * As the hash in the url can be changed manually, we need to consider that too.
 * This would natively result in a scroll to the target element which we cannot prevent.
 * Thus, we add another scroll to scroll to the intended position defiend by 'evaluateHash'.
 */
function onHashChange() {
    if (!scrolledPos) return;

    window.scrollTo({
        behavior: 'smooth',
        ...scrolledPos,
    });
    scrolledPos = null;
}

/**
 * Checks click on hash links before any other handler, marks them and prevents default action.
 * @param {PointerEvent} event
 */
function onCaptureClick(event) {
    const link = findLinkInEvent(event);
    const href = link?.getAttribute('href');

    if (!href || !href.startsWith('#')) return;

    event.hashScrollTo = href; //eslint-disable-line
    event.preventDefault();
}

/**
 * This might be the last handler in the event chain and will toggle the hash scroll if the capture handler marked the event and it wasn't cancelled
 * @param {PointerEvent} event
 */
function onBubbleClick(event) {
    if (event.cancelBubble || !event.hashScrollTo) return;

    window.history.replaceState(window.history.state, null, event.hashScrollTo);
    evaluateHash(event.hashScrollTo);
}

/**
 * This mixin overrides the browser default scrolling mechanics on url hash changes to facilitate scroll offsets due to fixed elements.
 *
 * Note: The above functions aren't added on purpose to the mixin to prevent accidental overrides
 */
export default {
    created() {
        window.addEventListener('hashchange', onHashChange);
        window.addEventListener('click', onCaptureClick, true);
        window.addEventListener('click', onBubbleClick);
        window.addEventListener('load', this.onLoadEvaluateRouteHash);
    },
    beforeUnmount() {
        window.removeEventListener('hashchange', onHashChange);
        window.removeEventListener('click', onCaptureClick, true);
        window.removeEventListener('click', onBubbleClick);
        window.removeEventListener('load', this.onLoadEvaluateRouteHash);
    },
    watch: {
        $route(route) {
            evaluateHash(route.hash);
        },
    },
    methods: {
        onLoadEvaluateRouteHash() {
            evaluateHash(this.$route.hash);
        },
    },
};
