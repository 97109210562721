const CONFIG = {
    clientId: window.sdWindow.appleClientId,
    scope: 'name email',
    redirectURI: `${window.location.origin}/social-auth/handle/apple`,
    // response_type: 'code id_token',
    // response_mode: 'form_post',
    state: 'studydrive_state',
    usePopup: true,
};

/**
 * Add apple script
 * @returns
 */
function addScript(locale) {
    const el = document.getElementById('apple-sign-in-script');
    if (el) return;

    const lang = locale.slice(0, 2) === 'de' ? 'de_DE' : 'en_US';

    const appleScript = document.createElement('script');
    appleScript.setAttribute(
        'src',
        `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${lang}/appleid.auth.js`,
    );
    appleScript.setAttribute('id', 'apple-sign-in-script');
    document.head.appendChild(appleScript);
}

/**
 * Initializes apple script and resolves promise when finished
 * @returns {Promise}
 */
function initScript() {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!window.AppleID?.auth) return;

            window.AppleID.auth.init(CONFIG);

            clearInterval(interval);
            resolve();
        }, 50);
    });
}

/**
 * Initilizes apple authentication
 * @param {String} locale
 * @returns {Promise}
 */
export function initAuth(locale) {
    if (!CONFIG.clientId) throw new Error('Apple login not configured');

    addScript(locale);
    return initScript();
}
