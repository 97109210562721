<template>
    <Btn
        v-bind="$attrs"
        :variant="variant"
        color="primary"
        right-icon="arrow-right"
        content-spacing="apart"
        class="btn-link"
    >
        <div class="text-left">
            <slot name="label">{{ label }}</slot>
            <div
                v-if="hasSubLabelOrSlot"
                class="sub-label"
            >
                <slot name="sub-label">{{ subLabel }}</slot>
            </div>
        </div>
    </Btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type PropType } from 'vue';
import Btn from '@components/Btn';
import { slotIsUsed } from '@helpers/dom';
import { BtnLinkVariant } from './BtnLink';

/**
 * This button utilizes our `Btn` component and configures it to fit our button link appearance.
 * It supports the same props as `Btn`, but additionally also provides a sub label.
 */
export default defineComponent({
    name: 'BtnLink',
    components: {
        Btn,
    },
    inheritAttrs: false,
    props: {
        /**
         * The variant of the link button.
         * @values filled, outlined, tonal
         */
        variant: {
            type: String as PropType<BtnLinkVariant>,
            default: BtnLinkVariant.TONAL,
            validator: (val: BtnLinkVariant) => Object.values(BtnLinkVariant).includes(val),
        },
        /**
         * The button label
         */
        label: {
            type: String,
            default: null,
        },
        /**
         * The button sub label
         */
        subLabel: {
            type: String,
            default: null,
        },
    },
    computed: {
        hasSubLabelOrSlot(): boolean {
            return !!this.subLabel || slotIsUsed(this.$slots.subLabel);
        },
    },
});
</script>

<style lang="scss" scoped>
.sub-label {
    @apply font-normal truncate mt-1;
}

.btn-link:not(.disabled):not(.variant-filled) {
    .sub-label {
        @apply text-grey-600;
    }
}
</style>
