const types = {
    OPEN_CREATE_POPUP: 'OPEN_CREATE_POPUP',
    CLOSE_CREATE_POPUP: 'CLOSE_CREATE_POPUP',
    OPEN_EDIT_POPUP: 'OPEN_EDIT_POPUP',
    CLOSE_EDIT_POPUP: 'CLOSE_EDIT_POPUP',
};

const state = {
    createPopupIsOpen: false,
    editPopupIsOpen: {
        byId: {},
    },
    isAnonymous: false,
};

const mutations = {
    [types.OPEN_CREATE_POPUP](state) {
        state.createPopupIsOpen = true;
    },

    [types.CLOSE_CREATE_POPUP](state) {
        state.createPopupIsOpen = false;
    },

    [types.OPEN_EDIT_POPUP](state, payload) {
        state.editPopupIsOpen.byId[payload] = true;
    },

    [types.CLOSE_EDIT_POPUP](state, payload) {
        state.editPopupIsOpen.byId[payload] = false;
    },
};

const getters = {
    createPopupIsOpen: (state) => state.createPopupIsOpen,
    editPopupIsOpen: (state) => (id) => state.editPopupIsOpen.byId[id] ?? false,
};

const actions = {
    openCreatePopup({ commit }) {
        commit(types.OPEN_CREATE_POPUP);
    },
    closeCreatePopup({ commit }) {
        commit(types.CLOSE_CREATE_POPUP);
    },
    openEditPopup({ commit }, id) {
        commit(types.OPEN_EDIT_POPUP, id);
    },
    closeEditPopup({ commit }, id) {
        commit(types.CLOSE_EDIT_POPUP, id);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
