<template>
    <div :class="['spinner', size, color, appearance]">
        <!-- original: /images/spinners/circular.svg -->
        <svg
            v-if="appearance === 'circular'"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 128 128"
            stroke="#000"
            stroke-width="4"
            fill="none"
        >
            <g>
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="1"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.67"
                    transform="rotate(45,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.42"
                    transform="rotate(90,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.2"
                    transform="rotate(135,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.12"
                    transform="rotate(180,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.12"
                    transform="rotate(225,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.12"
                    transform="rotate(270,64,64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12"
                    stroke-opacity="0.12"
                    transform="rotate(315,64,64)"
                />
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
                    calcMode="discrete"
                    dur="1040ms"
                    repeatCount="indefinite"
                ></animateTransform>
            </g>
        </svg>

        <svg
            v-if="appearance === 'dots'"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
            stroke-width="1"
        >
            <g
                stroke-width="2"
                transform="translate(0, 0)"
            >
                <g stroke-width="2">
                    <circle
                        fill="none"
                        stroke="#000000"
                        stroke-width="1"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        cx="6"
                        cy="25"
                        r="4"
                        stroke-linejoin="miter"
                    ></circle>
                    <circle
                        fill="none"
                        stroke="#000000"
                        stroke-width="1"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        cx="24"
                        cy="25"
                        r="4"
                        stroke-linejoin="miter"
                    ></circle>
                    <circle
                        fill="none"
                        stroke="#000000"
                        stroke-width="1"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        cx="42"
                        cy="25"
                        r="4"
                        stroke-linejoin="miter"
                    ></circle>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export const COLOR = {
    BLACK: 'black',
    BLUE: 'blue',
    WHITE: 'white',
    NONE: 'none',
};

export const COLORS = Object.values(COLOR);

export default {
    name: 'Spinner',
    props: {
        /**
         *  "circular" | "dots"
         */
        appearance: {
            type: String,
            required: false,
            default: 'circular',
            validator(value) {
                // The value must match one of these strings
                return ['circular', 'dots'].indexOf(value) !== -1;
            },
        },

        /**
         *  Adjust the spinner size
         */
        size: {
            type: String,
            required: false,
            default: 'md',
            validator(value) {
                // The value must match one of these strings
                return (
                    [
                        'xxs',
                        'xs',
                        'md',
                        'lg',
                        'xl',
                    ].indexOf(value) !== -1
                );
            },
        },

        /**
         *  Adjust the spinner color
         */
        color: {
            type: String,
            required: false,
            default: 'black',
            validator: (val) => COLORS.includes(val),
        },
    },
};
</script>
