import type { Ref } from 'vue';
import { ref } from 'vue';

const width: Ref<number> = ref(window.innerWidth);
const height: Ref<number> = ref(window.innerHeight);

const updateScreenSize = () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
};

window.addEventListener('resize', updateScreenSize);

/**
 * Returns the current window width and height as reactive values.
 *
 * @return {object} An object containing the current window width and height.
 */
export function useWindowSize() {
    return { width, height };
}
