<template>
    <div
        :class="[
            'relative flex flex-row justify-between items-center w-full',
            'bg-white-100 rounded-lg border-2 font-medium p-3',
            'alert',
            containerClass,
        ]"
    >
        <span class="align-middle">
            <slot></slot>
        </span>
        <Btn
            class="ml-2"
            :size="BtnSize.SM"
            :label="$t('common:ok')"
            :color="btnColor"
            @click.prevent.stop="$emit('button-click')"
        />
        <div
            v-if="progress.showProgress"
            class="time-track-container absolute w-full h-full px-1"
        >
            <div
                ref="progressTrack"
                class="time-track w-full h-full bg-black-200 rounded-lg"
            >
                <div
                    ref="progressBar"
                    :class="['time-progress h-full relative rounded-lg transition-all', progressClass]"
                ></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { BtnSize } from '@components/Btn';
import { ContainerClass, ProgressClass, BtnColor } from './Alert';

export default defineComponent({
    name: 'Alert',
    props: {
        /**
         * Controls the color of the Alert.
         */
        appearance: {
            type: String,
            default: 'default',
            validator(value: string) {
                return ['success', 'error', 'default'].includes(value);
            },
        },
        /**
         * Controls the Alert's disappearance.
         */
        progress: {
            type: Object as PropType<{ showProgress: boolean; time: number }>,
            required: false,
            default: () => ({
                time: 0,
                showProgress: false,
            }),
        },
    },
    emits: ['auto-close', 'button-click'],
    data() {
        return {
            BtnSize,
        };
    },
    computed: {
        containerClass(): string {
            return ContainerClass[this.appearance.toUpperCase() as keyof typeof ContainerClass];
        },
        btnColor(): string {
            return BtnColor[this.appearance.toUpperCase() as keyof typeof BtnColor];
        },
        progressClass(): string {
            return ProgressClass[this.appearance.toUpperCase() as keyof typeof ProgressClass];
        },
    },
    mounted() {
        if (this.progress && this.progress.time > 0) {
            this.animateProgress(0);
        }
    },
    methods: {
        animateProgress(timeleft: number) {
            if (this.progress.time > timeleft) {
                if (this.progress.showProgress) {
                    const progressBarWidth =
                        (timeleft * (this.$refs.progressTrack as HTMLElement).clientWidth) / this.progress.time;
                    (this.$refs.progressBar as HTMLElement).style.width = `${progressBarWidth}px`;
                }
                setTimeout(() => {
                    this.animateProgress(timeleft + 10);
                }, 10);
            } else {
                this.$emit('auto-close');
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.alert {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1), 0 6px 24px 0 rgba(0, 0, 0, 0.05);
    .time-track-container {
        height: 3px;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
</style>
