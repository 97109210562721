function prepareAttrsToNewTag(name, element, attrsFound) {
    const attrs = [];
    let value = '';
    attrsFound.forEach((attrFound) => {
        const attr = {
            key: attrFound[1],
            value: attrFound[2],
        };
        attrs.push(attr);

        if (attrFound[0].includes(element.mainAttr)) value = attr.value;
    });
    attrs.push({
        key: 'data-click',
        value: `${name},${value}`,
    });
    return attrs;
}

function createTag(tagData) {
    let tag = `<${tagData.tag}`;

    tagData.attrs.forEach((attr) => {
        tag += ` ${attr.key}="${attr.value}" `;
    });

    return `${tag}>`;
}

function replaceClickableTagsInTheText(text, oldTag, newTag) {
    return text.replace(oldTag, newTag);
}

function parseHtmlToTrackClicks(name, text) {
    let content = text;

    const tagsToTrack = [
        {
            tag: 'a',
            mainAttr: 'href',
        },
        {
            tag: 'img',
            mainAttr: 'src',
        },
        {
            tag: 'iframe',
            mainAttr: 'src',
        },
    ];

    tagsToTrack.forEach((element) => {
        const regExpToFindTag = new RegExp(`<${element.tag} [^>]*"[^>]*>`, 'gi');
        const tagsFound = [...content.matchAll(regExpToFindTag)];

        tagsFound.forEach((tagFound) => {
            const regExpToFindAttrs = /(\S+)\s*=\s*["']([^"']*)["']/gi;
            const attrsFound = [...tagFound[0].matchAll(regExpToFindAttrs)];
            const attrsToReplace = prepareAttrsToNewTag(name, element, attrsFound);

            const tagData = {
                tag: element.tag,
                attrs: attrsToReplace,
            };

            const newTag = createTag(tagData);

            content = replaceClickableTagsInTheText(content, tagFound[0], newTag);
        });
    });

    return content;
}

export default {
    beforeMount(el, binding) {
        if (!binding.value) return;

        if (typeof binding.value !== 'object') return;

        const name = binding.value.name ? binding.value.name : '';
        const value = binding.value.value ? binding.value.value : '';

        el.innerHTML = parseHtmlToTrackClicks(name, value);
    },
};
