import { asObject } from '@helpers/object';
import { childOfIdOrClass } from '@helpers/dom';

export const DEFAULT_CONFIG = {
    /** triggered when clicked outside of assigned element */
    callback: () => {},
    /** list of ids/classes of elements for which the callback is not being triggered inside */
    whitelist: [],
    /** dirctive listens for clicks when true */
    when: true,
};

/**
 * Handles check if the click is not inside the event dispatcher and attaches the send function to element
 * @param {HTMLElement} el
 * @param {Object} config
 */
function getHandler(el, config) {
    return (e) => {
        if (el.contains(e.target) || config.whitelist.some((name) => childOfIdOrClass(e.target, name))) {
            // element is inside target or whitelisted element
            return;
        }

        config.callback(e);
    };
}

/**
 * @param {HTMLElement} el
 */
function reset(el) {
    const handler = el.clickOutsideDirective?.handler;

    if (handler) {
        document.documentElement.removeEventListener('click', handler, { capture: true });
        document.documentElement.removeEventListener('contextmenu', handler, { capture: true });
        el.clickOutsideDirective.handler = undefined;
    }
}

/**
 * @param {HTMLElement} el
 * @param {Object} binding
 */
function configure(el, binding) {
    reset(el);

    if (!binding.value) return;

    const config = {
        ...DEFAULT_CONFIG,
        ...asObject(binding.value, 'callback'),
    };

    if (!config.when || !config.callback) {
        return;
    }

    const handler = getHandler(el, config);

    // stores handler and callback with the element.
    el.clickOutsideDirective = {
        handler,
    };
    document.documentElement.addEventListener('click', handler, true);
    document.documentElement.addEventListener('contextmenu', handler, true);
}

/**
 * Only assign a value to this directive if a callback is REALLY desired,
 * otherwise it would trigger FOR ALL clicks on a page, reducing performance.
 */
export default {
    beforeMount: configure,
    updated: configure,
    unmounted: reset,
};
