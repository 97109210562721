import { gateway } from '..';

const USER_SERVICE_GATEWAY = 'users/v1';
const BLOCKED_USERS_API = `${USER_SERVICE_GATEWAY}/blocks`;

export function fetchBlockedUsers() {
    return gateway.get(BLOCKED_USERS_API);
}

export function storeBlockedUser(userId: number) {
    return gateway.put(`${BLOCKED_USERS_API}/${userId}`);
}

export function deleteBlockedUser(userId: number) {
    return gateway.delete(`${BLOCKED_USERS_API}/${userId}`);
}
