import type { Slot, VNode } from 'vue';
import { Text, Comment, nextTick } from 'vue';
import { prefersReducedMotion } from './browser';
/*
 * This file is all about DOM & template stuff
 */

/**
 * Whether an element contains overflowing content
 */
export function isOverflown(element: HTMLElement) {
    const styles = window.getComputedStyle(element);
    const hBorderWidth = parseInt(styles.borderTopWidth, 10) + parseInt(styles.borderBottomWidth, 10);
    const vBorderWidth = parseInt(styles.borderLeftWidth, 10) + parseInt(styles.borderRightWidth, 10);

    if (styles.boxSizing === 'content-box') {
        return (
            element.scrollHeight > element.clientHeight + hBorderWidth ||
            element.scrollWidth > element.clientWidth + vBorderWidth
        );
    }
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

/**
 * Whether element rect is in viewport.
 */
export function isRectInViewport(rect: DOMRect) {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
}

/**
 * Whether element is in viewport.
 * Does not mean it is visible!
 */
export function isInViewport(element: HTMLElement) {
    return isRectInViewport(element.getBoundingClientRect());
}

/**
 * Checks if slot reference has content
 * Discussion: https://github.com/vuejs/core/issues/4733#issuecomment-1024816095
 */
export function slotIsUsed(slot: Slot | undefined, slotProps = {}): boolean {
    if (!slot) return false;

    return slot(slotProps).some((vnode: VNode) => {
        if (vnode.type === Comment) return false;

        if (Array.isArray(vnode.children) && !vnode.children.length) return false;

        return vnode.type !== Text || (typeof vnode.children === 'string' && vnode.children.trim() !== '');
    });
}

/**
 * Checks if element is child of any element having `name` as id or class
 * or having those itself.
 */
export function childOfIdOrClass(elem: HTMLElement | Document | null, name: string): boolean {
    if (!elem || elem instanceof Document) {
        // finished looking up parents
        return false;
    }

    if (elem.id === name || elem.classList.contains(name)) {
        return true;
    }

    return childOfIdOrClass(elem.parentElement, name);
}

/**
 * Builds an array of parent elements from current to window
 */
export function getParentPath(elem: Element) {
    const path = [];
    for (let el: Element | null = elem; el; el = el.parentElement) {
        path.push(el);
    }

    path.push(window);
    return path;
}

/**
 * Tries to get element by id for a specific number of vue ticks
 */
export function tryToGetElementById(id: string, ticks = 3) {
    async function tryGet(run: number): Promise<HTMLElement | null> {
        const el = document.getElementById(id);

        if (el || run <= 1) return el;

        await nextTick();
        return tryGet(run - 1);
    }

    return tryGet(ticks);
}

/**
 * Calculates the offset of an element to the top of a page.
 * @param {HTMLElement} el
 * @returns {Number}
 */
export function getOffsetTop(el: HTMLElement | null): number {
    return el ? getOffsetTop(el.offsetParent as HTMLElement) + el.offsetTop : 0;
}

/**
 * Provides backwards compatible scrolling on elements.
 * By default and if the user prefers it, smooth scrolling will be used (can be overwritten) (no all browsers support this)
 * @returns Promise that resolves once the scroll is completed
 */
export function smoothScroll(
    element: HTMLElement | Window,
    { behavior = 'smooth', ...position }: ScrollToOptions,
): Promise<void> {
    const scrollPos = {
        // window has no scrollTop property but scrollY instead
        top: position.top || (element as HTMLElement).scrollTop || (element as Window).scrollY,
        left: position.left || (element as HTMLElement).scrollLeft || (element as Window).scrollX,
    };

    const isScrollFinished = () =>
        ((element as HTMLElement).scrollTop || (element as Window).scrollY) === scrollPos.top &&
        ((element as HTMLElement).scrollLeft || (element as Window).scrollX) === scrollPos.left;

    if (isScrollFinished()) {
        // no need to scroll
        return Promise.resolve();
    }

    if (!('scroll' in element)) {
        // fallback if Element.scroll is not supported
        /* eslint-disable no-param-reassign */
        (element as HTMLElement).scrollTop = scrollPos.top;
        (element as HTMLElement).scrollLeft = scrollPos.left;
        /* eslint-enable no-param-reassign */
        return Promise.resolve();
    }

    if (prefersReducedMotion()) {
        // no smooth scrolling preferred
        element.scroll(scrollPos);
        return Promise.resolve();
    }

    // As smooth scrolling isn't instant, we need to observe it via the scroll event handler
    // in order to provide a feedback
    return new Promise((resolve) => {
        const scrollHandler = () => {
            if (isScrollFinished()) {
                element.removeEventListener('scroll', scrollHandler);
                resolve();
            }
        };

        element.addEventListener('scroll', scrollHandler);
        element.scroll({
            ...scrollPos,
            behavior,
        });

        // after 1s we consider the scroll to be successfull to not break the application
        setTimeout(() => {
            element.removeEventListener('scroll', scrollHandler);
            resolve();
        }, 1000);
    });
}

/**
 * Similar to smoothScroll()
 */
export function smoothScrollTop(element: HTMLElement | Window, top = 0) {
    return smoothScroll(element, { top });
}

/**
 * Checks if an element is scrollable in the given direction (-+deltaX, +-deltaY).
 * If both deltas are undefined, it checks whether the element is generally scrollable.
 */
export function isScrollable(element: HTMLElement, deltaX?: number, deltaY?: number) {
    // Check if the element can be scrolled horizontally
    const canScrollHorizontally = element.scrollWidth > element.clientWidth;
    const canScrollVertically = element.scrollHeight > element.clientHeight;

    if (!canScrollHorizontally && !canScrollVertically) {
        return false;
    }

    if (deltaX === undefined || deltaY === undefined) {
        return true;
    }

    // Check if the element can be scrolled further in the direction of the delta
    if (
        (deltaX > 0 && element.scrollLeft + element.clientWidth < element.scrollWidth) ||
        (deltaX < 0 && element.scrollLeft > 0)
    ) {
        return true;
    }

    if (
        (deltaY > 0 && element.scrollTop + element.clientHeight < element.scrollHeight) ||
        (deltaY < 0 && element.scrollTop > 0)
    ) {
        return true;
    }

    return false;
}

export function onElementVisible(element: HTMLElement, func: CallableFunction) {
    const observer = new IntersectionObserver(
        (entries) => {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            if (entries[0].isIntersecting) {
                // Element has just become visible in screen
                func();
            }
        },
        { threshold: [0] },
    );
    observer.observe(element);
}
