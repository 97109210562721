import type { Interceptor } from '@/api/utils/interceptors/types';
import { convertDateTimeStringsToDate } from '@helpers/date';

/**
 * This interceptor converts all datetime strings to Date objects
 * so you don't need to worry about that in the frontend anymore.
 */
export default {
    handleResponse(response) {
        // eslint-disable-next-line no-param-reassign
        response.data = convertDateTimeStringsToDate(response.data, true);
        return response;
    },
} as Interceptor;
