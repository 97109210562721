import { computed } from 'vue';
import { BREAKPOINT, getScreenForWidth, SCREEN } from '@/utils/uiUtils';
import { useWindowSize } from './windowSize';

const { width } = useWindowSize();
const current = computed(() => getScreenForWidth(width.value));

const breakpoints = {
    current,

    minXS: true,
    isXS: computed(() => current.value === SCREEN.XS),
    maxXS: computed(() => width.value < BREAKPOINT.SM),

    minSM: computed(() => width.value >= BREAKPOINT.SM),
    isSM: computed(() => current.value === SCREEN.SM),
    maxSM: computed(() => width.value < BREAKPOINT.MD),

    minMD: computed(() => width.value >= BREAKPOINT.MD),
    isMD: computed(() => current.value === SCREEN.MD),
    maxMD: computed(() => width.value < BREAKPOINT.LG),

    minLG: computed(() => width.value >= BREAKPOINT.LG),
    isLG: computed(() => current.value === SCREEN.LG),
    maxLG: computed(() => width.value < BREAKPOINT.XL),

    minXL: computed(() => width.value >= BREAKPOINT.XL),
    isXL: computed(() => current.value === SCREEN.XL),
    maxXL: true,
};

/**
 * Returns the reactive breakpoints object representing the current screen size.
 * this can be used in any vue component with setup method as follows:
 *
 * setup() {
 *    const breakpoints = useScreenSize();
 *    const { minLG } = useScreenSize();
 *
 *    return {
 *       breakpoints,
 *       minLG
 *    };
 * },
 *
 * @return {object} The breakpoints object.
 */
export function useScreenSize() {
    return breakpoints;
}
