import { Modernizr } from '@/plugins/modernizr';

export const isFirefox = window.navigator.userAgent.includes('Firefox');

export const hasWebSupport = new Promise<{ lossless: boolean; alpha: boolean; animation: boolean } | false>(
    (resolve) => {
        Modernizr.on('webp', resolve);
    },
);

export const hasAvifSupport = new Promise<boolean>((resolve) => {
    Modernizr.on('avif', resolve);
});

export const prefersReducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

/* we also got a vuex getter ui/prefersReducedMotion */
export function prefersReducedMotion() {
    return prefersReducedMotionQuery.matches;
}
