import { enable, disable, enableAll, disableAll } from '@/api/backend/settings';

const actions = {
    async enable(_, type) {
        return enable(type);
    },
    async disable(_, type) {
        return disable(type);
    },
    async enableAll() {
        return enableAll();
    },
    async disableAll() {
        return disableAll();
    },
};

export default {
    namespaced: true,
    actions,
};
