<template>
    <component
        :is="isSheet ? 'BottomSheet' : 'div'"
        :class="['flex flex-col', isSheet ? 'w-full' : 'modal relative bg-white-100 rounded-lg m-4']"
        :data-testid="testId"
    >
        <div class="media overflow-hidden">
            <!-- @slot section above the header to put image or video content -->
            <slot name="media"></slot>
        </div>
        <div class="px-8 py-4 md:p-6">
            <div
                v-if="shouldShowHeader"
                :class="['flex justify-center items-center mb-5', { 'pr-11': withCloseAction }]"
            >
                <h3
                    v-if="shouldShowTitle"
                    :class="['flex-1', isSheet ? 'font-body-1-medium' : 'font-subtitle-2', titleClass]"
                >
                    <!-- @slot custom title slot -->
                    <slot name="title">{{ title }}</slot>
                </h3>
                <div>
                    <!-- @slot slot right of the title for additional actions-->
                    <slot name="header-actions" />
                </div>
            </div>

            <div
                v-if="shouldShowContent"
                class="modal-content text-black-600 leading-normal"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="contentHtml"
                    v-html="contentHtml"
                />
                <!-- @slot through the default or content slot you can provide custom modal content -->
                <slot v-else>
                    <!-- @slot same as default -->
                    <slot name="content">{{ text }}</slot>
                </slot>
            </div>

            <div
                v-if="!noActions"
                :class="[
                    'actions flex flex-col mt-9 md:mt-7 space-y-4',
                    centerButtons ? 'justify-center' : 'md:justify-end',
                    stackButtons ? 'justify-stretch' : 'md:flex-row md:space-y-0 md:space-x-4',
                ]"
            >
                <!-- @slot through the actions slot you can override all modal actions -->
                <slot name="actions">
                    <!-- @slot Slot for a custom cancel button. The cancel props will have no affect. -->
                    <slot name="cancel-action">
                        <!-- native modifier has been removed, please confirm whether the function has been affected  -->
                        <BtnCta
                            v-if="!noCancel"
                            data-testid="modal-cancel"
                            :variant="BtnCtaVariant.SECONDARY"
                            :class="[
                                {
                                    'mt-4': isDanger,
                                    'md:mt-0 md:ml-4': isDanger && !stackButtons,
                                },
                            ]"
                            :label="cancelText || $t('common:modal.common.cancelText')"
                            :disabled="cancelDisabled"
                            @click="onCancel"
                        />
                    </slot>
                    <!-- @slot Slot for a custom submit button. The submit props will have no affect. -->
                    <slot name="submit-action">
                        <!-- native modifier has been removed, please confirm whether the function has been affected  -->
                        <BtnCta
                            v-if="!noSubmit"
                            data-testid="modal-submit"
                            :variant="isDanger ? BtnCtaVariant.SECONDARY : BtnCtaVariant.PRIMARY"
                            :color="isDanger ? 'negative' : null"
                            :class="[
                                {
                                    'order-first mt-0 ml-0': isDanger,
                                },
                            ]"
                            :label="submitText || $t('common:modal.common.submitText')"
                            :disabled="submitDisabled"
                            @click="onSubmit"
                        />
                    </slot>
                </slot>
            </div>
        </div>

        <Btn
            v-if="withCloseAction"
            data-testid="modal-close"
            variant="text"
            :class="[
                {
                    'order-first px-4 pt-4': isSheet,
                    '!absolute top-0 right-0 m-5 md:m-6 leading-0': !isSheet,
                },
            ]"
            icon="close"
            :compact="!isSheet"
            content-spacing="apart"
            @click="close()"
        />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Btn, { BtnSize, BtnVariant } from '@/components/Btn';
import BtnCta, { BtnCtaVariant } from '@/components/BtnCta';
import { slotIsUsed } from '@/helpers/dom';
import ModalMixin from '@/mixins/modalMixin';
import BottomSheet from '@components/BottomSheet/BottomSheet.vue';
import { useScreenSize } from '@/composables/screenSize';
import { ModalActionTypes } from './Modal';

/**
 * This Modal component provides a common structure to provide a consistend modal experience.
 * Open this component directly through our [Modal Plugin](../?path=/docs/plugins-modal--page) or create other more specific modal implementations based on it.
 *
 * You can control where a modal should appear on the screen.
 * With the modal plugin, the modal will render in our ModalContainer component, which wraps the modal in a flex box.
 * So you can just adjust the placement of a modal through css
 *
 * If you use this component for other modals, make sure to forward the close event.
 */
export default defineComponent({
    name: 'Modal',
    components: {
        Btn,
        BtnCta,
        BottomSheet,
    },
    extends: ModalMixin,
    props: {
        /**
         * title of the modal
         */
        title: {
            type: String,
            default: null,
        },
        /**
         * You can apply custom classes to the title
         */
        titleClass: {
            type: String,
            default: null,
        },
        /**
         * text content of the modal
         */
        text: {
            type: String,
            default: null,
        },
        /**
         * html content of the modal.
         * Important: Use with caution as it can lead to XSS attacks!
         */
        contentHtml: {
            type: String,
            default: null,
        },
        /**
         * Specifies cta appearance. 'danger' will flip buttons and style them differently.
         * @values neutral, danger
         */
        actionType: {
            type: String,
            default: ModalActionTypes.NEUTRAL,
            validator: (val: ModalActionTypes) => Object.values(ModalActionTypes).includes(val),
        },
        /**
         * Text of the submit button
         */
        submitText: {
            type: String,
            default: '',
        },
        /**
         * Whether the submit button is disabled.
         */
        submitDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Wheter the action buttons should be centered
         */
        centerButtons: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the action buttons should stack instead of float in a row
         */
        stackButtons: {
            type: Boolean,
            default: false,
        },
        /**
         * Text of the cancel button
         */
        cancelText: {
            type: String,
            default: '',
        },
        /**
         * Whether the cancel button is disabled.
         */
        cancelDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Hides the cancel button
         */
        noCancel: {
            type: Boolean,
            default: false,
        },
        /**
         * Hides all action buttons
         */
        noActions: {
            type: Boolean,
            default: false,
        },
        /**
         * Hides the submit button
         */
        noSubmit: {
            type: Boolean,
            default: false,
        },
        /**
         * Applies a close icon at the top-right of the modal.
         *
         * Important: Only use this option if explicitly specified by our design team,
         * because our modals normally don't have a close option.
         */
        withCloseAction: {
            type: Boolean,
            default: false,
        },
        /**
         * If the modal should open in the bottom sheet on small screens.
         */
        shouldOpenInBottomSheet: {
            type: Boolean,
            default: false,
        },
        /**
         * Test attribute ID for the modal itself.
         */
        testId: {
            type: String,
            default: undefined,
        },
    },
    emits: ['submit', 'cancel', 'close'],
    setup(props) {
        return {
            isSheet: props.shouldOpenInBottomSheet && useScreenSize().maxSM,
        };
    },
    data() {
        return {
            BtnVariant,
            BtnCtaVariant,
            BtnSize,
        };
    },
    computed: {
        shouldShowContent(): boolean {
            return (
                !!this.text || !!this.contentHtml || slotIsUsed(this.$slots.content) || slotIsUsed(this.$slots.default)
            );
        },
        shouldShowTitle(): boolean {
            return !!this.title || slotIsUsed(this.$slots.title);
        },
        shouldShowHeader(): boolean {
            return this.shouldShowTitle || slotIsUsed(this.$slots.headerActions);
        },
        isDanger(): boolean {
            return this.actionType === ModalActionTypes.DANGER;
        },
    },
    methods: {
        onSubmit() {
            /**
             * Submit event triggered by the submit button.
             * It will emit the `close` event with a value `true`
             */
            this.$emit('submit');
            this.close(true);
        },
        onCancel() {
            /**
             * Cancel event triggered by the cancel button.
             * It will emit the `close` event with a value `false`
             */
            this.$emit('cancel');
            this.close(false);
        },
    },
});
</script>

<style lang="scss" scoped>
.modal {
    max-width: 20rem;

    @screen md {
        width: 34rem;
        max-width: 34rem;
    }
}

.media {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.actions {
    > * {
        @screen md {
            @apply w-auto;
        }
    }
}
</style>
