import {
    fetchHeaderNotifications,
    readHeaderNotification,
    readAllHeaderNotification,
    readAllSideBarNotification,
} from '@/api/gateway/notifications';

const types = {
    SET_COUNT: 'SET_COUNT',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_HEADER_NOTIFICATIONS_HAS_READ: 'SET_HEADER_NOTIFICATIONS_HAS_READ',
};

const state = {
    unreadCount: 0,
    notifications: [],
};

const mutations = {
    [types.SET_COUNT](state, payload) {
        state.unreadCount = payload;
    },
    [types.SET_NOTIFICATIONS](state, payload) {
        state.notifications = payload;
    },
    [types.SET_HEADER_NOTIFICATIONS_HAS_READ](state, payload) {
        /* eslint-disable no-param-reassign */
        payload.forEach((item) => {
            item.read = true;
        });
        state.notifications = payload;
        /* eslint-enable no-param-reassign */
    },
};

const actions = {
    fetchNotifications({ commit }) {
        fetchHeaderNotifications().then((response) => {
            commit(types.SET_COUNT, response.data.unread);
            commit(types.SET_NOTIFICATIONS, response.data.notifications);
        });
    },
    setAsRead({ commit }, notificationId) {
        return readHeaderNotification(notificationId);
    },
    setAllAsRead({ commit }, notifications) {
        return readAllHeaderNotification().finally(() => {
            commit(types.SET_COUNT, 0);
            commit(types.SET_HEADER_NOTIFICATIONS_HAS_READ, notifications);
        });
    },
    setAllSideBarAsRead({ commit }, notificationType) {
        return readAllSideBarNotification(notificationType);
    },
};

const getters = {
    unreadNotificationsCount: (state) => state.unreadCount,
    notifications: (state) => state.notifications,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
