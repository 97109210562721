/**
 * watch and react to window visibility changes.
 * Delays can be specified to delay execution of the callbacks.
 *
 * @returns function to stop watching
 */
export function watchVisibility(onVisible: () => unknown, onHidden: () => unknown, visibleDelay = 0, hiddenDelay = 0) {
    let delayTimeout: ReturnType<typeof setTimeout> | undefined;

    function abort() {
        if (!delayTimeout) return;

        clearTimeout(delayTimeout);
        delayTimeout = undefined;
    }

    function handler() {
        abort();

        if (document.visibilityState === 'visible') {
            delayTimeout = setTimeout(onVisible, visibleDelay);
        } else {
            delayTimeout = setTimeout(onHidden, hiddenDelay);
        }
    }

    document.addEventListener('visibilitychange', handler);

    handler();

    return function stop() {
        document.removeEventListener('visibilitychange', handler);
        abort();
    };
}
