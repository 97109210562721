import { gateway } from '..';

export function getStudyPrograms() {
    return gateway.get('legacy-api/v1/study-programs');
}

export function getSemesters() {
    return gateway.get('legacy-api/v1/semesters');
}

export function getUserStudies(id: number) {
    return gateway.get(`legacy-api/v1/studies/${id}`);
}

export function updateUserStudies(payload: object[]) {
    return gateway.put('legacy-api/v1/studies', { studies: payload });
}

export function removeUserStudies(userId: number) {
    return gateway.delete(`legacy-api/v1/studies/${userId}`);
}

export function getUserStudyOnboarding() {
    return gateway.get('legacy-api/v1/studies/onboarding');
}

export function updateUserStudyOnboarding(payload: object) {
    return gateway.put('legacy-api/v1/studies/onboarding', payload);
}
