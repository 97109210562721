import { EventBus, EventBusEvent } from '@/plugins/eventBus';
import type { FlashMessageOptions } from './types';

export { default } from './FlashMessages.vue';

type NotifyFn = (content: string, options?: Omit<FlashMessageOptions, 'appearance'>) => void;

export const notifyInfo: NotifyFn = (content, options = {}) => {
    EventBus.emit(EventBusEvent.NOTIFY_INFO, content, { ...options, appearance: 'default' });
};

export const notifyError: NotifyFn = (content, options = {}) => {
    EventBus.emit(EventBusEvent.NOTIFY_ERROR, content, { ...options, appearance: 'error' });
};

export const notifySuccess: NotifyFn = (content, options = {}) => {
    EventBus.emit(EventBusEvent.NOTIFY_SUCCESS, content, { ...options, appearance: 'success' });
};
