/**
 * Maps backend search aggregations to an object for better usability.
 * The backend returns aggrgations in the format of: [{key: 'a', doc_count: #}, {key: 'b'', doc_count: #}, ...]
 * We map them to: {a: #, b: #}
 * @param {Array} array
 * @returns {Object}
 */
export function mapAggregationsToObject(array = []) {
    return array.reduce((obj, curr) => {
        // @ts-ignore: unmatched type due to dynamic indexing
        // eslint-disable-next-line no-param-reassign
        obj[curr.key] = curr.doc_count;
        return obj;
    }, {});
}

export enum StudyMaterialTypeId {
    Flashcards = 200,
    Summaries = 80,
    Lectures = 20,
    Assignments = 30,
    Exams = 60,
    Other = 10,
}

export const ACADEMIC_YEAR_MIN = 2000;
export const ACADEMIC_YEAR_MAX = new Date().getFullYear();
