/*
 *
 * There are currently two types of tracking available. Please make sure that you only use the trackEvent() function as
 * trackClick & trackImpression are deprecated.
 *
 * We generalized the old tracking system that would differentiate clicks and impressions only and also required creating new streams
 * anytime a new tracking was requested. This was recreated now in a way that we are more flexible and can store anything without the need of new streams.
 *
 * For more information please check the eureka documentation
 *
 */

import { storeTrackingData, storeTrackEventData } from '@/api/lake';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import isBot from 'isbot';
import Bowser from 'bowser';

const SOURCE = 'sd-web';
const SESSION_ID: string = Math.random().toString(36).substr(2, 9);
const ACCESSED_AT: number = Date.now();

interface InfoType {
    page_field_type: string;
    page_field_name?: string;
    page_field_value?: string;
    page_field_extra_info?: string;
}

/**
 * Generates a data track event object with the given parameters.
 *
 * @param {string} category - The category of the event.
 * @param {string} tracking_name - The tracking name of the event.
 * @param {string} label - The label of the event.
 * @param {object} customData - The custom data object for the event.
 */

function formatDataTrackEvent(category: string, tracking_name: string, label: string, customData: object) {
    return {
        source: SOURCE,
        category,
        tracking_name,
        label,
        custom_params: { ...customData },
        location: window.location.href,
        session_id: SESSION_ID,
        session_start: new Date(ACCESSED_AT).toISOString(),
        session_duration: Math.floor((Date.now() - ACCESSED_AT) / 1000),
        device_model: null,
        browser_info: Bowser.parse(window.navigator.userAgent),
        user_id: store.getters['user/user'].userid || (null as number | null),
        user_is_ce: store.getters['user/user'].is_ke as boolean | null,
        user_role_id: store.getters['user/user'].role_id || (null as number | null),
    };
}
/**
 * Executes a tracking event and stores the tracking data.
 *
 * @param {string} category - The category of the event.
 * @param {string} tracking_name - The name of the event being tracked.
 * @param {string} label - The label associated with the event.
 * @param {object} customData - Additional custom data for the event.
 * @param {boolean} checkForBots - Whether to check for bots before tracking the event.
 * @return {boolean} Returns false if the user agent is identified as a bot, otherwise returns true.
 */

export function trackEvent(category = '', tracking_name = '', label = '', customData = {}, checkForBots = true) {
    if (checkForBots && isBot(navigator.userAgent)) return false;
    const data = formatDataTrackEvent(category, tracking_name, label, customData);
    return storeTrackEventData(data);
}

/*
 *
 * DEPRECATED OLD TRACKING SYSTEM
 *
 * DO NOT USE ANYMORE
 *
 */

const IMPRESSION = 'Impression';
const CLICK = 'Click';
const CHANNEL = 1;

function generateUUIDv4() {
    return uuidv4() as string;
}

function getDateIsoString(milliseconds: number): string {
    const date = new Date(milliseconds);
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')}T${date.getUTCHours().toString().padStart(2, '0')}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}:${(date.getUTCSeconds() + date.getUTCMilliseconds() / 1000).toFixed(6).padStart(9, '0')}Z`;
}

function getTrackingType(model: string, action: string): string {
    return `${model}${action}`;
}

function getTimeInThePage(): number {
    const now = new Date().getTime();
    return Math.floor((now - ACCESSED_AT) / 1000);
}

function formatDataToSend(data: object, trackingType: string, model: string): object {
    return {
        uri: window.location.href,
        uuid: generateUUIDv4(),
        occurred_at: getDateIsoString(Date.now()),
        type: getTrackingType(model, trackingType),
        source: SOURCE,
        data: {
            channel: CHANNEL,
            page_accessed_at: getDateIsoString(ACCESSED_AT),
            page_session_id: SESSION_ID,
            page_session_duration_secs: getTimeInThePage(),
            user_id: store.getters['user/user'].userid || null,
            role_id: store.getters['user/user'].role_id || null,
            ...data,
        },
    };
}

export function trackImpression(model = '', impressionData = {}, checkForBots = true) {
    if (checkForBots && isBot(navigator.userAgent)) return false;

    const data = formatDataToSend(impressionData, IMPRESSION, model);
    return storeTrackingData(data);
}

export async function trackClick(model = '', clickData = {}, checkForBots = true) {
    if (checkForBots && isBot(navigator.userAgent)) return false;

    const data = formatDataToSend(clickData, CLICK, model);
    return storeTrackingData(data);
}

/*
 * NOT DEPRECATED! This code is related to the v-tracking directive that is used to track clicks on injected html.
 * This might be reused and changed to be also used for the generalized tracking system. This needs to be at the end because
 * it currently still uses the trackClick function.
 *
 * Some places, that are not injecting html, use data-click. This is not really necessary so please use native @click events
 * to trigger the trackEvent function for the generalized tracking system.
 *
 * */

function formatClickedDataAttrs(event: Event, additionalData: object, dataClickAttr: string): object {
    const info: InfoType = {
        page_field_type: '',
    };
    const extra = [];

    const target = event.target as HTMLElement | null;

    if (target) {
        info.page_field_type = target.localName;

        const dataClick = dataClickAttr.split(',');
        const [pageFieldName, pageFieldValue] = dataClick;
        if (pageFieldName) {
            info.page_field_name = pageFieldName;
        }

        if (pageFieldValue) {
            info.page_field_value = pageFieldValue;
        }

        if (target.getAttribute('alt')) extra.push(`alt: ${target.getAttribute('alt')}`);
        if (target.getAttribute('title')) extra.push(`title: ${target.getAttribute('title')}`);
        if (extra.length > 0) info.page_field_extra_info = extra.join(' | ');
    }

    return {
        ...info,
        ...additionalData,
    };
}

function getDataClickAttr(event: Event): string {
    const target: HTMLElement = event.target as HTMLElement;
    let dataClick: string | null = target.getAttribute('data-click');
    if (dataClick === null) {
        dataClick = '';
    }
    const closestAnchor = target.closest('a');
    if (closestAnchor?.hasAttribute('data-click')) {
        dataClick = closestAnchor.getAttribute('data-click');
        if (dataClick === null) {
            dataClick = '';
        }
    }
    return dataClick;
}

function trackIframeClick(element: HTMLElement) {
    window.focus();
    // TODO: this event needs to be cleaned up
    window.addEventListener('blur', () => {
        if (element.contains(document.activeElement) && document.activeElement?.tagName === 'IFRAME') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.activeElement.click();

            setTimeout(() => {
                window.focus();
            }, 500);
        }
    });
}

export function initializeClickListeningToTrack(
    element: HTMLElement,
    model = '',
    additionalData = {},
    checkForBots = true,
    hasIframe = false,
): void {
    // TODO: this event needs to be cleaned up
    element.addEventListener('click', (event: Event) => {
        if (element.contains(event.target as Node)) {
            const dataClickAttr = getDataClickAttr(event);
            if (dataClickAttr) {
                const clickedDataAttrs = formatClickedDataAttrs(event, additionalData, dataClickAttr);
                trackClick(model, clickedDataAttrs, checkForBots);
            }
        }
    });
    if (hasIframe) {
        trackIframeClick(element);
    }
}
