import type ModernizrType from 'modernizr';
import '../../../.modernizrrc';

// declaring global Modernizr variable type
declare global {
    interface Window {
        Modernizr: typeof ModernizrType;
    }

    interface ModernizrAPI {
        // override the on listener to better specify the result type
        on<T = boolean>(feature: string, cb: (result: T) => unknown): void;
    }
}

export const { Modernizr } = window;
