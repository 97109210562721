function getWheelListenerFor(el) {
    return (event) => {
        el.scrollBy({ left: event.deltaY });
        event.preventDefault();
    };
}

function addEventListener(el) {
    el.horizontalScrollDirective = {
        onWheel: getWheelListenerFor(el),
    };

    el.addEventListener('wheel', el.horizontalScrollDirective.onWheel, { passive: false });
}

function removeEventListener(el) {
    if (!el.horizontalScrollDirective?.onWheel) return;

    el.removeEventListener('wheel', el.horizontalScrollDirective.onWheel);
}

/**
 * transforms mouse wheel movement into horizontal scroll
 */
export default {
    beforeMount(el) {
        removeEventListener(el);
        addEventListener(el);
    },
    unmounted(el) {
        removeEventListener(el);
    },
};
