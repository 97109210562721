import { useCookies } from '@vueuse/integrations/useCookies';
import jwtDecode from 'jwt-decode';
import { type AxiosInstance } from 'axios';
import { requestLockOrWait } from '@/helpers/locks';
import { timeout } from '@/helpers/misc';
import { HTTP_STATUS } from '@/utils/networkUtils';
import route from '@/router/route';
import { Lock } from '@/utils/locksUtils';
import type { ApiAccessToken } from '@/api/gateway/types';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';
import { captureException } from '@sentry/vue';

/**
 * The axios instance on which the token refresh should occur needs to be set before the first token refresh
 */
class TokenRefreshUtils {
    /** marks timeout for next token refresh */
    #tokenRefreshTimeout: ReturnType<typeof setTimeout> | undefined;

    /** axios instance used for token refresh */
    #axios?: AxiosInstance;

    setAxiosInstance(axios: AxiosInstance) {
        this.#axios = axios;
    }

    unscheduleTokenRefresh() {
        if (this.#tokenRefreshTimeout) {
            clearTimeout(this.#tokenRefreshTimeout);
            this.#tokenRefreshTimeout = undefined;
        }
    }

    /**
     * Returns the expiration of the access token in milliseconds since 1970
     */
    static #getExpirationOfToken(token: string) {
        return jwtDecode<ApiAccessToken>(token).exp * 1000;
    }

    /**
     * Depending on the access token expiration, a token refresh is scheduled to occur before the token expires
     */
    scheduleTokenRefresh() {
        this.unscheduleTokenRefresh();

        const at = useCookies().get(import.meta.env.MIX_AUTH_ACCESS_TOKEN_NAME);
        // Cookie.get could return an object if the env variable is undefined
        if (!at || typeof at !== 'string') return;

        let exp;

        try {
            exp = TokenRefreshUtils.#getExpirationOfToken(at);
        } catch (e) {
            captureException(e);
            return;
        }

        const now = Date.now();
        const buffer = 60000; // 1 minute

        // This should result in every process starting a token refresh at the same time.
        // As locks are used, only one process would end up doing the actual refresh.
        const wait = Math.max(exp - now - buffer, 0);

        this.#tokenRefreshTimeout = setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            this.doTokenRefresh();
        }, wait);
    }

    /**
     * Initiates a token refresh across all browser tabs.
     * Calling it multiple times will result in waiting for the initial refresh to finish.
     * It will block any request from going out until the refresh is finished.
     *
     * If any error occurs, the user is prompted to login again.
     */
    doTokenRefresh() {
        if (!this.#axios) {
            throw Error('token refresh axios instance not set');
        }

        this.unscheduleTokenRefresh();
        // use passed instance here to avoid circular dependencies
        return requestLockOrWait(Lock.TOKEN_REFRESH, () =>
            navigator.locks.request(Lock.SEND_REQUEST, async () => {
                await this.#axios?.get(route('auth-provider.refresh'));
                // we add a small timeout so that a new token has a bit of time to update everywhere before starting using it
                await timeout(100);
            }),
        )
            .then(() => this.scheduleTokenRefresh())
            .catch((error) => {
                if (error?.response?.status !== HTTP_STATUS.MAINTENANCE) {
                    // prompt to login if the server is reachable
                    EventBus.emit(EventBusEvent.PROMPT_LOGIN);
                }
                throw error;
            });
    }
}

/**
 * Only use this function for backend responses as gateway responses behave differently.
 */
export function shouldDoTokenRefreshWhen(status: number, url?: string) {
    return status === HTTP_STATUS.UNAUTHORIZED && url !== route('auth-provider.refresh').toString();
}

export default new TokenRefreshUtils();
