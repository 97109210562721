import { trackFloatingStickyElement } from '@/modules/sticky';
import { storeXandrCloseAdCookie } from '@/plugins/cookie';
import { featureIsEnabled } from '@/modules/features';
import {
    postCompanyImpression,
    postTopCompanyClick,
    postTopCompanyImpression,
    postTopCompaniesView,
} from '@/api/backend/company';

function shouldExcludeFromTracking(context) {
    return context.rootGetters['auth/user'] && context.rootGetters['auth/isAdmin'];
}

const careerTrackingEnabled = featureIsEnabled('careerTracking');

const types = {
    SET_RETRIEVAL_PROGRESS: 'SET_RETRIEVAL_PROGRESS',
    SET_STICKY_SIDEBAR_AD: 'SET_STICKY_SIDEBAR_AD',
    COMPANY_IMPRESSION_PUSH: 'COMPANY_IMPRESSION_PUSH',
    COMPANY_IMPRESSION_PROGRESS: 'COMPANY_IMPRESSION_PROGRESS',
    IS_BOTTOM_FIXED_BANNER_ALLOWED: 'IS_BOTTOM_FIXED_BANNER_ALLOWED',
    HAS_AD_BLOCKER: 'HAS_AD_BLOCKER',
};

const state = {
    /**
     *  config: {
     *      page: 'feed',
     *      types: ['default', 'right_sidebar_top', 'between_feed_items', 'bottom_fixed'],
     *      extras: {
     *          sponsoredCourse: {id: course/campaign_id, some other shit}
     *      }
     *  }
     */
    retrievalInProgress: false,
    isSidebarAdSticky: false,
    storeImpressionProgress: false,
    storedImpressions: [],
    bottomFixedAllowed: false,
    hasAdBlocker: false,
};

const mutations = {
    [types.SET_RETRIEVAL_PROGRESS](state, payload) {
        state.retrievalInProgress = payload;
    },
    [types.SET_STICKY_SIDEBAR_AD](state, payload) {
        state.isSidebarAdSticky = payload;
    },
    [types.COMPANY_IMPRESSION_PUSH](state, companyId) {
        state.storedImpressions.push(companyId);
    },
    [types.COMPANY_IMPRESSION_PROGRESS](state, payload) {
        state.impressionStoreProgress = payload;
    },
    [types.IS_BOTTOM_FIXED_BANNER_ALLOWED](state, payload) {
        state.bottomFixedAllowed = payload;
    },
    [types.HAS_AD_BLOCKER](state, payload) {
        state.hasAdBlocker = payload;
    },
};

const actions = {
    trackFloatingStickyElement,
    storeXandrCloseAdCookie,
    storeTopCompanyImpression(context, companyId) {
        if (shouldExcludeFromTracking(context) || context.getters.storedImpressions.includes(companyId)) {
            return false;
        }
        context.commit('COMPANY_IMPRESSION_PROGRESS', true);

        if (careerTrackingEnabled) {
            return postTopCompanyImpression(companyId).finally(() => {
                context.commit('COMPANY_IMPRESSION_PUSH', companyId);
                context.commit('COMPANY_IMPRESSION_PROGRESS', false);
            });
        }
        return true;
    },
    storeTopCompanyView(context, companyIds = []) {
        if (shouldExcludeFromTracking(context)) {
            return;
        }

        if (careerTrackingEnabled) {
            postTopCompaniesView(companyIds);
        }
    },
    storeTopCompanyClick(context, companyId) {
        if (shouldExcludeFromTracking(context)) {
            return;
        }

        if (careerTrackingEnabled) {
            postTopCompanyClick(companyId);
        }
    },
    storeCompanyImpression(context, companyId) {
        if (shouldExcludeFromTracking(context)) {
            return;
        }

        if (careerTrackingEnabled) {
            postCompanyImpression(companyId);
        }
    },
    shouldShowBottomFixed({ commit }, data) {
        commit(types.IS_BOTTOM_FIXED_BANNER_ALLOWED, data);
    },
    updateUserHasAdBlocker({ commit }, data) {
        commit(types.HAS_AD_BLOCKER, data);
    },
};

const getters = {
    retrievalInProgress: (state) => state.retrievalInProgress,
    isSidebarAdSticky: (state) => state.isSidebarAdSticky,
    storedImpressions: (state) => state.storedImpressions,
    storeImpressionProgress: (state) => state.storeImpressionProgress,
    isBottomFixedAdAllowed: (state) => state.bottomFixedAllowed,
    hasAdBlocker: (state) => state.hasAdBlocker,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
