export const CALLOUTS = {
    newsfeed: 7,
    courses: 8,
    groups: 9,
    profile: 10,
    ceDashboard: 12,
    careerCornerWelcome: 13,
    newsfeedCareerCornerExistingUser: 14,
    newsfeedCareerCornerOnBoarding: 15,
};
