import type { Plugin } from 'vue';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Component specific id provided by our IdPlugin
         */
        $id: string;
    }
}

let idCounter = 0;

export default {
    /**
     * Adds a unique id to each component
     */
    install(app) {
        app.mixin({
            beforeCreate() {
                idCounter += 1;
                this.$id = idCounter.toString();
            },
        });
    },
} as Plugin;
