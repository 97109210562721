import { prefersReducedMotion } from '@helpers/browser';

export const DEFAULT_CONFIG = {
    amount: 0.25,
};

let elements = [];
let hasTranslatedElements = false;

/**
 * Offsets element to create parallax effect.
 * AN element is boffset around its neutral position (centered on the screen)
 * @param {Object} obj
 */
function offsetElement(obj) {
    const { el } = obj;
    const rect = el.getBoundingClientRect();
    const height = rect.bottom - rect.top;
    const offset = -(rect.top - obj.translated + height / 2 - window.innerHeight / 2) * obj.config.amount;

    obj.translated = offset; // eslint-disable-line no-param-reassign
    el.style.transform = `translateY(${offset}px) ${obj.transform}`;
    hasTranslatedElements = true;
}

function resetElement(obj) {
    /* eslint-disable no-param-reassign */
    obj.translated = 0;
    obj.el.style.transform = obj.transform;
    /* eslint-enable no-param-reassign */
}

function resetElements() {
    if (!hasTranslatedElements) return;

    hasTranslatedElements = false;
    elements.forEach(resetElement);
}

function updateElements() {
    // don't apply parallax if reduced motion is preferred
    if (prefersReducedMotion()) {
        // reset elements if preference was changed while already having elements transitioned
        resetElements();
        return;
    }

    elements.forEach(offsetElement);
}

function addListeners() {
    window.addEventListener('scroll', updateElements);
    window.addEventListener('resize', updateElements);
    window.addEventListener('load', updateElements);
}

function removeListeners() {
    window.removeEventListener('scroll', updateElements);
    window.removeEventListener('resize', updateElements);
    window.removeEventListener('load', updateElements);
}

/**
 * adds element
 */
function mounted(el, binding) {
    const obj = {
        el,
        transform: getComputedStyle(el).getPropertyValue('transform').replace('none', ''),
        translated: 0,
        config: {
            ...DEFAULT_CONFIG,
            ...(typeof binding.value === 'object' ? binding.value : {}),
        },
    };

    elements.push(obj);

    if (elements.length <= 1) {
        addListeners();
    }

    if (!prefersReducedMotion()) {
        offsetElement(obj);
    }
}

/**
 * updates element config and updates element offset
 */
function updated(el, binding) {
    const obj = elements.find((element) => element.el === el);

    if (!obj) return;
    obj.config = {
        ...obj.config,
        ...(typeof binding.value === 'object' ? binding.value : {}),
    };

    if (!prefersReducedMotion()) {
        offsetElement(obj);
    }
}

/**
 * removes element from listeners and stops listeners if directive is not used anymore
 */
function unmounted(el) {
    elements = elements.filter((obj) => {
        return obj.el !== el;
    });

    if (elements.length === 0) {
        removeListeners();
    }
}

export default {
    mounted,
    updated,
    unmounted,
};
