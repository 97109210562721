<template>
    <component
        :is="tag"
        :class="'share-network-' + key"
        :href="link"
        @click="handleClick"
    >
        <slot></slot>
    </component>
</template>

<script lang="ts">
import type { Component, PropType } from 'vue';
import { defineComponent } from 'vue';
import { TinyEmitter } from 'tiny-emitter';
import AvailableNetworks from './networks';

const EventBus = new TinyEmitter();
// @todo need to be fixed to open only one window at a time for each network
const sdNetworkWindows: Record<string, Window | null> = {};

export default defineComponent({
    name: 'LinkShare',
    props: {
        /**
         * Name of the network to display.
         */
        network: {
            type: String,
            required: true,
        },
        /**
         * URL of the content to share.
         */
        url: {
            type: String,
            required: true,
        },
        /**
         * Title of the content to share.
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Description of the content to share.
         */
        description: {
            type: String,
            default: '',
        },
        /**
         * Quote content, used for Facebook.
         */
        quote: {
            type: String,
            default: '',
        },
        /**
         * Hashtags, used for Twitter and Facebook.
         */
        hashtags: {
            type: String,
            default: '',
        },
        /**
         * Twitter user, used for Twitter
         * @var string
         */
        twitterUser: {
            type: String,
            default: '',
        },
        /**
         * Media to share, used for Pinterest
         */
        media: {
            type: String,
            default: '',
        },
        /**
         * HTML tag used by the Network component.
         */
        tag: {
            type: [String, Object] as PropType<string | Component>,
            default: 'a',
        },
        /**
         * Properties to configure the popup window.
         */
        popup: {
            type: Object as PropType<{ width: number; height: number }>,
            default: () => ({
                width: 626,
                height: 436,
            }),
        },
        /**
         * Add more custom networks to available ones, pair of network_name: sharable_link
         */
        extraNetworks: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
    },
    data() {
        return {
            popupTop: 0 as number,
            popupLeft: 0 as number,
        };
    },
    computed: {
        /**
         * List of available networks
         */
        networks() {
            return Object.assign(AvailableNetworks, this.extraNetworks || {}) as Record<string, string>;
        },
        /**
         * Formatted network name.
         */
        key(): string {
            return this.network?.toLowerCase();
        },
        /**
         * Network sharing raw sharing link.
         */
        rawLink(): string {
            const ua = navigator.userAgent.toLowerCase();

            if (!this.networks) {
                return '';
            }
            /**
             * On IOS, SMS sharing link need a special formatting
             * Source: https://weblog.west-wind.com/posts/2013/Oct/09/Prefilling-an-SMS-on-Mobile-Devices-with-the-sms-Uri-Scheme#Body-only
             */
            if (this.key === 'sms' && (ua.includes('iphone') || ua.includes('ipad'))) {
                return this.networks[this.key].replace(':?', ':&');
            }

            return this.networks[this.key];
        },
        /**
         * Create the url for sharing.
         */
        shareLink(): string {
            let link = this.rawLink;

            /**
             * Twitter sharing shouldn't include empty parameter
             * Source: https://github.com/nicolasbeauvais/vue-social-sharing/issues/143
             */
            if (this.key === 'twitter') {
                if (!this.hashtags.length) link = link.replace('&hashtags=@h', '');
                if (!this.twitterUser.length) link = link.replace('@tu', '');
            }

            return link
                .replace(/@tu/g, `&via=${encodeURIComponent(this.twitterUser)}`)
                .replace(/@u/g, encodeURIComponent(this.url))
                .replace(/@t/g, encodeURIComponent(this.title))
                .replace(/@d/g, encodeURIComponent(this.description))
                .replace(/@q/g, encodeURIComponent(this.quote))
                .replace(/@h/g, this.encodedHashtags)
                .replace(/@m/g, encodeURIComponent(this.media));
        },
        /**
         * Encoded hashtags for the current social network.
         */
        encodedHashtags(): string {
            if (this.key === 'facebook' && this.hashtags.length) {
                return `%23${this.hashtags.split(',')[0]}`;
            }

            return this.hashtags;
        },
        link(): string | null {
            // eslint-disable-next-line no-script-url
            return this.tag === 'a' ? 'javascript:void(0)' : null;
        },
    },
    methods: {
        handleClick(): void {
            this.rawLink?.startsWith('http') ? this.share() : this.touch();
        },
        /**
         * Center the popup on multi-screens
         * http://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen/32261263
         */
        resizePopup(): void {
            const width = window.innerWidth || document.documentElement.clientWidth || window.screenX;
            const height = window.innerHeight || document.documentElement.clientHeight || window.screenY;
            const systemZoom = width / window.screen.availWidth;

            this.popupLeft =
                (width - this.popup.width) / 2 / systemZoom +
                (window.screenLeft !== undefined ? window.screenLeft : window.screenX);
            this.popupTop =
                (height - this.popup.height) / 2 / systemZoom +
                (window.screenTop !== undefined ? window.screenTop : window.screenY);
        },
        closeWindow(network: string): void {
            delete sdNetworkWindows[network];
        },
        /**
         * Shares URL in specified network.
         */
        share(): void {
            this.resizePopup();
            if (!sdNetworkWindows[this.key]) {
                sdNetworkWindows[this.key] = window.open(
                    this.shareLink,
                    `sharer-${this.key}`,
                    `,height=${this.popup.height},width=${this.popup.width},left=${this.popupLeft},top=${this.popupTop},screenX=${this.popupLeft},screenY=${this.popupTop}`,
                );

                this.emit('open');
            }
            if (sdNetworkWindows[this.key]?.closed) {
                this.closeWindow(this.key);
                this.emit('close');
            } else {
                sdNetworkWindows[this.key]?.focus();
            }
        },
        touch(): void {
            window.open(this.shareLink, '_blank');
            this.emit('open');
        },
        emit(name: string): void {
            EventBus.emit(name, this.key, this.url);
        },
    },
});
</script>
