<template>
    <component
        :is="footerComponent.name"
        v-bind="footerComponent.props"
        v-if="!isLoggedIn || screen.minLG"
        :class="{ 'container hidden lg:block': isLoggedIn }"
    />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { FooterAuthArrangement } from './FooterAuth';

/**
 * Our main bottom page footer.
 * Guest users will receive the guest footer, whereas users get to see the auth footer, but only on large screens as it is otherwise included in our sidebar.
 */
export default defineComponent({
    name: 'TheFooter',
    components: {
        FooterGuest: defineAsyncComponent(() => import('./FooterGuest.vue')),
        FooterAuth: defineAsyncComponent(() => import('./FooterAuth.vue')),
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            screen: 'ui/screen',
        }),
        footerComponent(): { name: string; props?: object } {
            return this.isLoggedIn
                ? { name: 'FooterAuth', props: { arrangement: FooterAuthArrangement.VERTICAL } }
                : { name: 'FooterGuest' };
        },
    },
});
</script>

<style lang="scss" scoped></style>
