import { find, flatMap, get, map, uniqBy } from 'lodash-es';
import {
    getCredits,
    retrieveGroupMemberships,
    retrieveUserChallenges,
    finishUserChallenges,
    deleteProfilePicture,
} from '@/api/backend/user';
import { fetchBlockedUsers, storeBlockedUser, deleteBlockedUser } from '@/api/gateway/users/blocks';
import { orderObjects } from '@/helpers/array';
import { orderCourses } from '@/api/backend/course';
import { orderGroups } from '@/api/backend/group';
import { CountryId } from '@/utils/countryUtils';

const types = {
    SET_USER_STUDIES: 'SET_USER_STUDIES',
    SET_CREDITS: 'SET_CREDITS',
    SET_CREDITS_TOTAL: 'SET_CREDITS_TOTAL',
    /** course sidebar */
    UPDATE_USER_COURSES: 'UPDATE_USER_COURSES',
    UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL: 'UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL',
    RESET_COURSES_ORDER: 'RESET_COURSES_ORDER',
    PUSH_COURSE: 'PUSH_COURSE',
    POP_COURSE: 'POP_COURSE',
    SET_COURSES_ERROR: 'SET_GROUPS_ERROR',
    SET_COURSES_PROGRESS: 'SET_GROUPS_PROGRESS',
    /** group sidebar */
    UPDATE_USER_GROUPS: 'UPDATE_USER_GROUPS',
    UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL: 'UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL',
    RESET_GROUPS_ORDER: 'RESET_GROUPS_ORDER',
    PUSH_GROUP: 'PUSH_GROUP',
    POP_GROUP: 'POP_GROUP',
    SET_GROUPS_ERROR: 'SET_GROUPS_ERROR',
    SET_GROUPS_PROGRESS: 'SET_GROUPS_PROGRESS',
    UPDATE_CHALLENGES: 'UPDATE_CHALLENGES',
    FINISH_CHALLENGES: 'FINISH_CHALLENGES',
    DISABLE_CHALLENGES: 'DISABLE_CHALLENGES',
    SET_CHALLENGES_ERROR: 'SET_CHALLENGES_ERROR',
    SET_CHALLENGES_PROGRESS: 'SET_CHALLENGES_PROGRESS',
    SET_BLOCKED_USERS: 'SET_BLOCKED_USERS',
    DELETE_BLOCKED_USER: 'DELETE_BLOCKED_USER',
};

const state = {
    user: {
        ...get(window.sdWindow, 'user', null),
        courses: {
            items: [],
            progress: false,
            error: false,
            totalNotifications: 0,
        },
        groups: {
            items: [],
            progress: false,
            error: false,
            totalNotifications: 0,
        },
    },
    challenges: {
        enabled: get(window.sdWindow, 'hasUserChallenges', false),
        progress: false,
        data: null,
    },
    blockedUsers: [],
};

const mutations = {
    [types.SET_USER_STUDIES](state, payload) {
        state.user.studies = payload;
    },
    [types.SET_CREDITS](state, payload) {
        state.user.gamification.credits = payload;
    },
    [types.SET_CREDITS_TOTAL](state, payload) {
        state.user.credits = payload.total;
    },
    [types.UPDATE_USER_COURSES](state, payload) {
        state.user.courses.items = payload;
    },
    [types.UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL](state, payload) {
        state.user.courses.totalNotifications = payload;
    },
    [types.RESET_COURSES_ORDER](state) {
        state.user.courses.items.forEach((item, index) => {
            Object.assign(item, {
                order: index + 1,
            });
        });
    },
    [types.PUSH_COURSE](state, course) {
        state.user.courses.items.unshift(course);
    },
    [types.POP_COURSE](state, course) {
        const i = state.user.courses.items.map((item) => item.id).indexOf(course.id);
        state.user.courses.items.splice(i, 1);
    },
    [types.SET_COURSES_PROGRESS](state, payload) {
        state.user.courses.progress = payload;
    },
    [types.SET_COURSES_ERROR](state, payload) {
        state.user.courses.error = payload;
    },

    [types.UPDATE_USER_GROUPS](state, payload) {
        state.user.groups.items = payload;
    },
    [types.UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL](state, payload) {
        state.user.groups.totalNotifications = payload;
    },
    [types.RESET_GROUPS_ORDER](state) {
        state.user.groups.items.forEach((item, index) => {
            Object.assign(item, {
                order: index + 1,
            });
        });
    },
    [types.PUSH_GROUP](state, group) {
        state.user.groups.items.unshift(group);
    },
    [types.POP_GROUP](state, group) {
        const i = state.user.groups.items.map((item) => item.id).indexOf(group.id);
        state.user.groups.items.splice(i, 1);
    },
    [types.SET_GROUPS_PROGRESS](state, payload) {
        state.user.groups.progress = payload;
    },
    [types.SET_GROUPS_ERROR](state, payload) {
        state.user.groups.error = payload;
    },
    [types.UPDATE_CHALLENGES](state, payload) {
        state.challenges.data = payload;
    },
    [types.FINISH_CHALLENGES](state) {
        if (state.challenges.data) {
            state.challenges.data.isFinished = true;
        }
    },
    [types.DISABLE_CHALLENGES](state) {
        state.challenges.enabled = false;
    },
    [types.SET_CHALLENGES_PROGRESS](state, payload) {
        state.challenges.progress = payload;
    },
    [types.SET_CHALLENGES_ERROR](state, payload) {
        state.challenges.error = payload;
    },
    [types.SET_BLOCKED_USERS](state, payload) {
        payload.forEach((newBlockedUser) => {
            const blockedUserIndex = state.blockedUsers.findIndex(
                (blockedUser) => blockedUser.id === newBlockedUser.id,
            );
            if (blockedUserIndex < 0) {
                state.blockedUsers.push(newBlockedUser);
            } else {
                state.blockedUsers[blockedUserIndex] = newBlockedUser;
            }
        });
    },
    [types.DELETE_BLOCKED_USER](state, payload) {
        const blockedUserIndex = state.blockedUsers.findIndex((blockedUser) => blockedUser.id === payload);
        if (blockedUserIndex >= 0) {
            delete state.blockedUsers[blockedUserIndex];
        }
    },
};

const actions = {
    async fetchChallenges({ commit }) {
        try {
            commit(types.SET_CHALLENGES_ERROR, false);
            commit(types.SET_CHALLENGES_PROGRESS, true);
            commit(types.UPDATE_CHALLENGES, await retrieveUserChallenges());
        } catch (err) {
            commit(types.SET_CHALLENGES_ERROR, true);
            commit(types.UPDATE_CHALLENGES, null);
            commit(types.DISABLE_CHALLENGES);
        } finally {
            commit(types.SET_CHALLENGES_PROGRESS, false);
        }
    },
    async finishChallenges({ commit }) {
        if (await finishUserChallenges()) {
            commit(types.FINISH_CHALLENGES);
        }
    },
    async deleteProfilePicture({ commit }) {
        if (await deleteProfilePicture()) {
            commit(types.FINISH_CHALLENGES);
        }
    },
    async fetchGroups({ commit }) {
        try {
            commit(types.SET_GROUPS_ERROR, false);
            commit(types.SET_GROUPS_PROGRESS, true);
            const response = await retrieveGroupMemberships();
            commit(types.UPDATE_USER_GROUPS, response.data);
            commit(types.UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL, response.meta.totalCount);
        } catch (err) {
            commit(types.SET_GROUPS_ERROR, true);
            commit(types.UPDATE_USER_GROUPS, []);
            commit(types.UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL, 0);
        } finally {
            commit(types.SET_GROUPS_PROGRESS, false);
        }
    },
    fetchCourses({ commit }) {
        try {
            commit(types.SET_COURSES_ERROR, false);
            commit(types.SET_COURSES_PROGRESS, true);
            commit(types.UPDATE_USER_COURSES, get(sdWindow, 'courses.data', []));
            commit(types.UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL, get(sdWindow, 'courses.meta.totalCount', 0));
        } catch (err) {
            commit(types.SET_COURSES_ERROR, true);
            commit(types.UPDATE_USER_COURSES, []);
            commit(types.UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL, 0);
        } finally {
            commit(types.SET_COURSES_PROGRESS, false);
        }
    },
    markAllNotificationTotal({ commit }, isCoursesNotifications) {
        if (isCoursesNotifications) {
            commit(types.UPDATE_USER_COURSES_NOTIFICATIONS_TOTAL, 0);
        } else {
            commit(types.UPDATE_USER_GROUPS_NOTIFICATIONS_TOTAL, 0);
        }
    },
    setStudies({ commit }, studies) {
        commit(types.SET_USER_STUDIES, studies);
    },
    pushCourse({ commit }, course) {
        commit(types.PUSH_COURSE, course);
        commit(types.RESET_COURSES_ORDER);
    },
    popCourse({ commit }, course) {
        commit(types.POP_COURSE, course);
        commit(types.RESET_COURSES_ORDER);
    },
    pushGroup({ commit }, group) {
        commit(types.PUSH_GROUP, group);
        commit(types.RESET_GROUPS_ORDER);
    },
    popGroup({ commit }, group) {
        commit(types.POP_GROUP, group);
        commit(types.RESET_GROUPS_ORDER);
    },
    retrieveCredits({ commit }) {
        return getCredits().then((response) => commit('SET_CREDITS', response.data));
    },
    retrieveCreditsTotal({ commit }) {
        return getCredits().then((response) => commit('SET_CREDITS_TOTAL', response.data));
    },
    /**
     * @param {*} order - array of course ids
     */
    orderCourses({ commit, getters }, order) {
        commit(types.UPDATE_USER_COURSES, orderObjects(getters.courses, order));
        orderCourses(order);
    },
    /**
     * @param {*} order - array of group ids
     */
    orderGroups({ commit, getters }, order) {
        commit(types.UPDATE_USER_GROUPS, orderObjects(getters.groups, order));
        orderGroups(order);
    },
    async blockedUsers({ commit }) {
        return fetchBlockedUsers().then((response) => {
            commit(types.SET_BLOCKED_USERS, response.data);
        });
    },
    async blockUser({ commit }, data) {
        return storeBlockedUser(data.userId).then((response) => {
            commit(types.SET_BLOCKED_USERS, response.data);
        });
    },
    async unblockUser({ commit }, data) {
        return deleteBlockedUser(data.userId).then(() => {
            commit(types.DELETE_BLOCKED_USER, data.userId);
        });
    },
};

const getters = {
    user: (state) => state.user,
    groups: (state) => state.user.groups.items,
    groupsError: (state) => state.user.groups.error,
    groupsProgress: (state) => state.user.groups.progress,
    totalGroupNotifications: (state) => state.user.groups.totalNotifications,
    courses: (state) => state.user.courses.items,
    coursesError: (state) => state.user.courses.error,
    coursesProgress: (state) => state.user.courses.progress,
    inCourse: (state) => (courseId) => {
        return state.user.courses.items.filter((course) => course.id === courseId).length > 0;
    },
    inGroup: (state) => (groupId) => {
        return state.user.groups.items.filter((group) => group.id === groupId).length > 0;
    },
    /** whether user has a study in a certain country */
    hasStudyInCountry: (state, getters) => (countryId) =>
        getters.studies.some((study) => study.country_id === countryId),
    /** whether user has a study in germany */
    hasStudyInGermany: (state, getters) => getters.hasStudyInCountry(CountryId.GERMANY),
    totalCourseNotifications: (state) => state.user.courses.totalNotifications,
    studies: (state) => state.user?.studies || [],
    primaryStudy: (state, getters) => {
        const userStudy = find(getters.studies, (study) => study.primary === true);
        if (!userStudy) {
            return getters.studies[0] ? getters.studies[0] : null;
        }
        return userStudy;
    },
    universities: (state, getters) => {
        if (!getters.studies) {
            return [];
        }
        return map(uniqBy(getters.studies, 'uniId'), (study) => {
            return study.uniId;
        });
    },
    majors: (state, getters) => {
        if (!getters.submajors) {
            return [];
        }
        return map(uniqBy(getters.submajors, 'category_id'), (major) => {
            return { id: major.category_id, name: major.category };
        });
    },
    submajors: (state, getters) => {
        if (!getters.studies) {
            return [];
        }
        // USMs have a 'related' attribute, containing the related major. If this attribute is not present, we just return the major
        const majors = flatMap(getters.studies, (study) => study.majors.map((major) => major.related || major));
        return uniqBy(majors, 'id');
    },
    challengesEnabled: (state) => (state.challenges ? state.challenges.enabled : false),
    challengesFinished: (state) => (state.challenges.data ? state.challenges.data.isFinished : false),
    challengesInProgress: (state) => state.challenges && state.challenges.progress,
    challenges: (state) => state.challenges.data,
    blockedUsers: (state) => state.blockedUsers,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
