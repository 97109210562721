const types = {
    SET_DASHBOARD_LOADED: 'SET_DASHBOARD_LOADED',
};

const state = {
    dashboardIsLoaded: false,
};

const mutations = {
    [types.SET_DASHBOARD_LOADED](state) {
        state.dashboardIsLoaded = true;
    },
};

const getters = {
    dashboardIsEnabled(state) {
        return state.dashboardIsLoaded;
    },
};

const actions = {
    setDashboardLoaded({ commit }) {
        commit(types.SET_DASHBOARD_LOADED);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
