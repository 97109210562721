import type { MutationTree, GetterTree, ActionTree } from 'vuex';
import {
    fetchStudyLists,
    deleteStudylistItem,
    addStudylistItem,
    toggleStudyListItemStatus,
} from '@/api/gateway/legacy-api/studylist';
import { orderBy } from 'lodash-es';
import type { StudyListResource } from '@/api/gateway/legacy-api/resources/StudyListResource';
import type { StudyListItemResource } from '@/api/gateway/legacy-api/resources/StudyListItemResource';

interface StudyListState {
    studylists: StudyListResource[];
    isLoadingStudylists: boolean;
    hasLoadedStudylists: boolean;
}

const state: StudyListState = {
    studylists: [],
    isLoadingStudylists: false,
    hasLoadedStudylists: false,
};

const types = {
    SET_STUDYLISTS: 'SET_STUDYLISTS',
    SET_IS_LOADING_STUDYLISTS: 'SET_IS_LOADING_STUDYLISTS',
    SET_HAS_LOADED_STUDYLISTS: 'SET_HAS_LOADED_STUDYLISTS',
    EDIT_ITEM_STATUS: 'EDIT_ITEM_STATUS',
};

const getters: GetterTree<StudyListState, unknown> = {
    studylists: (state) => orderBy(Object.values(state.studylists), ['course_name'], ['asc']),
    hasLoadedStudylists: (state) => state.hasLoadedStudylists,
    studylistsAreLoading: (state) => state.isLoadingStudylists,
};

const mutations: MutationTree<StudyListState> = {
    [types.SET_STUDYLISTS](state, studylists: StudyListResource[]) {
        state.studylists = studylists.map((studylist) => ({
            ...studylist,
            items: studylist.items.sort((a, b) =>
                a.relationship.file_name.localeCompare(b.relationship.file_name, undefined, { sensitivity: 'base' }),
            ),
        }));
    },
    [types.SET_HAS_LOADED_STUDYLISTS](state, status: boolean) {
        state.hasLoadedStudylists = status;
    },
    [types.SET_IS_LOADING_STUDYLISTS](state, status: boolean) {
        state.isLoadingStudylists = status;
    },
    [types.EDIT_ITEM_STATUS](state, payload) {
        mutations[types.SET_STUDYLISTS](
            state,
            state.studylists.map((list) => ({
                ...list,
                items:
                    list.id === payload.id
                        ? list.items.map((item) => ({
                              ...item,
                              completed_at: item.item_id === payload.item_id ? payload.completed_at : item.completed_at,
                          }))
                        : [...list.items],
            })),
        );
    },
};
const actions: ActionTree<StudyListState, unknown> = {
    deleteStudylistItem({ commit }, { type, id }) {
        return deleteStudylistItem(type, id);
    },
    addStudylistItem({ commit }, { type, id }) {
        return addStudylistItem(type, id);
    },
    loadStudylists({ getters, commit }) {
        if (getters.studylistsAreLoading) return Promise.reject(new Error('Studylists are already loading'));
        commit(types.SET_IS_LOADING_STUDYLISTS, true);

        return fetchStudyLists()
            .then((response) => {
                commit(types.SET_STUDYLISTS, response.data.data);
                commit(types.SET_HAS_LOADED_STUDYLISTS, true);
            })
            .finally(() => {
                commit(types.SET_IS_LOADING_STUDYLISTS, false);
            });
    },
    toggleStudyListItemStatus({ commit }, item: StudyListItemResource) {
        const status = !item.completed_at;
        return toggleStudyListItemStatus(item, status).then(() => {
            commit(types.EDIT_ITEM_STATUS, {
                id: item.study_list_id,
                item_id: item.item_id,
                completed_at: !item.completed_at ? new Date() : null,
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
