export enum RouteGroup {
    HOME = 'home',
    COURSE = 'course',
    DOC = 'doc',
    DOCUMENTS = 'documents',
    FLASHCARDS = 'flashcards',
    GROUP = 'group',
    SEARCH = 'search',
    UNIVERSITY = 'university',
    COMPANY = 'company',
    REWARDS = 'rewards',
    NEWSFEED = 'newsfeed',
}
