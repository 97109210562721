import * as braze from '@braze/web-sdk';
import store from '@/store';

if (import.meta.env.MIX_BRAZE_SDK_ENABLED === 'true') {
    const user = store.getters['auth/user'];
    const brazeAppId = import.meta.env.MIX_BRAZE_APP_ID;
    const brazeSdkEndpoint = import.meta.env.MIX_BRAZE_SDK_ENDPOINT;
    const brazeSdkLogEnabled = import.meta.env.MIX_BRAZE_SDK_LOG_ENABLED === 'true';
    const propertiesAllowed = ['timeZone'];

    if (user?.uuid) {
        // initialize the SDK
        braze.initialize(brazeAppId, {
            baseUrl: brazeSdkEndpoint,
            devicePropertyAllowlist: propertiesAllowed,
            enableLogging: brazeSdkLogEnabled, // LOGS EVERYTHING TO THE CONSOLE if set to true
            allowUserSuppliedJavascript: true,
        });

        // show all in-app messages without custom handling
        braze.automaticallyShowInAppMessages();

        // Leaving this here commented out, to discuss:
        // if (isLoggedIn){
        //     braze.changeUser(user.uuid);
        // }
        braze.changeUser(user.uuid);

        // start (or continue) a session
        braze.openSession();
    }
}
