import axios from 'axios';

const queryArticles = {
    query: `
        query {
          allArticle(
            where: { owner: { reference_id: { eq: :companyId } } }
            sort: { highlighted: DESC, eventDate: ASC }
            limit: 10
          ) {
            _id
            title
            description
            url
            eventDate
            eventEndDate
            allDay
            highlighted
            poster {
              asset {
                url
              }
            }
          }
        }
    `,
};

const queryProfile = {
    query: `query{
      allCompany(where: {reference_id:{eq: :companyId}}, limit: 1){
        name,
        videos {
          _id,
          headline,
          description,
          url,
          _createdAt
        },
        links {
          _id,
          headline,
          subheadline,
          url,
          new_tab,
          _createdAt
        }
      }
    }`,
};

// eslint-disable-next-line import/prefer-default-export
export function getArticles(companyId) {
    queryArticles.query = queryArticles.query.replace(':companyId', companyId);
    return axios.post(import.meta.env.MIX_SANITY_GRAPHQL_ENDPOINT, queryArticles, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
}

export function getProfile(companyId) {
    queryProfile.query = queryProfile.query.replace(':companyId', companyId);
    return axios.post(import.meta.env.MIX_SANITY_GRAPHQL_ENDPOINT, queryProfile);
}
