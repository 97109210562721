import route from '@router/route';
import { backend } from '.';

export function getDsaReportReasons() {
    return backend.get(route('dsa.report.reasons')).then((response) => response.data.data);
}

export function storeDsaReportData(data) {
    return backend.post(route('dsa.report.store'), { ...data });
}

export function getXandrAdvertiser(creativeId, advertiserId) {
    return backend.get(route('dsa.xandr.advertiser', { creative_id: creativeId, advertiser_id: advertiserId }));
}
