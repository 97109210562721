import axios from 'axios';
import route from '@router/route';
import { backend } from '.';
import { assignCancelToken } from '../utils/index';
import type { InstitutionResource } from './resources/UniversityResource';

export function suggestUniversities(keyword: string, { page = 1, limit = 25 } = {}) {
    const cancelToken = axios.CancelToken.source();

    const promise = backend.get(route('suggest.universities', { keyword, page, limit }), {
        cancelToken: cancelToken.token,
    });
    return assignCancelToken(promise, cancelToken);
}

export function suggestUsms(keyword: string, uniId: number) {
    const cancelToken = axios.CancelToken.source();

    const promise = backend.get(
        route('suggest.usms', {
            uniid: uniId,
            keyword,
        }),
        { cancelToken: cancelToken.token },
    );
    return assignCancelToken(promise, cancelToken);
}

export function getUniversityById(universityId: number) {
    return backend.get<InstitutionResource>(route('university.id', { universityId }));
}

export function getStats(universityId: number) {
    return backend.post(route('guests.stats.university'), { id: universityId });
}

export function createUniversity(university: object) {
    return backend.post(route('university.store'), university);
}

export function getCourses(payload: Record<string, unknown>) {
    return backend.get(route('search.results', payload));
}

export function getDocuments(payload: Record<string, unknown>) {
    return backend.get(route('search.results', payload));
}
