import route from '@router/route';
import { backend } from '.';

export function retrieveFeed(start = 0, excludeNotifications = []) {
    const uri = route('newsfeed.index').toString();

    return backend.post(uri, {
        start,
        exclude_notifications: excludeNotifications,
    });
}
