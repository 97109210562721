import { gateway } from '..';
import type { NotificationChannel, NotificationType } from './resources/NotificationSettingResource';
import type { HeaderNotificationResource } from './resources/HeaderNotificationResource';
import type { NotificationSettingSectionResource } from './resources/NotificationSettingSectionResource';

const API_URL = 'notifications/v1';
const API_NOTIFICATION_SETTINGS = `${API_URL}/settings`;

export function fetchHeaderNotifications() {
    const endpoint = `${API_URL}/headers`;
    return gateway.get<{
        notifications: HeaderNotificationResource[];
        unread: number;
    }>(endpoint);
}

export function readHeaderNotification(notificationId: string) {
    const endpoint = `${API_URL}/headers/read`;
    return gateway.put(endpoint, { notification_id: notificationId });
}

export function readAllHeaderNotification() {
    const endpoint = `${API_URL}/headers/read-all`;
    return gateway.put(endpoint);
}

export function readAllSideBarNotification(notificationType: string) {
    const endpoint = `${API_URL}/sidebar/read-all`;
    return gateway.put(endpoint, { notification_type: notificationType });
}

/**
 *
 * @param token Auth token to reach notifications-service from unsubscribe page, token provided by UnsubscribeEmailShowController
 */
export function fetchNotificationSettings(token?: string) {
    const endpoint = API_NOTIFICATION_SETTINGS;
    const params: { [key: string]: unknown } = {};
    if (token) {
        params.token = token;
    }
    return gateway.get<NotificationSettingSectionResource[]>(endpoint, { params }).then((response) => response.data);
}

/**
 *
 * @param notificationType
 * @param notificationChannel
 * @param token Auth token to reach notifications-service from unsubscribe page, token provided by UnsubscribeEmailShowController
 */
export function enableNotificationSetting(
    notificationType: NotificationType,
    notificationChannel: NotificationChannel,
    token?: string,
) {
    const endpoint = `${API_NOTIFICATION_SETTINGS}/${notificationType}/${notificationChannel}/enable`;
    const params: { [key: string]: unknown } = {};
    if (token) {
        params.token = token;
    }
    return gateway.put<Record<string, never>>(endpoint, null, { params });
}

/**
 *
 * @param notificationType
 * @param notificationChannel
 * @param token Auth token to reach notifications-service from unsubscribe page, token provided by UnsubscribeEmailShowController
 */
export function disableNotificationSetting(
    notificationType: NotificationType,
    notificationChannel: NotificationChannel,
    token?: string,
) {
    const endpoint = `${API_NOTIFICATION_SETTINGS}/${notificationType}/${notificationChannel}/disable`;
    const params: { [key: string]: unknown } = {};
    if (token) {
        params.token = token;
    }
    return gateway.put<Record<string, never>>(endpoint, null, { params });
}
