import { getRewards, orderReward } from '@/api/backend/reward';

const types = {
    UPDATE_STORE_PROGRESS: 'UPDATE_STORE_PROGRESS',
    UPDATE_LIST_PROGRESS: 'UPDATE_LIST_PROGRESS',
    UPDATE_LIST_DATA: 'UPDATE_LIST_DATA',
    UPDATE_LIST_PAGINATION: 'UPDATE_LIST_PAGINATION',
};

const state = {
    orderProgress: false,
    rewards: {
        list: [],
        pagination: null,
        progress: false,
    },
};

const mutations = {
    [types.UPDATE_LIST_DATA](state, { list, shouldAppend }) {
        if (shouldAppend) {
            state.rewards.list = state.rewards.list.concat(list);
        } else {
            state.rewards.list = list;
        }
    },
    [types.UPDATE_LIST_PROGRESS](state, payload) {
        state.rewards.progress = payload;
    },
    [types.UPDATE_LIST_PAGINATION](state, payload) {
        state.rewards.pagination = payload;
    },
    [types.UPDATE_STORE_PROGRESS](state, payload) {
        state.orderProgress = payload;
    },
};

const actions = {
    retrieveRewards({ commit }) {
        commit('UPDATE_LIST_PROGRESS', true);
        return getRewards()
            .then((response) => {
                const { data, meta } = response.data;
                commit('UPDATE_LIST_DATA', {
                    list: data,
                    shouldAppend: true,
                });
                commit('UPDATE_LIST_PAGINATION', meta);
            })
            .finally(() => commit('UPDATE_LIST_PROGRESS', false));
    },
    order({ commit }, payload) {
        commit('UPDATE_STORE_PROGRESS', true);
        return orderReward(payload.reward_id, payload).finally(() => commit('UPDATE_STORE_PROGRESS', false));
    },
};

const getters = {
    rewards: (state) => state.rewards.list,
    rewardsPagination: (state) => state.rewards.pagination,
    rewardsProgress: (state) => state.rewards.progress,
    orderProgress: (state) => state.orderProgress,
    canAfford: () => (price, credits) => {
        return price <= credits;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
