import { i18n } from '@/plugins/i18n';
import { HTTP_STATUS } from '@/utils/networkUtils';
import type { Interceptor } from './types';

/**
 * This interceptor provides a message for unavailable servers
 */
export default {
    handleResponseError(error) {
        if (error && error?.response?.status === HTTP_STATUS.MAINTENANCE) {
            // eslint-disable-next-line no-param-reassign
            error.response.data = {
                message: i18n.t('errors.maintenanceError'),
            };
        }

        return Promise.reject(error);
    },
} as Interceptor;
