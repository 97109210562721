import { toValue, watch, type Ref } from 'vue';
import axios, { type CreateAxiosDefaults } from 'axios';
import { merge } from 'lodash-es';

/**
 * Simple wrapper to create a new axios instance and sync config changes with the instance
 */
export function useAxios<T extends CreateAxiosDefaults>(config: Ref<T>) {
    const instance = axios.create(toValue(config));

    watch(config, (newConfig) => merge(instance.defaults, newConfig), { deep: true });

    return instance;
}
