import { computed } from 'vue';
import { once } from 'lodash-es';
import { useCookies } from '@vueuse/integrations/useCookies';

/**
 * Reactive access token composable that will update whenever the associated cookie changes
 */
export const useAccessToken = once(() => {
    const apiCookies = useCookies([import.meta.env.MIX_AUTH_ACCESS_TOKEN_NAME], { autoUpdateDependencies: true });

    return {
        accessToken: computed(() => apiCookies.get<string>(import.meta.env.MIX_AUTH_ACCESS_TOKEN_NAME)),
    };
});
