import { Cookie } from '@/utils/cookieUtils';
import { useCookies } from '@vueuse/integrations/useCookies';

// non-reactive universal-cookie instance
const cookies = useCookies();

export function hasAcceptCookie() {
    const val = cookies.get(Cookie.ACCEPT_COOKIES);
    return val !== undefined && val;
}

export function acceptCookies({ commit }) {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 180);

    cookies.set(Cookie.ACCEPT_COOKIES, 1, { expires: new Date(expiry), path: '/' });
    commit('SET_ACCEPT_COOKIE');
}

export function checkAdCookie(type) {
    const val = cookies.get(`${Cookie.AD_COOKIE_PREFIX}${type}`);
    return val !== undefined && val;
}

export function storeXandrCloseAdCookie(content, type) {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 1);

    cookies.set(`${Cookie.AD_COOKIE_PREFIX}${type}`, 1, {
        expires: new Date(expiry),
        path: '/',
    });
}

export function numberOfDocsViewedCookie() {
    const val = cookies.get(Cookie.XANDR_VIEWED_DOCS);
    return val ?? 1;
}

/*
 * For xandr fullscreen ads
 * */
export function storeNumberOfViewedDocsCookie(number) {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 1);
    const previousNumber = numberOfDocsViewedCookie();
    const newNum =
        previousNumber >= parseInt(import.meta.env.MIX_XANDR_MAX_NUMBER_OF_DOCS_VIEWED, 10)
            ? number
            : number + previousNumber;

    cookies.set(Cookie.XANDR_VIEWED_DOCS, newNum, {
        expires: new Date(expiry),
        path: '/',
    });
}

export function storeXandrFullScreenAdViewed(val) {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 1);

    cookies.set(Cookie.XANDR_VIEWED_FULLSCREEN_AD, val, { expires: new Date(expiry), path: '/' });
}

export function checkFullScreenViewedCookie() {
    const val = cookies.get(Cookie.XANDR_VIEWED_FULLSCREEN_AD);
    return val !== undefined && val;
}
