import route from '@/router/route';
import { backend } from '.';

export function sendEmailLoginRequest({ remember = false, extras = null, ...payload }) {
    return backend.post(route('auth.email.login'), {
        ...payload,
        ...extras,
        remember,
    });
}

export function sendEmailRegistrationRequest({ extras = null, ...payload }) {
    return backend.post(route('auth.register'), {
        ...payload,
        ...extras,
    });
}

export function sendFacebookLoginRequest(payload) {
    return backend.post(route('auth.social.login'), {
        ...payload,
    });
}

export function logFacebookAuthError(response) {
    // make sure sensitive information is masked
    const filteredResponse = response;

    if (filteredResponse.authResponse) {
        const { authResponse } = filteredResponse;
        if (authResponse.accessToken && authResponse.accessToken.length > 0) {
            filteredResponse.authResponse.accessToken = '*****';
        }
        if (authResponse.signedRequest && authResponse.signedRequest.length > 0) {
            filteredResponse.authResponse.signedRequest = '*****';
        }
    }

    const filteredResponseAsString = JSON.stringify(filteredResponse);
    const error = new Error(`Error with Faceboook login: ${filteredResponseAsString}`);
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
}

export function verificationEmailResend() {
    return backend.post(route('auth.verification.resend'));
}

export function verificationEmailCheck() {
    return backend.get(route('auth.verification.check'));
}

export function sendGoogleAuthRequest(extras) {
    return backend.post(route('auth.social.login'), {
        ...extras,
    });
}

export function sendAppleAuthRequest(extras) {
    return backend.post(route('auth.social.login'), {
        ...extras,
    });
}
