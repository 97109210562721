/**
 * Simple helper function to check if a value is truthy.
 */
export function isTruthy(val: unknown): boolean {
    return !!val;
}

/**
 * Simple helper function to check if a value is falsy.
 */
export function isFalsy(val: unknown): boolean {
    return !val;
}
