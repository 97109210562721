import { CALLOUTS } from '@/utils/calloutUtils';
import { markCalloutsAsRead } from '@/api/backend/user';
import { difference, get } from 'lodash-es';
import { assureArray } from '@helpers/array';

const state = {
    unread: get(window.sdWindow, 'callouts', []),
    triggered: [],
};

const types = {
    MARK_AS_READ: 'MARK_AS_READ',
    SET_TRIGGERED_CALLOUTS: 'SET_TRIGGERED_CALLOUTS',
    CLEAR_TRIGGERED_CALLOUTS: 'CLEAR_TRIGGERED_CALLOUTS',
};

const mutations = {
    [types.MARK_AS_READ](state, ids) {
        state.unread = difference(state.unread, ids);
    },
    [types.SET_TRIGGERED_CALLOUTS](state, val) {
        state.triggered = val;
    },
    [types.CLEAR_TRIGGERED_CALLOUTS](state) {
        state.triggered = [];
    },
};

const actions = {
    trigger({ commit }, ids) {
        commit(types.SET_TRIGGERED_CALLOUTS, ids);
    },
    async markAsRead({ commit }, idOrIds) {
        const ids = assureArray(idOrIds);
        if (!ids.length) return;

        await markCalloutsAsRead(ids);
        commit(types.MARK_AS_READ, ids);
    },

    markAllAsRead({ state, dispatch, getters }) {
        let markedCallouts;
        markedCallouts = dispatch('markAsRead', state.triggered);
        if (window.sdWindow.user.is_ke && getters.unreadCallouts.includes(CALLOUTS.newsfeedCeDashboard)) {
            markedCallouts = dispatch('markAsRead', [CALLOUTS.newsfeedCeDashboard]);
        }
        return markedCallouts;
    },
    clear({ commit }) {
        commit(types.CLEAR_TRIGGERED_CALLOUTS);
    },
};

const getters = {
    unreadCallouts: (state) => state.unread,

    triggeredCallouts: (state) => state.triggered,
    triggeredUnreadCallouts: (state) => state.triggered.filter((id) => state.unread.includes(id)),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
