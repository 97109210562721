import type { Directive } from 'vue';

/* eslint-disable no-param-reassign */
interface DirectiveContext {
    once?: boolean;
    immediate?: boolean;
    handler: CallableFunction;
    config: MutationObserverInit;
    observer?: MutationObserver;
}

interface DirectiveModifiers extends MutationObserverInit {
    once?: boolean;
    immediate?: boolean;
}

// as we are adding a property to the Node interface, we need to extend it
declare global {
    interface Node {
        mutationDirective?: DirectiveContext;
    }
}

const defaultConfig: MutationObserverInit = {
    childList: true,
    subtree: true,
    attributes: true,
    attributeOldValue: true,
    characterData: true,
    characterDataOldValue: true,
};

function destroy(el: Node) {
    if (!el.mutationDirective) return;

    el.mutationDirective.observer?.disconnect();
    delete el.mutationDirective;
}

function updateObserver(el: Node) {
    const ctx = el.mutationDirective;
    if (!ctx) return;

    ctx.observer?.disconnect();

    ctx.observer = new MutationObserver((list) => {
        if (typeof ctx.handler !== 'function') return;

        const res = ctx.handler(list);

        if (res === false || ctx.once === true) {
            destroy(el);
        }
    });

    ctx.observer.observe(el, ctx.config);
}

export default {
    beforeMount(
        el: Node,
        { modifiers: { immediate, once, ...mod }, value }: { modifiers: DirectiveModifiers; value: CallableFunction },
    ) {
        el.mutationDirective = {
            once,
            immediate,
            handler: value,
            config: !Object.keys(mod).length ? defaultConfig : mod,
        };

        updateObserver(el);
    },

    mounted(el: Node, { modifiers: { immediate } }: { modifiers: DirectiveModifiers }) {
        if (immediate) {
            el.mutationDirective?.handler([]);
        }
    },

    updated(el: Node, { oldValue, value }: { oldValue: CallableFunction; value: CallableFunction }) {
        if (!el.mutationDirective || oldValue !== value) return;

        el.mutationDirective.handler = value;

        updateObserver(el);
    },

    unmounted: destroy,
} as Directive;
