import store from '@/store';
import { tryToGetElementById, getOffsetTop, smoothScroll } from './dom';

export function scrollPageToElement(el: HTMLElement, extraOffsetTop = 0) {
    const pos = {
        left: 0,
        top: getOffsetTop(el) - store.getters['ui/pageScrollTopOffset'] - extraOffsetTop,
    };
    smoothScroll(window, pos);

    return pos;
}

export async function scrollPageToElementWithId(id: string) {
    const el = await tryToGetElementById(id);
    if (!el) return undefined;

    return scrollPageToElement(el);
}
