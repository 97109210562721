import {
    getStats,
    getDocuments,
    getFlashcards,
    updateEmail,
    updateProfile,
    deleteProfilePicture,
} from '@/api/backend/user';

const types = {
    SET_PROFILE: 'SET_PROFILE',
    SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
    SET_PROFILE_USERINFO: 'SET_PROFILE_USERINFO',
    SET_STATS: 'SET_STATS',
    SET_DOCUMENTS: 'SET_DOCUMENTS',
    SET_DOCUMENTS_FOLLOWED: 'SET_DOCUMENTS_FOLLOWED',
    SET_FLASHCARDS: 'SET_FLASHCARDS',
    SET_FLASHCARDS_FOLLOWED: 'SET_FLASHCARDS_FOLLOWED',
    SET_UPDATE_EMAIL_PROGRESS: 'SET_UPDATE_EMAIL_PROGRESS',
};

const state = {
    profile: null,
    updateEmailProgress: false,
};

const mutations = {
    [types.SET_PROFILE](state, profile) {
        state.profile = profile;
    },
    [types.SET_PROFILE_PICTURE](state, picture) {
        state.profile.image = picture;
    },
    [types.SET_PROFILE_USERINFO](state, userInfo) {
        if (state.profile === null) {
            state.profile = {};
        }
        state.profile.name = userInfo.name;
        state.profile.slug = userInfo.slug;
        state.profile.gender = userInfo.gender;
    },
    [types.SET_STATS](state, stats) {
        state.profile = { ...state.profile, ...{ stats } };
    },
    [types.SET_DOCUMENTS](state, documents) {
        state.profile = { ...state.profile, ...{ documents } };
    },
    [types.SET_DOCUMENTS_FOLLOWED](state, documentsFollowed) {
        state.profile = { ...state.profile, ...{ documentsFollowed } };
    },
    [types.SET_FLASHCARDS](state, flashcards) {
        state.profile = { ...state.profile, ...{ flashcards } };
    },
    [types.SET_FLASHCARDS_FOLLOWED](state, flashcardsFollowed) {
        state.profile = { ...state.profile, ...{ flashcardsFollowed } };
    },
    [types.SET_UPDATE_EMAIL_PROGRESS](state, payload) {
        state.updateEmailProgress = payload;
    },
};

const actions = {
    setProfile({ commit }, profile) {
        commit(types.SET_PROFILE, profile);
    },
    updateProfilePicture({ commit }, picture) {
        commit(types.SET_PROFILE_PICTURE, picture);
    },
    retrieveStats({ commit }, { id }) {
        return getStats(id).then((response) => commit('SET_STATS', response.data.data));
    },
    retrieveDocuments({ commit, getters }, { id, page, shouldAppendResult, followed = false }) {
        return getDocuments(id, followed, page).then(({ data }) => {
            const { meta } = data;
            let { data: list } = data;

            if (shouldAppendResult) {
                const existingList = followed ? getters.documentsFollowedList : getters.documentsList;
                list = existingList.concat(list);
            }

            commit(followed ? 'SET_DOCUMENTS_FOLLOWED' : 'SET_DOCUMENTS', {
                list,
                total: meta.total,
                currentPage: meta.current_page,
                hasNext: data.links.next !== null,
            });
        });
    },
    retrieveFlashcards({ commit, getters }, { id, page, shouldAppendResult, followed = false }) {
        return getFlashcards(id, followed, page).then(({ data }) => {
            const { meta } = data;
            let { data: list } = data;

            if (shouldAppendResult) {
                const existingList = followed ? getters.flashcardsFollowedList : getters.flashcardsList;
                list = existingList.concat(list);
            }

            commit(followed ? 'SET_FLASHCARDS_FOLLOWED' : 'SET_FLASHCARDS', {
                list,
                total: meta.total,
                currentPage: meta.current_page,
                hasNext: data.links.next !== null,
            });
        });
    },
    updateEmail({ commit }, payload) {
        commit('SET_UPDATE_EMAIL_PROGRESS', true);
        return updateEmail(payload)
            .then((response) => response.data)
            .finally(() => commit('SET_UPDATE_EMAIL_PROGRESS', false));
    },
    updateProfile({ commit }, payload) {
        return updateProfile(payload).then(({ data }) => {
            commit('SET_PROFILE_USERINFO', data);
            return data;
        });
    },
    deleteProfilePicture({ commit }) {
        return deleteProfilePicture().then((response) => commit('SET_PROFILE_PICTURE', response.data.data));
    },
};

const flashcardGetters = {
    flashcardsList: (state) =>
        state.profile && state.profile.flashcards && state.profile.flashcards.list ? state.profile.flashcards.list : [],
    flashcardsPage: (state) =>
        state.profile && state.profile.flashcards && state.profile.flashcards.currentPage
            ? state.profile.flashcards.currentPage
            : 1,
    flashcardsCanLoadMore: (state) =>
        !!(state.profile && state.profile.flashcards && state.profile.flashcards && state.profile.flashcards.hasNext),
    flashcardsTotalCount: (state) => {
        if (state.profile && state.profile.flashcards && state.profile.flashcards.total !== undefined) {
            return state.profile.flashcards.total;
        }
        return null;
    },
    flashcardsFollowedList: (state) =>
        state.profile && state.profile.flashcardsFollowed && state.profile.flashcardsFollowed.list
            ? state.profile.flashcardsFollowed.list
            : [],
    flashcardsFollowedPage: (state) =>
        state.profile && state.profile.flashcardsFollowed && state.profile.flashcardsFollowed.currentPage
            ? state.profile.flashcardsFollowed.currentPage
            : 1,
    flashcardsFollowedCanLoadMore: (state) =>
        !!(
            state.profile &&
            state.profile.flashcardsFollowed &&
            state.profile.flashcardsFollowed &&
            state.profile.flashcardsFollowed.hasNext
        ),
    flashcardsFollowedCount: (state) => {
        if (state.profile && state.profile.flashcardsFollowed && state.profile.flashcardsFollowed.total !== undefined) {
            return state.profile.flashcardsFollowed.total;
        }
        return null;
    },
};

const documentGetters = {
    documentsList: (state) =>
        state.profile && state.profile.documents && state.profile.documents.list ? state.profile.documents.list : [],
    documentsPage: (state) =>
        state.profile && state.profile.documents && state.profile.documents.currentPage
            ? state.profile.documents.currentPage
            : 1,
    documentsCanLoadMore: (state) =>
        !!(state.profile && state.profile.documents && state.profile.documents && state.profile.documents.hasNext),
    documentsTotalCount: (state) => {
        if (state.profile && state.profile.documents && state.profile.documents.total !== undefined) {
            return state.profile.documents.total;
        }
        return null;
    },
    documentsFollowedList: (state) =>
        state.profile && state.profile.documentsFollowed && state.profile.documentsFollowed.list
            ? state.profile.documentsFollowed.list
            : [],
    documentsFollowedPage: (state) =>
        state.profile && state.profile.documentsFollowed && state.profile.documentsFollowed.currentPage
            ? state.profile.documentsFollowed.currentPage
            : 1,
    documentsFollowedCanLoadMore: (state) =>
        !!(
            state.profile &&
            state.profile.documentsFollowed &&
            state.profile.documentsFollowed &&
            state.profile.documentsFollowed.hasNext
        ),
    documentsFollowedCount: (state) => {
        if (state.profile && state.profile.documentsFollowed && state.profile.documentsFollowed.total !== undefined) {
            return state.profile.documentsFollowed.total;
        }
        return null;
    },
};

const getters = {
    profile: (state) => state.profile,
    ...flashcardGetters,
    ...documentGetters,
    isProfileOwner: (state) => (user) => {
        if (!user || !user.userid) {
            return false;
        }
        return state.profile && state.profile.id === user.userid;
    },
    updateEmailProgress: (state) => state.updateEmailProgress,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
