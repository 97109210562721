const info = 'default';
const error = 'error';
const success = 'success';
const maxFlashmessages = 3;

const types = {
    SHOW: 'SHOW',
    DISMISS: 'DISMISS',
};

const state = {
    items: [],
};

const mutations = {
    [types.SHOW](state, { appearance, content, key, progress = { time: 5000, showProgress: false } }) {
        // if key exists, remove existing element with same key
        if (key) {
            const index = state.items.findIndex((item) => item.key === key);

            if (index !== -1) {
                state.items.splice(index, 1);
            }
        }

        if (state.items.length >= maxFlashmessages) {
            state.items.pop();
        }
        state.items.unshift({
            key: key || new Date().getTime(),
            appearance,
            content,
            progress,
        });
    },

    [types.DISMISS](state, key) {
        const index = state.items.findIndex((message) => {
            return message.key === key;
        });
        if (index !== -1) {
            state.items.splice(index, 1);
        }
    },
};

const getters = {
    messages: (state) => state.items,
};

const actions = {
    showAlert({ commit }, { appearance, content, key, progress }) {
        commit(types.SHOW, { appearance, content, key, progress });
    },
    showDefault({ commit }, { content, key, progress }) {
        commit(types.SHOW, { appearance: info, content, key, progress });
    },
    showError({ commit }, { content, key, progress }) {
        commit(types.SHOW, { appearance: error, content, key, progress });
    },
    showSuccess({ commit }, { content, key, progress }) {
        commit(types.SHOW, { appearance: success, content, key, progress });
    },
    dismiss({ commit }, key) {
        commit(types.DISMISS, key);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
