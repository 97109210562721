function getMouseDownListenerFor(el) {
    return () => {
        if (el.dragScrollDirective.origin) return;

        const styles = window.getComputedStyle(el);

        el.dragScrollDirective.origin = {
            scrollSnapType: styles.scrollSnapType,
            scrollBehavior: styles.scrollBehavior,
        };

        el.style.cursor = 'grabbing';
        // disable snapping temporarily
        el.style.scrollBehavior = 'auto';
        el.style.scrollSnapType = 'none';

        el.addEventListener('mousemove', el.dragScrollDirective.onMouseMove);
        el.addEventListener('mouseup', el.dragScrollDirective.onMouseUp);
    };
}

function getMouseMoveListenerFor(el) {
    return (event) => {
        el.scrollLeft -= event.movementX;
        el.scrollTop -= event.movementY;
    };
}

function getMouseUpListenerFor(el) {
    return () => {
        el.removeEventListener('mousemove', el.dragScrollDirective.onMouseMove);
        el.removeEventListener('mouseup', el.dragScrollDirective.onMouseUp);

        el.style.cursor = 'grab';
        el.style.scrollBehavior = el.dragScrollDirective.origin.scrollBehavior;
        el.style.scrollSnapType = el.dragScrollDirective.origin.scrollSnapType;

        delete el.dragScrollDirective.origin;
    };
}

function addEventListeners(el) {
    el.dragScrollDirective = {
        onMouseDown: getMouseDownListenerFor(el),
        onMouseMove: getMouseMoveListenerFor(el),
        onMouseUp: getMouseUpListenerFor(el),
    };

    el.addEventListener('mousedown', el.dragScrollDirective.onMouseDown);
}

function removeEventListeners(el) {
    if (!el.dragScrollDirective) return;

    el.addEventListener('mousedown', el.dragScrollDirective.onMouseDown);
    el.removeEventListener('mousemove', el.dragScrollDirective.onMouseMove);
    el.removeEventListener('mouseup', el.dragScrollDirective.onMouseUp);
}

/**
 * This directive adds scroll on drag functionality to a scrollable element
 */
export default {
    beforeMount(el) {
        removeEventListeners(el);
        addEventListeners(el);

        el.style.cursor = 'grab';
        el.style.userSelect = 'none';
    },
    unmounted(el) {
        removeEventListeners(el);
    },
};
