import type { Plugin } from 'vue';
import PrimeVue from 'primevue/config';

/**
 * Small plugin wrapper for the PrimeVue library
 */
export default {
    install(app) {
        app.use(PrimeVue, {
            // we want to style their components in our own way and remove their styling
            unstyled: true,
        });
    },
} as Plugin;
