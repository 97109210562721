import type { Directive } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

// this type is based on ResizeObserverCallback
type DirectiveCallback = (entry: Element, observerEntry?: ResizeObserverEntry) => void | boolean;

/* eslint-disable no-param-reassign */
interface DirectiveContext {
    once?: boolean;
    immediate?: boolean;
    handler?: DirectiveCallback;
}

interface DirectiveModifiers {
    once?: boolean;
    immediate?: boolean;
}

// as we are adding a property to the Node interface, we need to extend it
declare global {
    interface Node {
        resizeDirective?: DirectiveContext;
    }
}

const resizeObserver = new ResizeObserver((list) => {
    // We delay execution of the handler to the next animation frame.
    // In case the handler manipulates the DOM, we don't end up in an error "ResizeObserver loop limit exceeded"
    // but have at most one execution per frame.
    window.requestAnimationFrame(() => {
        list.forEach((entry) => {
            const ctx = entry.target.resizeDirective;
            if (!ctx) return;

            if (ctx.handler?.(entry.target, entry) === false || ctx.once === true) {
                // eslint-disable-next-line no-use-before-define
                destroy(entry.target);
            }
        });
    });
});

function destroy(el: Element) {
    if (!el.resizeDirective) return;

    resizeObserver.unobserve(el);
    delete el.resizeDirective;
}

function updateObserver(el: Element) {
    const ctx = el.resizeDirective;
    if (!ctx) return;

    resizeObserver.unobserve(el);

    if (typeof ctx.handler === 'function') {
        resizeObserver.observe(el);
    }
}

export default {
    beforeMount(
        el: Element,
        { modifiers: { immediate, once }, value }: { modifiers: DirectiveModifiers; value: DirectiveCallback },
    ) {
        el.resizeDirective = {
            once,
            immediate,
            handler: value,
        };

        updateObserver(el);
    },

    mounted(el: Element, { modifiers: { immediate } }: { modifiers: DirectiveModifiers }) {
        if (immediate && el.resizeDirective?.handler) {
            el.resizeDirective.handler(el, undefined);
        }
    },

    updated(el: Element, { oldValue, value }: { oldValue: DirectiveCallback; value: DirectiveCallback }) {
        if (!el.resizeDirective || oldValue !== value) return;

        el.resizeDirective.handler = value;

        updateObserver(el);
    },

    unmounted: destroy,
} as Directive;
