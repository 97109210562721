export enum BtnVariant {
    FILLED = 'filled',
    OUTLINED = 'outlined',
    TONAL = 'tonal',
    TEXT = 'text',
    CUSTOM = 'custom',
}

export enum BtnSize {
    XXS = 'xxs',
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
}

export enum BtnType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

export enum BtnContentSpacing {
    CENTER = 'center',
    APART = 'apart',
}

export enum BtnLabelAlignment {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum BtnLabelFlexStyle {
    FLEX = 'flex',
    GROW = 'grow',
}
