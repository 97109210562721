import { memoize, get } from 'lodash-es';
import { lightTheme, legacyTheme } from '@/../../tailwind.colors.json';

/**
 * Looks up the tailwind color value for a given color name.
 * The lookups are cached to improve performance.
 */
export const getTailwindColor = memoize((val: string) => {
    const path = val.replace('-', '.');
    return (get(lightTheme, path) ||
        get(lightTheme, `${path}.DEFAULT`) ||
        get(legacyTheme, path) ||
        get(legacyTheme, `${path}.DEFAULT`)) as string | undefined;
});

export function isTailwindColor(val: string): boolean {
    return !!getTailwindColor(val);
}

/**
 * Resolves tailwind color names to native css color values
 */
export function resolveTailwindColor(val?: string): string | undefined {
    if (!val) return undefined;

    if (isTailwindColor(val)) {
        return `rgb(var(--colors-${val}))`;
    }

    return val;
}
