/**
 * updates element height
 * @param el
 * @param binding object
 */
function update(el) {
    const { style } = el;
    const styles = window.getComputedStyle(el);
    const borderWidth = parseInt(styles.borderTopWidth, 10) + parseInt(styles.borderBottomWidth, 10);

    style.height = 0; // reset height, so scrollHeight can be re-evaluated
    style.height = `${el.scrollHeight + borderWidth}px`; // set the dynamic height
}

/**
 * This directive adjusts an elements size according to its content.
 * see DEFAULT_VALUE for possible config value
 */
export default {
    beforeMount: update,

    mounted: update,
    updated: update,
};
