import route from '@router/route';
import { backend } from '.';

export function getRewards() {
    return backend.get(route('reward.index'));
}

export function orderReward(rewardId, payload) {
    /* eslint-disable-next-line no-param-reassign */
    payload.reward_id = rewardId;

    return backend.post(route('reward.order.store'), payload);
}
