import { isObject } from 'lodash-es';

/**
 * Returns a value as object.
 * If it is an object, it will be retured.
 * If it is any other type, it will be added under `defaultAttr` of a new object
 * @param {*} any
 * @param {String} defaultAttr
 */
export function asObject(any, defaultAttr) {
    return isObject(any) ? any : { [defaultAttr]: any };
}
