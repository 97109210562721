import route from '@router/route';
import { backend } from '.';

export function deletePost(context, postId) {
    return backend.delete(
        route('post.question.destroy', {
            context,
            postId,
        }),
    );
}

export function editPost(context, post) {
    return backend.put(
        route('post.question.update', {
            context,
        }),
        post,
    );
}

export function reportPost(context, postId, reason, additional) {
    return backend.post(
        route('post.question.report', {
            context,
        }),
        {
            postId,
            reason,
            additional,
            type: context,
        },
    );
}

export function togglePinnedPost(context, postId, targetId) {
    return backend.post(route('post.pin'), {
        questionID: postId,
        type: context,
        targetID: targetId,
    });
}

export function createComment(context, comment) {
    return backend.post(
        route('post.answer.store', {
            context,
        }),
        comment,
    );
}

export function deleteComment(context, commentId) {
    return backend.delete(
        route('post.answer.destroy', {
            context,
            postId: commentId,
        }),
    );
}

export function editComment(context, comment) {
    return backend.put(
        route('post.answer.update', {
            context,
        }),
        comment,
    );
}

export function reportComment(context, commentId, reason, additional) {
    return backend.post(
        route('post.answer.report', {
            context,
        }),
        {
            postId: commentId,
            reason,
            additional,
            type: context,
        },
    );
}

export function toggleBestComment(context, commentId) {
    return backend.post(route('post.answer.mark-best'), {
        type: context,
        answerId: commentId,
    });
}

export function getComments(context, postId) {
    return backend.post(route('post.answer.index'), {
        questionid: postId,
        type: context,
    });
}

export function updatePollOptions(context, pollId, optionId) {
    return backend
        .post(route('post.poll.vote'), {
            context,
            poll_id: pollId,
            option_id: optionId,
        })
        .then((response) => response.data.poll);
}
