import route from '@router/route';
import { backend } from '.';

const configs = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return {
        params: Object.fromEntries(urlSearchParams.entries()),
    };
};

export const enable = async (type) => {
    return backend.put(route('settings.email.enable', { type }), null, configs());
};

export const disable = async (type) => {
    return backend.put(route('settings.email.disable', { type }), null, configs());
};

export const enableAll = async () => {
    return backend.put(route('settings.email.enable.all'), null, configs());
};

export const disableAll = async () => {
    return backend.put(route('settings.email.disable.all'), null, configs());
};

export async function sendUnsubscribeFeedback({
    feedbackOption,
    otherOptionReason,
    emailType,
    email,
    token,
    campaignId,
}) {
    const body = {
        feedback_option: feedbackOption,
        email_type: emailType,
        email,
        token,
    };
    if (otherOptionReason) {
        body.other_reason = otherOptionReason;
    }
    if (campaignId) {
        body.campaign_id = campaignId;
    }

    return backend.post(route('unsubscribe.feedback'), body);
}

export default { enable, disable, enableAll, disableAll, sendUnsubscribeFeedback };
