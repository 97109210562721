import type { route as routeFn } from 'ziggy-js';
import { getLanguageCodeFromLocale, type Locale } from '@/utils/langUtils';

declare global {
    /** Ziggy is injected by the backend and only available for fullstack builds */
    const Ziggy: Parameters<typeof routeFn>[3];

    interface Window {
        route: typeof routeFn;
    }
}

/**
 * This helper function returns the path of a route and converts parameters for vue-router usage.
 * {param} --> :param
 * {param?} --> :param?
 * {param:substitute} --> :param
 *
 * @param {string} routeName
 * @returns
 */
export function getPath(routeName: string) {
    if (typeof Ziggy === 'undefined') {
        // Note: this can occur in environments that are detached from the backend (e.g. testing, storybook)
        console.error('Ziggy does not exist! Routes will be broken!');
        return '';
    }

    return (
        Ziggy.routes[routeName].uri
            // remap laravel route params to vue-router ones
            .replace(/\{([A-Za-z0-9_]+\?*)(:[A-Za-z0-9_]+)*\}/g, ':$1')
            // remove singular / at the beginning
            .replace(/^\/$/, '') as string
    );
}

export function setRouteLang(lang: Locale.DE | Locale.EN) {
    if (typeof Ziggy === 'undefined') {
        // Note: this can occur in environments that are detached from the backend (e.g. testing, storybook)
        console.error('Ziggy does not exist! Routes will be broken!');
        return;
    }

    Ziggy.defaults.lang = lang;
}

// initialize route lang
setRouteLang(getLanguageCodeFromLocale(document.documentElement.lang as Locale));

// Ziggy's route helper is injected into the window by the back end
export default window.route;
