import route from '@router/route';
import type { DataResponse } from '@/api/types';
import type { DOCUMENT_CONTENT_TYPE } from '@/utils/documentUtils';
import { backend } from '.';

export function getHash() {
    return backend.get(route('upload.hash'));
}

export function getHashAnon() {
    return backend.get(route('upload.hash.anon'));
}

export function sendNotification(payload: unknown) {
    return backend.post(route('upload.notification'), payload);
}

export function isGuestUploadEligible(payload: unknown) {
    return backend.post(route('document.guests.studies'), payload);
}

export function postGuestUploadPaymentDetails(payload: {
    payment_method_id: number | null;
    last_name: string;
    file_uuids: string[];
    first_name: string;
    email: string;
    upi_id: string;
}) {
    return backend.post<DataResponse<unknown>>(route('document.guests.payment_details'), payload);
}

export interface UploadRequest {
    file: File;
    course: number;
    university: number;
    name: string;
    professor: string;
    type: DOCUMENT_CONTENT_TYPE;
    semester: number;
    has: string;
    description: string;
    self_made: boolean;
    visibility: boolean;
    school_id: number;
    language_id: number;
}
