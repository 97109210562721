<template>
    <div
        class="bottom-sheet place-self-end bg-white rounded-t-lg mt-10"
        data-testid="bottom-sheet"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Simple component to provide common appearance & animation for bottom sheet scenarios.
 * Use it in combination with our [Modal Plugin](../?path=/docs/plugins-modal--page).
 */
export default defineComponent({
    name: 'BottomSheet',
});
</script>

<style lang="scss" scoped>
/* slide-up/down animation when toggled inside our modal system */
.modal-enter-active,
.modal-leave-to {
    .bottom-sheet {
        transition: transform 0.25s ease-in-out;
        transform: translateY(100%);
    }
}

.modal-enter-to {
    .bottom-sheet {
        transform: translateY(0%);
    }
}
</style>
