import type { Channel } from 'laravel-echo';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { captureException } from '@sentry/vue';
import { authorizeBroadcastChannels } from '@/api/gateway/messages';
import type { ChannelAuthorizationCallback } from 'pusher-js';
import type { Lock } from '@/utils/locksUtils';
import { watchVisibility } from '@helpers/visibility';
import { assureArray } from '@helpers/array';
import { memoize } from 'lodash-es';
import { convertDateTimeStringsToDate } from '@helpers/date';

const options = {
    broadcaster: 'pusher',
    key: import.meta.env.MIX_PUSHER_APP_KEY ?? '',
    cluster: import.meta.env.MIX_PUSHER_APP_CLUSTER ?? '',
    forceTLS: true,
    authorizer: (channel: { name: string }) => ({
        authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
            authorizeBroadcastChannels(socketId, channel.name)
                .then((response) => {
                    callback(null, response.data);
                })
                .catch((error) => {
                    captureException(error);
                    callback(error, null);
                });
        },
    }),
};

let connected = false;

/**
 * we only want to create websocket connections when we need them.
 * Only when getEcho is called the first time, a connection is being created.
 */
const getEcho = memoize(() => {
    connected = true;
    return new Echo({
        ...options,
        client: new Pusher(options.key, options),
    });
});

const getConnectionState = (echo: Echo) => echo.options.client.connection.state as string;

/**
 * Helper function to more efficiently handle our websocket connections.
 * It will make sure that any connection will only be established when the current tab is visible
 * and will be closed when the tab is hidden.
 * When a tab is re-visible, the connection will be re-established.
 *
 * Via the config, you can provide additional settings.
 *
 * With `lock`, you can provide a lock that will be requested for a connection.
 * This will make sure that only one tab can establish a connection at a time.
 *
 * Additional delays can be configured via `startDelay` and `endDelay` to delay start & end of a connection.
 *
 * @returns function to stop watching
 */
export async function listenWhenVisible(
    channel: string,
    event: string | string[],
    callback: CallableFunction,
    config?: {
        beforeStart?: () => Promise<unknown>;
        beforeEnd?: () => Promise<unknown>;
        startDelay?: number;
        endDelay?: number;
        lock?: Lock;
    },
) {
    let releaseLock: CallableFunction | undefined;
    let stopped = false;
    let activeChannel: Channel | undefined;
    const events = assureArray(event);

    const processEvent = (eventData: unknown) => {
        // we convert all date strings to date objects
        callback(convertDateTimeStringsToDate(eventData, true));
    };

    function startListening() {
        const echo = getEcho();
        if (getConnectionState(echo) !== 'connected') {
            // re-connect if neccessary
            echo.options.client.connect();
        }
        connected = true;

        activeChannel = echo.channel(channel);
        events.forEach((e) => activeChannel?.listen(e, processEvent));
    }

    async function onVisible() {
        if (stopped) return;

        if (config?.beforeStart) {
            await config.beforeStart();
        }

        if (!config?.lock) {
            startListening();
            return;
        }

        navigator.locks.request(config.lock, () => {
            // locks will be granted whenever they are available, so we need to check the visibility state again
            if (document.visibilityState !== 'visible' || stopped) return null;

            startListening();

            // We keep the lock until the tab is destroyed or the lock is released by the onHidden function
            return new Promise((resolve) => {
                releaseLock = resolve;
            });
        });
    }

    async function onHidden() {
        if (config?.beforeEnd) {
            await config.beforeEnd();
        }

        // no active channel
        if (!activeChannel) return;

        events.forEach((e) => activeChannel?.stopListening(e, processEvent));
        activeChannel = undefined;

        const echo = getEcho();

        // eslint-disable-next-line no-underscore-dangle
        if (!Object.keys(echo.connector.channels[channel].subscription.callbacks._callbacks).length) {
            // no event to observe in this channel, so we leave the channel
            echo.leave(channel);
        }

        if (!Object.keys(echo.connector.channels).length && getConnectionState(echo) !== 'disconnected' && connected) {
            connected = false;
            // no channel to observe, and connection is still active, so we disconnect.
            // we give echo some time to communicate the channel leave before finally disconnecting.
            setTimeout(() => {
                // only disconnect if the connection wasn't re-established
                if (connected) return;
                echo.disconnect();
            }, 500);
        }

        releaseLock?.();
    }

    const unwatch = watchVisibility(onVisible, onHidden, config?.startDelay, config?.endDelay);

    return function stop() {
        stopped = true;
        unwatch();
        onHidden();
    };
}
