<template>
    <Btn
        v-bind="$attrs"
        :variant="btnVariant"
        class="btn-cta"
        :color="($attrs.color as string|undefined) || 'primary'"
    >
        <!-- forward all slots -->
        <template
            v-for="(_, slot) of $slots"
            #[slot]
        >
            <slot :name="slot" />
        </template>
    </Btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type PropType } from 'vue';
import Btn, { BtnVariant } from '@/components/Btn';
import type { EnumToUnion } from '@/types/misc';
import { BtnCtaVariant } from './BtnCta';

/**
 * This button can be considered ["syntactic sugar"](https://en.wikipedia.org/wiki/Syntactic_sugar) as it is just our `Btn` component with pre-filled values to enforce our cta designs.
 * You can use the same props as with our `Btn` component.
 * If your designs deviate from this cta designs, you should just use our `Btn` component and customize it to your needs instead!
 *
 * Check out our Btn component for more.
 */
export default defineComponent({
    name: 'BtnCta',
    components: {
        Btn,
    },
    inheritAttrs: false,
    props: {
        /**
         * The cta button variant.
         * @values primary, secondary, tertiary
         */
        variant: {
            type: String as PropType<EnumToUnion<BtnCtaVariant>>,
            default: BtnCtaVariant.PRIMARY,
        },
    },
    computed: {
        btnVariant(): BtnVariant | undefined {
            switch (this.variant) {
                case BtnCtaVariant.PRIMARY:
                    return BtnVariant.FILLED;
                case BtnCtaVariant.SECONDARY:
                    return BtnVariant.OUTLINED;
                case BtnCtaVariant.TERTIARY:
                    return BtnVariant.TEXT;
                default:
                    return undefined;
            }
        },
    },
});
</script>
