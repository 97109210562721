<template>
    <Vue3Lottie
        class="overflow-hidden player-size"
        :animation-link="src"
        v-bind="$attrs"
    ></Vue3Lottie>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';

/**
 * The Lottie Player is used to show animated Avatars (json files).
 * The json files are saved on our cdn.
 * The Documentation for the Lottie PLayer can be found here: https://github.com/megasanjay/vue3-lottie
 */
export default {
    name: 'AssetLottie',
    components: { Vue3Lottie },
    inheritAttrs: false,
    props: {
        /**
         * Link to the json file which is saved in the gamify_avatar_url column on the user table
         */
        src: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.player-size {
    width: inherit;
    height: inherit;
}
</style>
