import { toRef } from 'vue';
import { once } from 'lodash-es';
import { useCommonHeaders } from '@/api/utils/composables/commonHeaders';
import { useAxios } from '@/api/utils/composables/axios';
import { registerInterceptors } from '@/api/utils/interceptors';
import networkErrorInterceptor from '@/api/utils/interceptors/networkErrorInterceptor';
import maintenanceInterceptor from '@/api/utils/interceptors/maintenanceInterceptor';
import tokenRefreshInterceptor from '@/api/utils/interceptors/tokenRefreshInterceptor';
import tooManyRequestsInterceptor from '@/api/utils/interceptors/tooManyRequestsInterceptor';
import { shouldDoTokenRefreshWhen } from '@/utils/tokenRefreshUtils';

export const useBackendHeaders = once(() => ({
    ...useCommonHeaders(),
    'X-Requested-With': 'XMLHttpRequest',
}));

function createBackend() {
    const instance = useAxios(
        toRef({
            // We used XSRC token in the past, but discontinued it.
            // In order to not include it in requests, we specify a cookie name that should not contain data
            xsrfCookieName: 'unset',
            headers: useBackendHeaders(),
        }),
    );

    registerInterceptors(instance, [
        networkErrorInterceptor,
        maintenanceInterceptor,
        tokenRefreshInterceptor(instance, (response) =>
            shouldDoTokenRefreshWhen(response.status, 'config' in response ? response.config.url : undefined),
        ),
        tooManyRequestsInterceptor,
    ]);

    return instance;
}

export const backend = createBackend();
