import type { DataResponse } from '@/api/types';
import { gateway } from '..';
import type { ChatResource } from './resources/ChatResource';
import type { ChatMessageResource } from './resources/ChatMessageResource';
import type { ChatPreferenceResource } from './resources/ChatPreferenceResource';
import type { BroadcastAuthorizationResource } from './resources/BroadcastAuthorizationResource';

const API_URL = 'messages/v1';
const API_CHATS = `${API_URL}/chats`;
const API_SETTINGS = `${API_URL}/settings`;

export function storeChat(receiverId: number) {
    return gateway.post<ChatResource>(API_CHATS, {
        receiver_id: receiverId,
    });
}

export function fetchChats() {
    return gateway.get<DataResponse<ChatResource[]>>(API_CHATS, {
        params: { include_blocked: true },
        headers: { 'X-SD-Route-Version': 2 },
    });
}

export function fetchUnreadMessages() {
    return gateway.get<DataResponse<{ count: number }>>(`${API_CHATS}/messages/unread`, {
        headers: { 'X-SD-Route-Version': 2 },
    });
}

export function storeMessage(message: ChatMessageResource) {
    return gateway.post<ChatMessageResource>(`${API_CHATS}/${message.chat_id}/messages`, message);
}

export function deleteMessage(message: ChatMessageResource) {
    return gateway.delete(`${API_CHATS}/${message.chat_id}/messages/${message.id}`);
}

export function fetchMessages(chatID: number, oldestLoadedMessageId?: number, newestLoadedMessageId?: number) {
    return gateway.get<DataResponse<ChatMessageResource[]>>(`${API_CHATS}/${chatID}/messages`, {
        params: {
            oldest_loaded_message_id: oldestLoadedMessageId,
            newest_loaded_message_id: newestLoadedMessageId,
        },
    });
}

export function fetchNewMessages(chatID: number, since: Date) {
    return gateway.get<DataResponse<ChatMessageResource[]>>(`${API_CHATS}/${chatID}/messages/updates`, {
        params: { since },
        headers: { 'X-SD-Route-Version': 2 },
    });
}

export function getPreferences() {
    return gateway.get<ChatPreferenceResource>(API_SETTINGS);
}

export function setPreferences(settings: ChatPreferenceResource) {
    return gateway.post<ChatPreferenceResource>(API_SETTINGS, settings);
}

export function authorizeBroadcastChannels(socketId: string, channelName: string) {
    return gateway.get<BroadcastAuthorizationResource>(`${API_URL}/broadcasting/auth`, {
        params: {
            socket_id: socketId,
            channel_name: channelName,
        },
    });
}
