import { defineComponent } from 'vue';
import { findLinkInEvent } from '@helpers/event';
import { isFirefox } from '@helpers/browser';
import { pushGtmEvent, GtmEventName } from '@/utils/gtmUtils';
import { GlobalEvents } from 'vue-global-events';
import { clickEvent } from '@/modules/globalEvents';

/**
 * This mixin is a placeholder until we have a global App component where we can keep the code.
 * Until then, this mixin shall be injected into each layout and contain code used by every layout.
 */
export default defineComponent({
    name: 'LayoutMixin',
    components: {
        GlobalEvents,
    },
    created() {
        // for click Events
        window.addEventListener('click', this.onClickCapture, true);
        // for contextmenu Events
        window.addEventListener('contextmenu', this.onClickCapture, true);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.onClickCapture, { capture: true });
        window.removeEventListener('contextmenu', this.onClickCapture, { capture: true });
    },
    methods: {
        onClickCapture(e: MouseEvent) {
            const link = findLinkInEvent(e);
            if (!link) return;

            this.onLinkClick(link);
        },
        onLinkClick(link: HTMLLinkElement) {
            const url = new URL(link.href, window.location.origin);
            if (url.hostname.includes('studydrive')) {
                // the user stays on studydrive pages, so don't bother
                return;
            }

            if (link.target === '_blank') {
                // make sure that all the external link click on our page dont have: opener referrer (for privacy reasons)
                // eslint-disable-next-line no-param-reassign
                link.rel = isFirefox ? 'noopener noreferrer' : 'noopener';
            }

            pushGtmEvent(GtmEventName.LINK_OUT);
        },
        globalClickEvent(e: MouseEvent) {
            clickEvent(e, this.$store, this.$i18next);
        },
    },
});
