<template>
    <div
        class="cookie-banner z-30 p-4 fixed bottom-0 right-0 left-0 md:left-auto md:right-0 md:rounded-lg md:mr-5 md:mb-5"
    >
        <div class="w-full text-white-100 mb-4 text-left md:mb-8 leading-normal">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="disclaimerText"></p>
        </div>
        <!-- native modifier has been removed, please confirm whether the function has been affected  -->
        <BtnCta
            class="w-full md:w-auto"
            :label="$t('common:cookie_banner.cta')"
            @click="acceptCookies"
        />
    </div>
</template>

<script>
import route from '@router/route';

export default {
    name: 'TheCookieBanner',
    computed: {
        privacyPolicyUrl() {
            return route('landing-page.show', { slug: this.$t('footer.privacy_slug') });
        },
        privacyPolicyLabel() {
            return this.$t('common:cookie_banner.privacy_link');
        },
        disclaimerText() {
            return `${this.$t('common:cookie_banner.text')} <a href="${
                this.privacyPolicyUrl
            }" target="_blank" class="underline">${this.privacyPolicyLabel}</a>`;
        },
    },
    i18nOptions: {
        namespaces: ['common'],
    },
    methods: {
        acceptCookies() {
            this.$store.dispatch('ui/acceptCookies');
        },
    },
};
</script>
<style lang="scss" scoped>
.cookie-banner {
    background-color: rgba(0, 0, 0, 0.7);
    @screen md {
        max-width: 320px;
    }
}
</style>
