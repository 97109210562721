import route from '@router/route';
import { backend } from '.';

export function sendFeedback(message) {
    return backend.post('ke/feedback', { message });
}

export function getCourseExpertStats() {
    return backend.post(route('ke.dashboard.show'), {});
}

export function storePageview() {
    return backend.post(route('ke.pageview.store'));
}
