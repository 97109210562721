import route from '@router/route';
import { backend } from '.';

/*
 *  Ad blocking
 */
export function postUserBlocksAd(userId: number, bool: boolean) {
    return backend.post(route('adblocker.store'), {
        user_id: userId,
        has_ads_blocks: bool,
    });
}
